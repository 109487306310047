import React from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { UPDATE_CPO } from 'actions/partnerManagement';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useParams, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';

const rand = () => {
  return Math.random().toString(36).substr(2);
};

const remoteToken = () => {
  return rand() + rand() + rand() + rand();
};

const Connectivity = ({ setCurrentTab }) => {
  const history = useHistory();
  const { ocpiId } = useParams();
  const dispatch = useDispatch();

  const roleId = useSelector((state) => state.partnerManagement.partnerInfo.id);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const EmspConnectSchema = Yup.object().shape({
    countryCode: Yup.string().required('Country Code is requried!'),
    partyId: Yup.string().required('Party ID is requried!'),
    url: Yup.string().required('URL is requried!'),
    token: Yup.string().required('Token is requried!'),
  });

  const initialValues = ocpiId
    ? {
        countryCode: ocpiData.countryCode ? ocpiData.countryCode : '971',
        partyId: ocpiData.partyId,
        url: ocpiData.url,
        token: ocpiData.token ? ocpiData.token : remoteToken(),
        token_b: ocpiData.token_b ? ocpiData.token_b : '',
      }
    : {
        countryCode: '971',
        partyId: '',
        url: '',
        token_b: '',
        token: remoteToken(),
      };

  return (
    <React.Fragment>
      <LayoutContainer>
        <Card className="customerBooking-table">
          <Formik
            initialValues={initialValues}
            validationSchema={EmspConnectSchema}
            onSubmit={(data) =>
              dispatch({
                type: UPDATE_CPO,
                payload: { data, roleId },
                cb: (res) => {
                  if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                    setCurrentTab('Financials');
                  }
                },
              })
            }
          >
            {({ values, errors, touched, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <div>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Country Code"
                          placeholder="Enter Country Code"
                          value={values.countryCode}
                          onChange={(val) => setFieldValue('countryCode', val.target.value)}
                          error={errors.countryCode && touched.countryCode ? errors.countryCode : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label="Party Id "
                          placeholder="Enter Party Id "
                          value={values.partyId}
                          onChange={(val) => setFieldValue('partyId', val.target.value)}
                          error={errors.partyId && touched.partyId ? errors.partyId : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Version URL"
                          placeholder="Enter Version URL "
                          value={values.url}
                          onChange={(val) => setFieldValue('url', val.target.value)}
                          error={errors.url && touched.url ? errors.url : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Our Token (Enter token which we will send to party to authenticate request on our server)"
                          placeholder="Enter Token"
                          value={values.token}
                          onChange={(val) => setFieldValue('token', val.target.value)}
                          error={errors.token && touched.token ? errors.token : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          label="Party Token (Enter token which party has shared to authenticate request  on thier server)"
                          placeholder="Enter Party Token"
                          value={values.token_b}
                          onChange={(val) => setFieldValue('token_b', val.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
                <div>&nbsp;</div>
                <div className="cpo_save_btn">
                  <div>
                    <Button className="cpo_save_btn__1 " onClick={() => history.push('/emsp')}>
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button type="submit" className="cpo_save_btn__1">
                      Connect
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </LayoutContainer>
    </React.Fragment>
  );
};

Connectivity.propTypes = {
  setCurrentTab: PropTypes.func,
};

export default Connectivity;
