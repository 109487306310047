import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_COUNTRY,
  GET_COUNTRY,
  CREATE_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
  FETCH_COUNTRY_SUCCESS,
  GET_COUNTRY_SUCCESS,
  CREATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAIL,
  GET_COUNTRY_FAIL,
  CREATE_COUNTRY_FAIL,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY_FAIL,
  FETCH_STATE,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAIL,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAIL,
  CREATE_STATE,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAIL,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
  FETCH_AREA_SUCCESS,
  FETCH_AREA_FAIL,
  FETCH_AREA,
  GET_AREA,
  GET_AREA_SUCCESS,
  GET_AREA_FAIL,
  CREATE_AREA,
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAIL,
  UPDATE_AREA,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAIL,
  DELETE_AREA,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_FAIL,
  FETCH_CITY,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_FAIL,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_FAIL,
  CREATE_CITY,
  CREATE_CITY_SUCCESS,
  CREATE_CITY_FAIL,
  UPDATE_CITY,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  DELETE_CITY,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  GET_CITY_BY_STATE,
  GET_CITY_BY_STATE_SUCCESS,
  GET_CITY_BY_STATE_FAIL,
} from 'actions/address';

function* fetchCountry(action) {
  try {
    const response = yield call(API.getCountry, action.payload);
    yield put({ type: FETCH_COUNTRY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_COUNTRY_FAIL, payload: { error: e } });
  }
}

function* getCountryById(action) {
  try {
    const response = yield call(API.getCountryById, action.payload);
    yield put({ type: GET_COUNTRY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_COUNTRY_FAIL, payload: { error: e } });
  }
}

function* getCityByState(action) {
  try {
    const response = yield call(API.getCityByState, action.payload);
    yield put({ type: GET_CITY_BY_STATE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CITY_BY_STATE_FAIL, payload: { error: e } });
  }
}

function* createCountry(action) {
  try {
    const response = yield call(API.addCountry, action.payload);
    yield put({ type: CREATE_COUNTRY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_COUNTRY_FAIL, payload: { error: e } });
  }
}

function* updateCountry(action) {
  try {
    const response = yield call(API.updateCountry, action.payload);
    yield put({ type: UPDATE_COUNTRY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_COUNTRY_FAIL, payload: { error: e } });
  }
}

function* deleteCountry(action) {
  try {
    const response = yield call(API.deleteCountry, action.payload);
    yield put({ type: DELETE_COUNTRY_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_COUNTRY_FAIL, payload: { error: e } });
  }
}

function* fetchState(action) {
  try {
    const response = yield call(API.getState, action.payload);
    yield put({ type: FETCH_STATE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_STATE_FAIL, payload: { error: e } });
  }
}

function* getStateById(action) {
  try {
    const response = yield call(API.getStateById, action.payload);
    yield put({ type: GET_STATE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_STATE_FAIL, payload: { error: e } });
  }
}

function* createState(action) {
  try {
    const response = yield call(API.addState, action.payload);
    yield put({ type: CREATE_STATE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_STATE_FAIL, payload: { error: e } });
  }
}

function* updateState(action) {
  try {
    const response = yield call(API.updateState, action.payload);
    yield put({ type: UPDATE_STATE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_STATE_FAIL, payload: { error: e } });
  }
}

function* deleteState(action) {
  try {
    const response = yield call(API.deleteState, action.payload);
    yield put({ type: DELETE_STATE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_STATE_FAIL, payload: { error: e } });
  }
}

function* fetchCity(action) {
  try {
    const response = yield call(API.getCity, action.payload);
    yield put({ type: FETCH_CITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CITY_FAIL, payload: { error: e } });
  }
}

function* getCityById(action) {
  try {
    const response = yield call(API.getCityById, action.payload);
    yield put({ type: GET_CITY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CITY_FAIL, payload: { error: e } });
  }
}

function* createCity(action) {
  try {
    const response = yield call(API.addCity, action.payload);
    yield put({ type: CREATE_CITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CITY_FAIL, payload: { error: e } });
  }
}

function* updateCity(action) {
  try {
    const response = yield call(API.updateCity, action.payload);
    yield put({ type: UPDATE_CITY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CITY_FAIL, payload: { error: e } });
  }
}

function* deleteCity(action) {
  try {
    const response = yield call(API.deleteCity, action.payload);
    yield put({ type: DELETE_CITY_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CITY_FAIL, payload: { error: e } });
  }
}

function* fetchArea(action) {
  try {
    const response = yield call(API.getArea, action.payload);
    yield put({ type: FETCH_AREA_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_AREA_FAIL, payload: { error: e } });
  }
}

function* getAreaById(action) {
  try {
    const response = yield call(API.getAreaById, action.payload);
    yield put({ type: GET_AREA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_AREA_FAIL, payload: { error: e } });
  }
}

function* createArea(action) {
  try {
    const response = yield call(API.addArea, action.payload);
    yield put({ type: CREATE_AREA_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_AREA_FAIL, payload: { error: e } });
  }
}

function* updateArea(action) {
  try {
    const response = yield call(API.updateArea, action.payload);
    yield put({ type: UPDATE_AREA_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_AREA_FAIL, payload: { error: e } });
  }
}

function* deleteArea(action) {
  try {
    const response = yield call(API.deleteArea, action.payload);
    yield put({ type: DELETE_AREA_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_AREA_FAIL, payload: { error: e } });
  }
}

function* address() {
  yield takeLatest(FETCH_COUNTRY, fetchCountry);
  yield takeLatest(GET_COUNTRY, getCountryById);
  yield takeLatest(CREATE_COUNTRY, createCountry);
  yield takeLatest(UPDATE_COUNTRY, updateCountry);
  yield takeLatest(DELETE_COUNTRY, deleteCountry);
  yield takeLatest(FETCH_STATE, fetchState);
  yield takeLatest(GET_STATE, getStateById);
  yield takeLatest(CREATE_STATE, createState);
  yield takeLatest(UPDATE_STATE, updateState);
  yield takeLatest(DELETE_STATE, deleteState);
  yield takeLatest(FETCH_CITY, fetchCity);
  yield takeLatest(GET_CITY, getCityById);
  yield takeLatest(CREATE_CITY, createCity);
  yield takeLatest(UPDATE_CITY, updateCity);
  yield takeLatest(DELETE_CITY, deleteCity);
  yield takeLatest(FETCH_AREA, fetchArea);
  yield takeLatest(GET_AREA, getAreaById);
  yield takeLatest(CREATE_AREA, createArea);
  yield takeLatest(UPDATE_AREA, updateArea);
  yield takeLatest(DELETE_AREA, deleteArea);
  yield takeLatest(GET_CITY_BY_STATE, getCityByState);
}

export default address;
