import { get, post, deleteRequest, patch } from 'utils/api';

export const getPermission = (data) => {
  return get(`/permissions`, data);
};

export const getPermissionById = (id) => {
  return get(`/permissions/${id}`);
};

export const addPermission = (data) => {
  return post(`/permissions`, data);
};

export const updatePermission = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/permissions/${id}`, data);
};

export const deletePermission = (id) => {
  return deleteRequest(`/permissions/${id}`);
};

export const permissions = {
  getPermission,
  getPermissionById,
  addPermission,
  updatePermission,
  deletePermission,
};
