import {
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  FETCH_USER,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  CHANGE_ROLE,
  CHANGE_ROLE_SUCCESS,
  CHANGE_ROLE_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from 'actions/user';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  users: [],
  userDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER:
    case CREATE_USER:
    case UPDATE_USER:
    case CHANGE_ROLE:
    case DELETE_USER:
    case GET_USER: {
      return { ...state, isLoading: true };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        users: payload.results,
        isLoading: false,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        userDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_USER_SUCCESS: {
      toast.success('Create User successfully');
      return {
        ...state,
        users: _.concat(state.users, payload),
        isLoading: false,
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        users: state.users.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }
    case CHANGE_ROLE_SUCCESS: {
      toast.success(`User's role change successfully`);
      return {
        ...state,
        users: state.users.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_USER_SUCCESS: {
      // window.location.reload();
      toast.success(`User deleted successfully`);
      return {
        ...state,
        isLoading: false,
        users: state.users.filter((data) => data.id !== payload),
      };
    }

    case FETCH_USER_FAIL:
    case CREATE_USER_FAIL:
    case DELETE_USER_FAIL:
    case UPDATE_USER_FAIL:
    case CHANGE_ROLE_FAIL:
    case GET_USER_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default userReducer;
