import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { UPDATE_FIRMWARE } from 'actions/chargerOcpp';
import * as _ from 'lodash';
import moment from 'moment';

const UpdateFirmwareSchema = Yup.object().shape({
  location: Yup.string()
    .required('Location is required')
    .trim('Space is not allowed')
    .strict(true)
    .max(100, 'Text limit should be less than 100 characters.'),
  retries: Yup.number().required('Retries is required'),
  retryInterval: Yup.number().required('Retry interval is required'),
  retrieveDate: Yup.string().required('Retrieve date is required'),
  retrieveTime: Yup.string().required('Retrieve Time is required'),
});

const UpdateFirmwareForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const initialValues = {
    location: '',
    retries: '',
    retryInterval: '',
    retrieveDate: '',
    retrieveTime: '',
  };

  const getUpdateFirmware = useCallback((data, resetForm) => {
    dispatch({
      type: UPDATE_FIRMWARE,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Firmware update successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">UPDATE FIRMWARE</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={UpdateFirmwareSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const retrieveDateMomentObj = moment(values.retrieveDate + values.retrieveTime, 'YYYY-MM-DDLT');
                const retrieveDate = retrieveDateMomentObj.format('YYYY-MM-DD HH:mm:s');
                const formData = { ...values, id: chargerId, retrieveDate: retrieveDate };
                const updateFirmwareFormData = _.omit(formData, ['retrieveTime']);
                getUpdateFirmware(updateFirmwareFormData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          placeholder="Location"
                          label="Location"
                          value={values.location}
                          name="location"
                          onChange={handleChange}
                          error={errors.location && touched.location ? errors.location : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Retry Interval"
                          placeholder="Retry interval"
                          type="number"
                          value={values.retryInterval}
                          name="retryInterval"
                          onChange={handleChange}
                          error={errors.retryInterval && touched.retryInterval ? errors.retryInterval : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Retries"
                          placeholder="Retries"
                          type="number"
                          value={values.retries}
                          name="retries"
                          onChange={handleChange}
                          error={errors.retries && touched.retries ? errors.retries : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label="Retrieve Date"
                          placeholder="Retrieve date"
                          type="date"
                          value={values.retrieveDate}
                          name="retrieveDate"
                          onChange={handleChange}
                          error={errors.retrieveDate && touched.retrieveDate ? errors.retrieveDate : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label="Retrieve Time"
                          type="time"
                          value={values.retrieveTime}
                          name="retrieveTime"
                          onChange={handleChange}
                          error={errors.retrieveTime && touched.retrieveTime ? errors.retrieveTime : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Update Firmware
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default UpdateFirmwareForm;
