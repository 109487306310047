import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_ZONES, FETCH_ZONES, GET_ZONE } from 'actions/zoneManagement';
import ZonesForm from './ZoneManagementForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';

const ZoneManagement = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [zoneId, setZoneId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state?.zoneManagement?.page);
  const totalData = useSelector((state) => state?.zoneManagement?.total);
  const limit = useSelector((state) => state?.zoneManagement?.limit);
  const totalPages = useSelector((state) => state?.zoneManagement?.totalPages);
  const zoneList = useSelector((state) => state?.zoneManagement?.zones);
  const isLoader = useSelector((state) => state?.zoneManagement?.isLoading);

  const getZones = useCallback(
    (data = {}) => {
      if (searchText) {
        const ZonesData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_ZONES, payload: ZonesData });
      } else {
        dispatch({ type: FETCH_ZONES, payload: data });
      }
    },
    [searchText]
  );

  const getzoneDetails = useCallback((id) => {
    dispatch({ type: GET_ZONE, payload: id });
  }, []);

  const deleteZones = useCallback(
    () =>
      dispatch({
        type: DELETE_ZONES,
        payload: zoneId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getZones({ page: 1 });
          }
        },
      }),
    [zoneId]
  );

  useEffect(() => {
    getZones();
  }, []);

  const handleSearch = () => {
    getZones({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getZones(data);
      }
    },
    [sortByItem]
  );

  const handleCloseFormZones = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getZones(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getZones(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setZoneId('');
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Zones Management" />
        <div className="page-content-wrapper scrollable">
          <div className="zoneList-page">
            <Card>
              <div className="zoneList-page-wrapper">
                <Row className="zoneList-search-box">
                  <Col xl={10} lg={9} md={8} className="zoneList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="zoneList-search-boxButton">
                    <Button
                      className="zoneList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      Add Zones
                    </Button>
                  </Col>
                </Row>
                <div className="zoneList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(zoneList) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">No Zones Data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(zoneList, (item, key) => {
                            return (
                              <>
                                <tr key={`zone-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>
                                    <span
                                      className="zoneList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getzoneDetails(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 />
                                    </span>
                                    <span
                                      className="zoneList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setZoneId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(zoneList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <ZonesForm onClose={handleCloseFormZones} onSuccess={getZones} isEdit={isEdit} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="Zone" onClose={handleCloseDeleteModel} onRemove={deleteZones} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default ZoneManagement;
