import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_MODULE } from 'actions/module';
import Collapse from 'components/inputs/Accordion';
import * as _ from 'lodash';
import CheckBox from 'components/inputs/CheckBox';
import { GET_ROLE, UPDATE_ROLE } from 'actions/role';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/inputs/Button';
import { UPDATE_STATUS_CODE } from 'components/common/constant';

const ConfigureRole = () => {
  const history = useHistory();
  const { roleId } = useParams();
  const dispatch = useDispatch();
  const [allPermissions, setAllPermissions] = useState([]);
  const [checkedPermission, setCheckedPermission] = useState(true);

  const modules = useSelector((state) => state.module.modules);
  const roleDetail = useSelector((state) => state.role.roleDetail);

  const getAllModule = useCallback((data = {}) => {
    dispatch({ type: FETCH_MODULE, payload: data });
  }, []);

  const getAllRole = useCallback((id) => {
    dispatch({ type: GET_ROLE, payload: id });
  }, []);

  const updateRolePermissions = useCallback(() => {
    const PermissionData = {
      permissions: allPermissions,
      id: roleId,
    };
    dispatch({
      type: UPDATE_ROLE,
      payload: PermissionData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          history.push('/role');
        }
      },
    });
  }, [allPermissions, roleId]);

  useEffect(() => {
    getAllModule();
    getAllRole(roleId);
  }, [roleId]);

  // Functionality of get data from another data id match with current Data id...
  const getPermissionDetails = _.filter(roleDetail.permissions, ({ id: id1 }) =>
    _.flattenDepth(
      _.map(
        _.filter(modules, (data) => !_.isEmpty(data.permissions)),
        (item) => item.permissions
      ),
      1
    ).some(({ id: id2 }) => id2 === id1)
  );

  useEffect(() => {
    if (!_.isEmpty(getPermissionDetails) && _.isEmpty(allPermissions) && checkedPermission) {
      setAllPermissions([...allPermissions, ..._.map(getPermissionDetails, (data) => data.id)]);
      setCheckedPermission(false);
    }
  }, [getPermissionDetails, allPermissions, checkedPermission]);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Admin Configure Details" backTo="/role" />
        <div className="page-content-wrapper scrollable">
          <div>
            {_.map(modules, (data, index) => {
              return (
                <div key={index}>
                  <Collapse title={data.name} eventKey={index}>
                    <div className="configuration-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>Page Name</span>
                                </div>
                              </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {_.isEmpty(data.permissions) ? (
                              <tr>
                                <td colSpan={3} className="border-0">
                                  <div className="empty-data-block">No Permission</div>
                                </td>
                              </tr>
                            ) : (
                              _.map(data.permissions, (item, key) => {
                                return (
                                  <>
                                    <tr key={`permission-${key}`}>
                                      <td>{_.get(item, 'name', '')}</td>
                                      <td>
                                        <CheckBox
                                          name="permission"
                                          value={allPermissions}
                                          checked={_.includes(allPermissions, item.id)}
                                          className="configure-check-box"
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                              // add to list
                                              setAllPermissions(_.uniq([...allPermissions, item.id]));
                                            } else {
                                              // remove from list
                                              setAllPermissions(_.uniq(allPermissions.filter((data) => data !== item.id)));
                                            }
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Collapse>
                </div>
              );
            })}
            <div className="configureRole-btn-block">
              <Button type="submit" className="configureRole-form-btn  create-btn" onClick={updateRolePermissions}>
                Save & Update
              </Button>
              <Button className="configureRole-form-btn cancel_btn" onClick={() => history.push('/role')}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default ConfigureRole;
