import { authApi } from './authApi';
import { chargingStation } from './chargingStation';
import { vehicle } from './vehicle';
import { tenant } from './tenant';
import { charger } from './charger';
import { chargerBooking } from './chargerBooking';
import { invoice } from './invoice';
import { user } from './user';
import { oem } from './oem';
import { amenity } from './amenity';
import { subscription } from './subscription';
import { profile } from './profile';
import { otp } from './otp';
import { address } from './address';
import { notification } from './notification';
import { tariff } from './tariff';
import { vehicleMake } from './vehicleMake';
import { vehicleModel } from './vehicleModel';
import { activityLog } from './activityLog';
import { electricitySupplier } from './electricitySupplier';
import { stationAccessType } from './stationAccessType';
import { oemVendor } from './oemVendor';
import { faq } from './faq';
import { privacy } from './privacy';
import { connectorType } from './connectorType';
import { chargeSpeed } from './chargeSpeed';
import { role } from './role';
import { dashboard } from './dashboard';
import { chargeOcpp } from './chargerOcpp';
import { chargerState } from './chargerState';
import { oemErrorCode } from './oemErrorCode';
import { dataReport } from './dataReport';
import { generateQrCode } from './generateQrCode';
import { permissions } from './permissions';
import { offers } from './offers';
import { userGroup } from './userGroup';
import { sms } from './sms';
import { email } from './email';
import { module } from './module';
import { rfid } from './rfid';
import { rfidGroup } from './rfidGroup';
import { chargerStatus } from './chargerStatus';
import { zoneManagement } from './zoneManagement';
import { partnerManagement } from './partnerManagement';

export const API = {
  ...authApi,
  ...chargingStation,
  ...vehicle,
  ...tenant,
  ...charger,
  ...chargerBooking,
  ...invoice,
  ...user,
  ...oem,
  ...amenity,
  ...subscription,
  ...profile,
  ...otp,
  ...address,
  ...notification,
  ...tariff,
  ...vehicleMake,
  ...vehicleModel,
  ...activityLog,
  ...electricitySupplier,
  ...stationAccessType,
  ...oemVendor,
  ...faq,
  ...privacy,
  ...connectorType,
  ...chargeSpeed,
  ...role,
  ...dashboard,
  ...chargeOcpp,
  ...chargerState,
  ...oemErrorCode,
  ...dataReport,
  ...generateQrCode,
  ...permissions,
  ...offers,
  ...userGroup,
  ...sms,
  ...email,
  ...module,
  ...rfid,
  ...rfidGroup,
  ...chargerStatus,
  ...zoneManagement,
  ...partnerManagement,
};
