import { useState, useEffect } from 'react';

const useAudio = () => {
  const [audio] = useState(new Audio('/notification.mp3'));
  const [playing, setPlaying] = useState(false);

  const playNotificationSound = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    playing ? audio?.play() : audio?.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return { playNotificationSound };
};

export default useAudio;
