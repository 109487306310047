import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_TARIFF,
  FETCH_TARIFF_SUCCESS,
  FETCH_TARIFF_FAIL,
  DELETE_TARIFF,
  DELETE_TARIFF_SUCCESS,
  DELETE_TARIFF_FAIL,
  UPDATE_TARIFF,
  UPDATE_TARIFF_SUCCESS,
  UPDATE_TARIFF_FAIL,
  CREATE_TARIFF,
  CREATE_TARIFF_SUCCESS,
  CREATE_TARIFF_FAIL,
  GET_TARIFF,
  GET_TARIFF_SUCCESS,
  GET_TARIFF_FAIL,
} from 'actions/tariff';

function* fetchTariff(action) {
  try {
    const response = yield call(API.getTariff, action.payload);
    yield put({ type: FETCH_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TARIFF_FAIL, payload: { error: e } });
  }
}

function* getTariff(action) {
  try {
    const response = yield call(API.getTariffById, action.payload);
    yield put({ type: GET_TARIFF_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_TARIFF_FAIL, payload: { error: e } });
  }
}

function* createTariff(action) {
  try {
    const response = yield call(API.addTariff, action.payload);
    yield put({ type: CREATE_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_TARIFF_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateTariff(action) {
  try {
    const response = yield call(API.updateTariff, action.payload);
    yield put({ type: UPDATE_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TARIFF_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteTariff(action) {
  try {
    const response = yield call(API.deleteTariff, action.payload);
    yield put({ type: DELETE_TARIFF_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_TARIFF_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* tariff() {
  yield takeLatest(FETCH_TARIFF, fetchTariff);
  yield takeLatest(GET_TARIFF, getTariff);
  yield takeLatest(CREATE_TARIFF, createTariff);
  yield takeLatest(UPDATE_TARIFF, updateTariff);
  yield takeLatest(DELETE_TARIFF, deleteTariff);
}
export default tariff;
