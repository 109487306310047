import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { CREATE_OEM_ERROR_CODE, UPDATE_OEM_ERROR_CODE } from 'actions/oemErrorCode';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_CHARGER_STATE } from 'actions/chargerState';

const OemErrorCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required('Oem error code is required')
    .strict(true)
    .trim('Space is not allowed')
    .matches(/^[A-Za-z0-9\s]+$/, 'Only alphabets and numbers are allowed'),
  vendor: Yup.string().required('Vendor name is required').max(100, 'Text limit should be less than 100 characters'),
  state: Yup.string().required('State name is required'),
});

const OemErrorCodeForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();

  const oemErrorCodeDetail = useSelector((state) => state.oemErrorCode.oemErrorCodeDetail);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const allChargerState = useSelector((state) => state.chargerState.chargerStates);
  const isLoading = useSelector((state) => state.oemErrorCode.isLoading);
  const oemPage = useSelector((state) => state.oemVendor.page);
  const oemTotalPage = useSelector((state) => state.oemVendor.totalPages);

  const addOemErrorCode = useCallback((data) => {
    dispatch({
      type: CREATE_OEM_ERROR_CODE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateOemErrorCode = useCallback((data) => {
    dispatch({
      type: UPDATE_OEM_ERROR_CODE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllOemVendor = useCallback(() => {
    dispatch({ type: FETCH_OEM_VENDOR, payload: {} });
  }, []);

  const getAllChargerState = useCallback(() => {
    dispatch({ type: FETCH_CHARGER_STATE, payload: {} });
  }, []);

  useEffect(() => {
    getAllOemVendor();
    getAllChargerState();
  }, []);

  const initialValues = isEdit
    ? { ...oemErrorCodeDetail, vendor: _.get(oemErrorCodeDetail, 'vendor.id', ''), state: _.get(oemErrorCodeDetail, 'state.id', '') }
    : {
        code: '',
        vendor: '',
        state: '',
      };
  return (
    <React.Fragment>
      <div className="oemErrorCode-form-page__main">
        <Card>
          <div className="oemErrorCode-form-header__block">
            <div className="oemErrorCode-header-name">
              <span>{isEdit ? 'Edit' : 'Add'} Oem Error Code </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="oemErrorCode-form-body__block">
            <div className="oemErrorCode-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={OemErrorCodeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateOemErrorCode(values);
                  } else {
                    addOemErrorCode(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label="Oem Error Code"
                          placeholder="Oem error code"
                          name="code"
                          value={_.get(values, 'code', '')}
                          onChange={handleChange}
                          error={errors.code && touched.code ? errors.code : null}
                        />
                      </Col>
                      <Col md={12}>
                        <Select
                          isRequired
                          label="Vendor Name"
                          options={_.map(allOemVendor, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder="Select vendor name"
                          name="vendor"
                          value={_.get(values, 'vendor')}
                          onChange={(val) => setFieldValue(`vendor`, val)}
                          error={errors.vendor && touched.vendor ? errors.vendor : null}
                        />
                      </Col>
                      <Col md={12}>
                        <Select
                          isRequired
                          label="State Name"
                          options={_.map(allChargerState, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllOemVendor}
                          page={oemPage}
                          totalPage={oemTotalPage}
                          placeholder="Select state name"
                          name="state"
                          value={_.get(values, 'state')}
                          onChange={(val) => setFieldValue(`state`, val)}
                          error={errors.state && touched.state ? errors.state : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
OemErrorCodeForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default OemErrorCodeForm;
