import { get } from 'utils/api';

export const getChargerStatus = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return get(`/chargingStations?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return get(`/chargingStations?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return get(`/chargingStations?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return get(`/chargingStations?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return get(`/chargingStations?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return get(`/chargingStations?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return get(`/chargingStations?page=${parseInt(page)}`, data);
  }
};

export const chargerStatus = {
  getChargerStatus,
};
