import { CHARGER_STATUS, CHARGER_STATUS_FAIL, CHARGER_STATUS_SUCCESS } from 'actions/chargerStatus';

const initialState = {
  isLoading: false,
  chargerStatus: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerStatusReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHARGER_STATUS: {
      return { ...state, isLoading: true };
    }
    case CHARGER_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerStatus: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case CHARGER_STATUS_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerStatusReducer;
