import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import Select from 'components/inputs/Select';
// import { AiOutlineDownload } from 'react-icons/ai';
import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
// import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { JSON_REQUEST_RESPONSE_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { startsWith } from 'components/common/utils';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';

const JsonRequestResponseReport = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  // const [fieldValue, setFieldValue] = useState('All');

  const [allJsonRequestResponseReportData, setAllJsonRequestResponseReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const jsonRequestResponseReportList = useSelector((state) => state.jsonRequestResponseReport.jsonRequestResponseReports);

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  const jsonRequestResponseReport = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
      };
      dispatch({ type: JSON_REQUEST_RESPONSE_REPORT, payload: data });
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    const filterDateData = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
    };
    jsonRequestResponseReport(filterDateData);
  }, [startDate, endDate]);

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && jsonRequestResponseReport(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && jsonRequestResponseReport(oldestData);
  //   fieldValue === 'All' && jsonRequestResponseReport();
  // }, [fieldValue]);

  useEffect(() => {
    setAllJsonRequestResponseReportData(
      _.filter(jsonRequestResponseReportList, (item) => startsWith(_.get(item, 'charger.charger_id', ''), searchText))
    );
  }, [searchText, jsonRequestResponseReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    jsonRequestResponseReport();
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Json Request Response Report" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col xl={6} md={12}>
                      <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />
                    </Col>
                    {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                    <Col xl={5} md={9}>
                      <div className="data-report-date_picker">
                        <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={3}>
                      <div className="log-search-box" onClick={dateFilter}>
                        <Button className="log-search-button">Search</Button>
                      </div>
                    </Col>
                    {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive  data-report_inner">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>OPPID</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>ReqString</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>ResString</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>ReqDateIST </span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>ResDateIST</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allJsonRequestResponseReportData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No Json Request Response Report Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allJsonRequestResponseReportData, (item, key) => {
                            return (
                              <>
                                <tr key={`json-request-response-report-${key}`}>
                                  <td>{key + 1}</td>
                                  <td>{_.get(item, 'charger.charger_id', '')}</td>
                                  <td className="json-data__block">
                                    <div className="json--data">{JSON.stringify(_.get(item, 'message', ''))}</div>
                                  </td>
                                  <td className="json-data__block">
                                    <div className="json--data">{JSON.stringify(_.get(item, 'response', ''))}</div>
                                  </td>
                                  {/* <td>{moment(_.get(item, 'date_time.from', '-')).format('DD/MM/YYYY HH:mm:ss')}</td> */}
                                  <td>{_.get(item, 'date_time.from') ? moment(_.get(item, 'date_time.from')).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                                  <td>{item.response_time ? moment(item.response_time).format('DD/MM/YYYY HH:mm:ss') : ''}</td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default JsonRequestResponseReport;
