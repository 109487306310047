import { get, post, deleteRequest, patch } from 'utils/api';

export const getRole = (data) => {
  return get(`/roles`, data);
};

export const getRoleById = (id) => {
  return get(`/roles/${id}`);
};

export const addRole = (data) => {
  return post(`/roles`, data);
};

export const updateRole = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/roles/${id}`, data);
};

export const deleteRole = (id) => {
  return deleteRequest(`/roles/${id}`);
};

export const role = {
  getRole,
  getRoleById,
  addRole,
  updateRole,
  deleteRole,
};
