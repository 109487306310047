import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPromiseAction } from '@adobe/redux-saga-promise';
import { FETCH_PROFILE, GET_PROFILE_SUCCESS } from 'actions/profile';
import PropTypes from 'prop-types';
import { cookie } from 'utils/cookies/cookies';
import MediaQuery from 'react-responsive';
import MainLayout from 'components/layout/index';
import { onMessageListener } from '../../firebase';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { MessagePopup } from 'App';
import { GET_DASHBOARD_DATA } from 'actions/dashboard';
import { FETCH_CHARGER_BY_STATION, GET_CHARGER } from 'actions/charger';
import { FETCH_NOTIFICATION } from 'actions/notification';
import useAudio from 'hooks/useAudio';
import moment from 'moment';
import { CPO } from 'components/common/constant';

export const RealTimeData = createContext();

const PrivateRoute = ({ component: Component, roles, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = cookie.get('token');
  const role = cookie.get('user-role');
  const [isAllowed, setIsAllowed] = useState(false);
  const [realTimeMessageType, setRealTimeMessageType] = useState(false);
  const userData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = userData.charging_stations;
  const { playNotificationSound } = useAudio();

  const getProfile = useCallback((data) => {
    const fetchPostAction = createPromiseAction(FETCH_PROFILE);
    return dispatch(fetchPostAction(data));
  }, []);

  const setUser = useCallback((data) => dispatch({ type: GET_PROFILE_SUCCESS, payload: data }), []);

  const getDashboard = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_DATA });
  }, []);

  const getChargers = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGER_BY_STATION, payload: data });
  }, []);

  const getChargerDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER, payload: id });
  }, []);

  const getGeneralNotification = useCallback((data = {}) => {
    dispatch({ type: FETCH_NOTIFICATION, payload: data });
  }, []);

  const onMessageReceived = useCallback(
    (payload) => {
      setRealTimeMessageType(payload.data.type);
      if (payload.data.type === 'update_dashboard') {
        if (location.pathname === '/dashboard') {
          // call dashboard api
          getDashboard();
        } else if (payload.data.for === 'charger' && location.pathname === `/stations/${get(payload, 'data.station')}`) {
          // call charger by Station Api
          getChargers({ id: get(payload, 'data.station') });
        } else if (payload.data.for === 'charger' && location.pathname === `/charger/${get(payload, 'data.charger')}`) {
          // call charger Api
          getChargerDetail(get(payload, 'data.charger'));
        }
      } else {
        //show toast message
        if (location.pathname === '/notification') {
          const allNotification = {
            from: moment(moment().startOf('month')).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(moment(new Date()).endOf('day')).format('YYYY-MM-DD HH:mm:ss'),
          };
          getGeneralNotification(allNotification);
        }
        const title = get(payload, 'data.title', '');
        const description = get(payload, 'data.description', '');
        const options = {
          autoClose: 6000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.TOP_RIGHT,
          pauseOnHover: true,
        };
        if (title) {
          toast(<MessagePopup title={title} description={description} />, options);
          playNotificationSound();
        }
      }
    },
    [location]
  );

  onMessageListener()
    .then((payload) => {
      if (role === CPO) {
        if (!userProfileStations || userProfileStations && userProfileStations?.includes(payload.data.charging_station)) {
          onMessageReceived(payload);
        }
      }
    })
    .catch((err) => console.error('Failed: ', err));

  useEffect(() => {
    if (!roles && token) {
      getProfile()
        .then((res) => {
          const user2 = res.data;
          setUser(user2);
          setIsAllowed(true);
        })
        .catch((err) => {
          console.error(err);
          cookie.clean();
        });
    } else if (roles && !token) {
      history.push('/login');
    } else if (roles && token) {
      if (!userData && !token && roles.length > 0) {
        history.push('/login');
      } else {
        getProfile()
          .then((res) => {
            const user2 = res.data;
            if (roles.includes(user2.role)) {
              setUser(user2);
              setIsAllowed(true);
            } else {
              if (token) {
                setIsAllowed(false);
                history.push('/');
              } else {
                setIsAllowed(false);
                history.push('/login');
              }
            }
          })
          .catch((err) => {
            console.error(err);
            cookie.clean();
            history.push('/login');
          });
      }
    } else {
      setIsAllowed(true);
    }
  }, []);

  return isAllowed ? <AdminRoute {...props} component={Component} realTimeMessageType={realTimeMessageType} /> : null;
};

export default PrivateRoute;
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
  roles: PropTypes.arrayOf(PropTypes.string),
};

export const AdminRoute = ({ component: Component, realTimeMessageType, ...rest }) => {
  // const previousLocation = useRef(rest.location.pathname + rest.location.search);
  // const location = rest.location.pathname === '/logout' ? previousLocation.current : rest.location.pathname + rest.location.search;
  // localStorage.setItem('pathname', JSON.stringify(location));
  //
  // useEffect(() => {
  //   previousLocation.current = rest.location.pathname + rest.location.search;
  // }, [rest.location.pathname + rest.location.search]);

  return (
    <RealTimeData.Provider value={realTimeMessageType}>
      <Route
        {...rest}
        render={(props) => (
          <MediaQuery minWidth={800}>
            {(matches) => {
              return (
                <>
                  <MainLayout {...props} showToggle={!matches}>
                    <Component {...props} />
                  </MainLayout>
                </>
              );
            }}
          </MediaQuery>
        )}
      />
    </RealTimeData.Provider>
  );
};
AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
  realTimeMessageType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
