import {
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  FETCH_VEHICLE_FAIL,
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAIL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_SUCCESS,
} from 'actions/vehicle';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  vehicles: [],
  vehicleDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const vehicleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_VEHICLE:
    case CREATE_VEHICLE:
    case GET_VEHICLE:
    case DELETE_VEHICLE: {
      return { ...state, isLoading: true, vehicleDetail: {} };
    }

    case UPDATE_VEHICLE: {
      return { ...state, isLoading: true };
    }

    case FETCH_VEHICLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vehicles: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_VEHICLE_SUCCESS: {
      return {
        ...state,
        vehicleDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VEHICLE_SUCCESS: {
      toast.success('Vehicle added successfully');
      return {
        ...state,
        vehicles: _.concat(state.vehicles, payload),
        isLoading: false,
      };
    }

    case UPDATE_VEHICLE_SUCCESS: {
      toast.success('Vehicle updated successfully');
      return {
        ...state,
        isLoading: false,
        vehicles: state.vehicles.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_VEHICLE_SUCCESS: {
      toast.success('Vehicle deleted successfully');
      return {
        ...state,
        isLoading: false,
        vehicles: state.vehicles.filter((data) => data.id !== payload),
      };
    }

    case FETCH_VEHICLE_FAIL:
    case CREATE_VEHICLE_FAIL:
    case GET_VEHICLE_FAIL:
    case DELETE_VEHICLE_FAIL:
    case UPDATE_VEHICLE_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default vehicleReducer;
