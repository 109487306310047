import React, { useCallback, useEffect, useState } from 'react';
import * as _ from 'lodash';
// import Select from 'components/inputs/Select';
import Avatar from 'components/inputs/Avatar';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USER, DELETE_USER, UPDATE_USER } from 'actions/user';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { SUPER_ADMIN, DOCO } from 'components/common/constant'; //, CPO, SUPPORT,TENANT_ADMIN,
import { Modal, Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
// import { FETCH_ROLE } from 'actions/role';
import { BiSort } from 'react-icons/bi';
// import { FaChargingStation } from 'react-icons/fa';
import { useHistory } from 'react-router';
import LayoutContainer from 'components/layout/LayoutContainer';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { startsWith } from 'components/common/utils';
import { AiOutlinePlus } from 'react-icons/ai';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
// import { FETCH_TARIFF } from 'actions/tariff';
import { FiEdit2 } from 'react-icons/fi';
import { roundOfDigit } from 'components/common/utils';
import { FaLock, FaUnlockAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

const BusinessPartner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userId, setUserId] = useState();
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [allUserData, setAllUserData] = useState([]);

  // const profileData = useSelector((state) => state.profile.userProfile);
  const isLoader = useSelector((state) => state.user.isLoading);
  const allUsers = useSelector((state) => state.user.users);
  // const allRoles = useSelector((state) => state.role.roles).filter((value) => !value.is_hidden);
  const page = useSelector((state) => state.user.page);
  const totalData = useSelector((state) => state.user.total);
  const limit = useSelector((state) => state.user.limit);
  const totalPages = useSelector((state) => state.user.totalPages);
  // const allTariffList = useSelector((state) => state.tariff.tariff);

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  const getAllUsers = useCallback((data) => {
    dispatch({
      type: FETCH_USER,
      payload: data ? data : null,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          getAllUsers({ page: 1, role: DOCO });
        }
      },
    });
  }, []);

  // const getTariff = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_TARIFF, payload: data });
  // }, []);

  // const getAllRoles = useCallback((data) => {
  //   dispatch({ type: FETCH_ROLE, payload: data ? data : {} });
  // }, []);

  const deleteUser = useCallback((id) => {
    dispatch({ type: DELETE_USER, payload: id });
    handleCloseDeleteModel();
  }, []);

  // const editUserPLU = useCallback(
  //   (plu, userId) => {
  //     const userRole = {
  //       id: userId,
  //       tariff: plu,
  //     };
  //     dispatch({
  //       type: UPDATE_USER,
  //       payload: userRole,
  //       cb: (res) => {
  //         if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
  //           getAllUsers({ page: 1, role: DOCO });
  //         }
  //       },
  //     });
  //   },
  //   [allUsers]
  // );

  const editLockUser = useCallback(
    (id) => {
      const data = {
        id: id,
        isActive: 'false',
      };
      dispatch({
        type: UPDATE_USER,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              toast.success('User is not active');
              getAllUsers({ page: 1, role: DOCO });
            }
          }
        },
      });
    },
    [allUsers]
  );

  const editUnLockUser = useCallback(
    (id) => {
      const data = {
        id: id,
        isActive: 'true',
      };
      dispatch({
        type: UPDATE_USER,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              toast.success('User is active');
              getAllUsers({ page: 1, role: DOCO });
            }
          }
        },
      });
    },
    [allUsers]
  );

  useEffect(() => {
    getAllUsers({ page, role: DOCO });
    // getTariff();
    // getAllRoles();
  }, []);

  useEffect(() => {
    if (!searchText) {
      setAllUserData(allUsers);
      return;
    }
    setAllUserData(_.filter(allUserData, (data) => startsWith(_.get(data, 'name', ''), searchText)));
  }, [searchText, allUsers]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
          role: DOCO,
        };
        getAllUsers(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        role: DOCO,
      };
      getAllUsers(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        role: DOCO,
      };
      getAllUsers(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title=" Manage Business Partner" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-page_main">
            <div className="user-group-main-block">
              <Card>
                <div className="user-group-block">
                  <div className="user-group-search-box">
                    <Row>
                      <Col xl={10}>
                        <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                      </Col>
                      <Col xl={2}>
                        <Button className="user-group-btn" onClick={() => history.push('/businessPartner/addBusinessPartner')}>
                          <span className="me-2">
                            <AiOutlinePlus color={'#FFFFFF'} />
                          </span>
                          Add Business Partner
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="role-access-page__main">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Sr.no</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Company Name</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>POC Name</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>POC Email</span>
                                <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>POC Phone No.</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>PLU</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Monthly Limit</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Remaining Limit</span>
                              </div>
                            </th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader-block___">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allUserData) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">No Users</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(allUserData, (userData) => userData.role !== SUPER_ADMIN),
                              (user, key) => {
                                const serial_num = limit * (page - 1) + key;

                                const remainingLimit =
                                  _.get(user, 'unit_consumed_limit', '') - _.get(user, 'unit_consumed_till_now', '')
                                    ? _.get(user, 'unit_consumed_limit', '') - _.get(user, 'unit_consumed_till_now', '')
                                    : '0';

                                const remainingAEDLimit =
                                  _.get(user, 'aed_consumed_limit', '') - _.get(user, 'aed_amount_used', '')
                                    ? _.get(user, 'aed_consumed_limit', '') - _.get(user, 'aed_amount_used', '')
                                    : '0';
                                // const isCurrentUser = _.get(profileData, 'id', '') === _.get(user, 'id', '');
                                return (
                                  <tr key={`user-index-${serial_num}`}>
                                    <td>{serial_num + 1}</td>
                                    <td>{_.get(user, 'company', '')}</td>
                                    <td>
                                      <div className="role-access-name-list">
                                        <Avatar imgSrc={_.get(user, 'picture', '')} className="user-avatar" />
                                        <span>{_.get(user, 'name', '')}</span>
                                      </div>
                                    </td>
                                    <td>{_.get(user, 'email', '')}</td>
                                    <td>
                                      {_.get(user, 'country_code', '')}
                                      {_.get(user, 'phone', '')}
                                    </td>
                                    <td>
                                      {_.get(user, 'tariff.plu', '')}
                                      {/* <div className="role-access-role"> */}
                                      {/* <Select
                                          className="roles-select__box"
                                          value={_.get(user, 'tariff', '')}
                                          options={_.map(allTariffList, (item) => {
                                            return { label: _.startCase(item?.plu), value: item?.id };
                                          })}
                                          onChange={(val) => {
                                            editUserPLU(val, user.id);
                                          }}
                                        /> */}
                                      {/* <Select
                                          isDisabled={_.get(profileData, 'role', '') !== TENANT_ADMIN || isCurrentUser}
                                          className="roles-select__box"
                                          value={_.get(user, 'role', '')}
                                          options={_.map(allRoles, (item) => {
                                            return { label: _.startCase(item.name), value: item.name };
                                          })}
                                          onChange={(val) => {
                                            editUserRole(val, user.id);
                                          }}
                                        /> */}
                                      {/* </div> */}
                                    </td>
                                    <td>
                                      {user.units === 'units(kW)'
                                        ? _.get(user, 'unit_consumed_limit') === 0
                                          ? 'No Limit'
                                          : `${roundOfDigit(_.get(user, 'unit_consumed_limit', 0), 2)} kWh`
                                        : _.get(user, 'aed_consumed_limit') === 0
                                        ? 'No Limit'
                                        : `${roundOfDigit(_.get(user, 'aed_consumed_limit', 0), 2)} AED`}
                                    </td>
                                    <td>
                                      {user.units === 'units(kW)'
                                        ? _.get(user, 'unit_consumed_limit') === 0
                                          ? 'No Limit'
                                          : `${roundOfDigit(remainingLimit, 2)} kWh`
                                        : _.get(user, 'aed_consumed_limit') === 0
                                        ? 'No Limit'
                                        : `${roundOfDigit(remainingAEDLimit, 2)} AED`}
                                    </td>
                                    {/* {_.get(profileData, 'role', '') === TENANT_ADMIN && !isCurrentUser && ( */}
                                    <td>
                                      <div>
                                        {/* {(_.get(user, 'role', '') === DOCO ) && (
                                            <span className="role-access-charging-station-icon">
                                              <FaChargingStation
                                                size={24}
                                                onClick={() => {
                                                  history.push({pathname: `/stationWiseAccess/${_.get(user, 'id')}`, state: {name:"businessPartner"}});
                                                }}
                                              />
                                            </span>
                                          )} */}
                                        {_.get(user, 'isActive') ? (
                                          <span className="userList-lock-unlock-icon" onClick={() => editLockUser(user.id)}>
                                            <FaUnlockAlt size={22} />
                                          </span>
                                        ) : (
                                          <span className="userList-lock-unlock-icon" onClick={() => editUnLockUser(user.id)}>
                                            <FaLock size={22} />
                                          </span>
                                        )}
                                        <span className="role-access-charging-station-icon">
                                          <FiEdit2
                                            size={20}
                                            onClick={() => {
                                              history.push(`/editBusinessPartner/${_.get(user, 'id')}`);
                                            }}
                                          />
                                        </span>
                                        <span className="role-access-delete-icon">
                                          <RiDeleteBinLine
                                            size={24}
                                            onClick={() => {
                                              setUserId(_.get(user, 'id'));
                                              setShowDeleteModal(true);
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    {/* )} */}
                                  </tr>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allUsers) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                  </div>
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="user" onClose={handleCloseDeleteModel} onRemove={() => deleteUser(userId)} />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default BusinessPartner;
