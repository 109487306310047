import { get, post, deleteRequest, patch } from 'utils/api';

export const getRfid = (data) => {
  return get(`/rfids`, data);
};

export const getRfidById = (id) => {
  return get(`/rfids/${id}`);
};

export const addRfid = (data) => {
  return post(`/rfids`, data);
};

export const updateRfid = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/rfids/${id}`, data);
};

export const deleteRfid = (id) => {
  return deleteRequest(`/rfids/${id}`);
};

export const addBulkRfid = (data) => {
  return post(`/rfids/bulk_add`, data);
};

export const rfid = {
  getRfid,
  getRfidById,
  addRfid,
  updateRfid,
  deleteRfid,
  addBulkRfid,
};
