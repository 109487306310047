import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCaretDown, AiOutlineUser } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import ActivityItem from 'components/general/ActivityItem';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { FETCH_NOTIFICATION } from 'actions/notification';
// import Pagination from 'components/general/Pagination';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import DropDown from 'components/inputs/DropDown';
import { AiOutlineUserAdd, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FaUserTimes, FaUserClock, FaChargingStation } from 'react-icons/fa';
import { MdBattery20, MdBatteryUnknown, MdOutlineWifiOff } from 'react-icons/md';
import { FiRefreshCcw } from 'react-icons/fi';
import { IoMdBatteryCharging } from 'react-icons/io';
// import CheckBox from 'components/inputs/CheckBox';
import { Row, Col, Spinner } from 'react-bootstrap';
import SessionHeading from 'components/general/SessionHeading';
import Button from 'components/inputs/Button';
import DateTimePicker from 'components/inputs/DateTimePicker';
import ReactPagination from 'components/general/ReactPagination';

const GeneralNotification = () => {
  const dispatch = useDispatch();
  const allGeneralNotification = useSelector((state) => state.notification.notifications);
  const isLoader = useSelector((state) => state.notification.isLoading);
  const page = useSelector((state) => state.notification.page);
  const totalData = useSelector((state) => state.notification.total);
  const limit = useSelector((state) => state.notification.limit);
  const totalPages = useSelector((state) => state.notification.totalPages);

  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [fieldValue, setFieldValue] = useState('All Notification');
  const [type, setType] = useState('');

  const getGeneralNotification = useCallback((data = {}) => {
    dispatch({ type: FETCH_NOTIFICATION, payload: data });
  }, []);

  // const deleteNotification = useCallback((id) => {
  //   dispatch({ type: DELETE_NOTIFICATION, payload: id });
  // }, []);

  useEffect(() => {
    if (type && startDate && endDate) {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        message_type: type,
      };
      const allNotification = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      type === 'All' ? getGeneralNotification(allNotification) : endDate && getGeneralNotification(filterDateData);
    } else if (startDate && endDate) {
      const filterDateType = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      endDate && getGeneralNotification(filterDateType);
    } else if (type) {
      const filterDateType = {
        message_type: type,
      };
      type === 'All' ? getGeneralNotification() : getGeneralNotification(filterDateType);
    }
  }, [startDate, endDate, type]);

  const handlePageClick = (page) => {
    if (page && type && startDate && endDate) {
      const filterData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        message_type: type,
        page: page.selected + 1,
      };
      const allNotification = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        page: page.selected + 1,
      };
      type === 'All' ? getGeneralNotification(allNotification) : endDate && getGeneralNotification(filterData);
    } else if (page && startDate && endDate) {
      const filterData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        page: page.selected + 1,
      };
      endDate && getGeneralNotification(filterData);
    } else if (page && type) {
      const filterDateType = {
        message_type: type,
        page: page.selected + 1,
      };
      const allNotification = {
        page: page.selected + 1,
      };
      type === 'All' ? getGeneralNotification(allNotification) : getGeneralNotification(filterDateType);
    } else if (page) {
      const data = {
        page: page.selected + 1,
      };
      getGeneralNotification(data);
    }
  };

  const dateFilter = useCallback(() => {
    const filterDateData = {
      from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
    };
    getGeneralNotification(filterDateData);
  }, [startDate, endDate]);

  const notification = [
    { label: 'All Notification', value: 'All' },
    { label: 'Charger Stopped', value: 'charger_stopped' },
    { label: 'Connectivity Failure', value: 'connectivity_failure' },
    { label: 'Power Loss', value: 'power_loss' },
    { label: 'Charger Fixed', value: 'charger_fixed' },
    { label: 'Charger Errored', value: 'charger_errored' },
    { label: 'Power Loss User', value: 'power_loss_user' },
    { label: 'Charger Fixed User', value: 'charger_fixed_user' },
    { label: 'charger Errored User', value: 'charger_errored_user' },
    { label: 'User Booking Cancel', value: 'user_booking_cancel' },
    { label: 'User Booking Create', value: 'user_booking_create' },
    { label: 'User Booking Reschedule', value: 'user_booking_reschedule' },
    { label: 'User Booking Changed', value: 'user_booking_changed' },
    { label: 'Update Firmware', value: 'update_firmware' },
  ];

  const notificationIcon = [
    { label: 'connectivity_failure', value: <MdOutlineWifiOff /> },
    { label: 'power_loss', value: <MdBattery20 /> },
    { label: 'charger_fixed', value: <FaChargingStation /> },
    { label: 'charger_errored', value: <MdBatteryUnknown /> },
    { label: 'user_booking_cancel', value: <FaUserTimes /> },
    { label: 'user_booking_create', value: <AiOutlineUserAdd /> },
    { label: 'user_booking_reschedule', value: <FaUserClock /> },
    { label: 'user_booking_changed', value: <AiOutlineUsergroupAdd /> },
    { label: 'update_firmware', value: <FiRefreshCcw /> },
    { label: 'charger_status', value: <IoMdBatteryCharging /> },
    { label: 'user_booking', value: <AiOutlineUsergroupAdd /> },
  ];

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Notifications" />
        <div className="page-content-wrapper scrollable">
          <div className="notification-details-page__main">
            <Card>
              <SessionHeading
                text="General"
                // number={
                //   <div className="notification-number">
                //     <span className="notification-text">
                //       {_.size(
                //         _.filter(allGeneralNotification, (item) => {
                //           return item.message_type !== 'firmware_update';
                //         })
                //       )}
                //     </span>
                //   </div>
                // }
                className="activity-header--block"
              >
                <div className="session-heading-right-content">
                  <div className="filterData---notification">
                    <DropDown
                      popupClass="main-menu bottom scrollable"
                      trigger={
                        <>
                          <div className="log-filter-block">
                            <div className="log-filter-icon">
                              <BsFilter />
                              <span className="log-filter-label">{fieldValue}</span>
                            </div>
                            <div className="log-arrow__icon">
                              <AiFillCaretDown />
                            </div>
                          </div>
                        </>
                      }
                      triggerClass="log-filter-dropdown"
                    >
                      {_.map(notification, (item, index) => (
                        <ul key={`${index}`}>
                          <li
                            className="item"
                            onClick={() => {
                              setFieldValue(item.label);
                              setType(item.value);
                            }}
                          >
                            {item.label}
                          </li>
                        </ul>
                      ))}
                    </DropDown>
                  </div>
                  <Row>
                    <Col xl={10} md={10}>
                      <DateTimePicker
                        onChangeOfStartDate={(item) => setStartDate(item)}
                        onChangeOfEndDate={(item) => setEndDate(item)}
                        initialValueOfStartDate={moment(new Date()).startOf('day')}
                        initialValueOfEndDate={moment(new Date()).endOf('day')}
                      />
                    </Col>
                    <Col xl={2} md={2}>
                      <div className="log-search-excel-block">
                        <div className="log-search-box" onClick={dateFilter}>
                          <Button className="log-search-button">Search</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SessionHeading>
              <div className="general-notification__main">
                {isLoader ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                    <span className="ps-2">Loading...</span>
                  </div>
                ) : _.isEmpty(allGeneralNotification) ? (
                  <div className="empty-data-block">No Notifications</div>
                ) : (
                  !(isLoader || _.isUndefined(isLoader)) && (
                    <>
                      {_.map(
                        _.filter(allGeneralNotification, (item) => {
                          return item.message_type !== 'firmware_update';
                        }),
                        (notification, index) => {
                          return (
                            <ActivityItem
                              key={`notification-${index}`}
                              icon={
                                _.get(
                                  notificationIcon.find((item) => item.label === notification.message_type),
                                  'value'
                                ) ? (
                                  _.get(
                                    notificationIcon.find((item) => item.label === notification.message_type),
                                    'value'
                                  )
                                ) : (
                                  <AiOutlineUser />
                                )
                              }
                              text={
                                <>
                                  {/* Charger 3 <span className="meta-info">(ID: Ch785G45) in </span>Dolmur <span className="meta-info">is not connected to the internet</span> */}
                                  {_.get(notification, 'from', '')} {''}
                                  <span className="meta-info">{_.get(notification, 'message', '')}</span> {_.get(notification, 'to', '')}
                                </>
                              }
                              date={moment(_.get(notification, 'createdAt')).format('dddd, DD MMMM, h:mm A')}
                              // dropdownList={
                              //   <>
                              //     <li className="item">Mark as read</li>
                              //     <li className="item" onClick={() => deleteNotification(_.get(notification, 'id', ''))}>
                              //       Delete Notification
                              //     </li>
                              //   </>
                              // }
                            />
                          );
                        }
                      )}
                      {/*<Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} /> */}
                    </>
                  )
                )}
              </div>
              {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allGeneralNotification) && (
                <ReactPagination
                  currentPage={page}
                  limit={limit}
                  total={totalData}
                  handlePageClick={(pageVal) => handlePageClick(pageVal)}
                  totalPages={totalPages}
                  marginPagesDisplayed={1}
                />
              )}
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default GeneralNotification;
