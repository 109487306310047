import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import { DELETE_SMS, FETCH_SMS, GET_SMS } from 'actions/sms';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import SmsForm from './SmsForm';

const SmsList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [allSmsData, setAllSmsData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [smsId, setSmsId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.sms.page);
  const totalData = useSelector((state) => state.sms.total);
  const limit = useSelector((state) => state.sms.limit);
  const totalPages = useSelector((state) => state.sms.totalPages);
  const smsList = useSelector((state) => state.sms.sms);
  const isLoader = useSelector((state) => state.sms.isLoading);

  const getSms = useCallback((data = {}) => {
    dispatch({ type: FETCH_SMS, payload: data });
  }, []);

  const getSmsDetail = useCallback((id) => {
    dispatch({ type: GET_SMS, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setSmsId('');
  };

  const deleteSms = useCallback(
    () =>
      dispatch({
        type: DELETE_SMS,
        payload: smsId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getSms({ page });
          }
        },
      }),
    [smsId]
  );

  useEffect(() => {
    getSms();
  }, []);

  useEffect(() => {
    setAllSmsData(
      _.filter(
        smsList,
        (item) =>
          startsWith(_.get(item, 'name', ''), searchText) ||
          startsWith(_.get(item, 'slug', ''), searchText) ||
          startsWith(_.get(item, 'message', ''), searchText)
      )
    );
  }, [searchText, smsList]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getSms(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getSms(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getSms(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormSms = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="SMS Template List" />
        <div className="page-content-wrapper scrollable">
          <div className="smsList-page">
            <Card>
              <div className="smsList-page-wrapper">
                <Row className="smsList-search-box">
                  <Col xl={10} lg={9} md={8} className="smsList-search-inputBox">
                    <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="smsList-search-boxButton">
                    <Button
                      className="smsList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      Add SMS
                    </Button>
                  </Col>
                </Row>
                <div className="smsList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Slug</span>
                              <span className="ico" onClick={() => handleSorting('slug', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>URL</span>
                              <span className="ico" onClick={() => handleSorting('url', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>Message</span>
                              <span className="ico" onClick={() => handleSorting('message', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          <th className="action-col">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(allSmsData) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">No SMS Data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(allSmsData, (item, key) => {
                            return (
                              <>
                                <tr key={`sms-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>{_.get(item, 'slug', '')}</td>
                                  {/* <td>                                  
                                      {_.get(item, 'url', '')}                                    
                                  </td> */}
                                  {/* <td>{_.get(item, 'message', '')}</td> */}
                                  <td>
                                    <span
                                      className="smsList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getSmsDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 />
                                    </span>
                                    <span
                                      className="smsList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setSmsId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allSmsData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <SmsForm onClose={handleCloseFormSms} isEdit={isEdit} onSuccess={getSms} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="sms" onClose={handleCloseDeleteModel} onRemove={deleteSms} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default SmsList;
