import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { BsSearch, BsStar, BsStarHalf, BsStarFill } from 'react-icons/bs';
import { RiEqualizerFill } from 'react-icons/ri';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaChargingStation } from 'react-icons/fa';
import { MdClose, MdRefresh } from 'react-icons/md';
import ReactStars from 'react-rating-stars-component';
import { Offcanvas, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import * as _ from 'lodash';
import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { Form, Formik } from 'formik';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import Footer from 'components/general/Footer';

const ChargerStationList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [allChargingStationData, setAllChargingStationData] = useState([]);
  // const [clickedLatLng, setClickedLatLng] = useState(null);
  const [activeMarker, setActiveMarker] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [center, setCenter] = useState({
    lat: 24.4539,
    lng: 54.3773,
  });
  const [filterData, setFilterData] = useState({});
  // const prevScrollY = useRef(0);
  // const [goingUp, setGoingUp] = useState(false);

  const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);

  useEffect(() => {
    getChargingStations();
  }, []);

  useEffect(() => {
    setAllChargingStationData(allChargingStations);
  }, [allChargingStations]);

  const getChargingStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    setAllChargingStationData(
      _.filter(
        allChargingStations,
        (item) =>
          startsWith(item.name, searchText) ||
          startsWith(item.city, searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
      )
    );
  }, [searchText, allChargingStations]);

  const onCloseFilterPopup = () => setShowFilterPopup(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Add your API key
  });

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
  };

  useEffect(() => {
    loadHandler();
  }, [mapRef, center]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  // const onScroll = (e) => {
  //   const scrollHeight = e.target.scrollHeight;
  //   const clientHeight = e.target.clientHeight;
  //   const currentScrollY = e.target.scrollTop;
  //   if (prevScrollY.current < currentScrollY && goingUp) {
  //     setGoingUp(false);
  //   }
  //   if (prevScrollY.current > currentScrollY && !goingUp) {
  //     setGoingUp(true);
  //   }
  //   prevScrollY.current = currentScrollY;

  //   if (e && scrollHeight - clientHeight === currentScrollY && page < totalPage) {
  //     const data = {
  //       page: page + 1,
  //     };
  //     getChargingStations(data);
  //   }
  // };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader showButton title="Stations and Chargers" buttonText="Add charging station" onClickButton={() => history.push('/addStation')} />
        <div className="page-content-wrapper scrollable">
        {isLoader ? (
            <div className="station-list-page__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">Loading...</span>
              </div>
            </div>
          ) : (
          <div className="station-list-page__main">
            <div className="search--block">
              <SearchBox
                value={searchText}
                preIcon={<BsSearch />}
                postIcon={<RiEqualizerFill onClick={() => setShowFilterPopup(true)} />}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="map-station--outer-block">
              <div className="station-list__block-main">
                <div className="station__count-info">
                  <span>All stations</span>
                  <div className="total-station">
                    <span>{_.get(allChargingStations, 'length')}</span>
                  </div>
                </div>
                <div className="station-list__block-inner scrollable">
                  {searchText && _.isEmpty(allChargingStationData) ? (
                    <div className="empty-data-block">No charging stations</div>
                  ) : (
                    _.map(allChargingStationData, (station, index) => (
                      <StationInfoBlock
                        key={`charging-station-${index}`}
                        stationId={_.get(station, 'id', '')}
                        name={_.get(station, 'name', '')}
                        city={_.get(station, 'city', '')}
                        rating={_.get(station, 'avg_rating', 0)}
                        ratingByUsersCount={_.get(station, 'rating_count', 0)}
                        totalCharger={_.get(station, 'total_chargers', 0)}
                        is_active={_.get(station, 'is_active')}
                      />
                    ))
                  )}
                </div>
              </div>
              <div className="map__block-main">
                {isLoaded && (
                  <GoogleMap
                    onLoad={loadHandler}
                    center={center}
                    zoom={6}
                    // onClick={(e) => setClickedLatLng(e.latLng.toJSON())}
                    onCenterChanged={() => mapRef && setCenter(mapRef.center.toJSON())}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                  >
                    {_.map(allChargingStations, (item, index) => {
                      return (
                        <Marker key={index} position={_.get(item, 'location')} onClick={() => handleActiveMarker(index)}>
                          {activeMarker === index ? (
                            <InfoWindow onCloseClick={() => setActiveMarker(false)}>
                              <div className="marker-text" onClick={() => history.push(`/stations/${_.get(item, 'id')}`)}>
                                {/* {`${_.get(item, 'area')}, ${_.get(item, 'city')}, ${_.get(item, 'state')}, ${_.get(item, 'country')}`} */}
                                {_.get(item, 'name', '')}
                              </div>
                            </InfoWindow>
                          ) : null}
                        </Marker>
                      );
                    })}
                  </GoogleMap>
                )}
              </div>
            </div>
          </div>
          )}
          <Footer />
        </div>
      </LayoutContainer>
      {showFilterPopup && (
        <StationFilterBlock
          show={showFilterPopup}
          onClose={onCloseFilterPopup}
          filterData={filterData}
          handleFilterData={(data) => setFilterData(data)}
        />
      )}
    </React.Fragment>
  );
};
ChargerStationList.propTypes = {};
export default ChargerStationList;

export const StationInfoBlock = (props) => {
  const { stationId, name, city, rating, ratingByUsersCount, totalCharger, is_active } = props;
  const history = useHistory();

  return (
    <div className="station-list-item__main" onClick={() => history.push(`/stations/${stationId}`)}>
      <div className="station-item__inner">
        <div className="station--icon-block">
          <div className="station--icon">
            <FaChargingStation />
          </div>
        </div>
        <div className="station--info-block">
          <div className="station--info_inner">
            <span className="station--name">{name}</span>
            <div className="station--rating-block">
              <div className="rating-star__block">
                <ReactStars
                  edit={false}
                  a11y={true}
                  count={5}
                  value={_.round(rating, 1)}
                  size={12}
                  isHalf={true}
                  emptyIcon={<BsStar />}
                  halfIcon={<BsStarHalf />}
                  filledIcon={<BsStarFill />}
                  activeColor="#BE210B"
                  color="#BE210B"
                />
              </div>
              <span className="rating__count">
                {_.round(rating, 1)}/5 ({ratingByUsersCount})
              </span>
            </div>
            <div className="station--location-block">
              <div className="location--name text-ellipsis">
                <HiOutlineLocationMarker /> {city}
              </div>
              <span>{totalCharger} Chargers</span>
            </div>
          </div>
          {is_active === false && (
            <div className="station-active__main">
              <span className="station-active-text">In Draft</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
StationInfoBlock.propTypes = {
  stationId: PropTypes.string,
  name: PropTypes.string,
  city: PropTypes.string,
  rating: PropTypes.number,
  ratingByUsersCount: PropTypes.number,
  totalCharger: PropTypes.number,
  is_active: PropTypes.bool,
};

export const StationFilterBlock = (props) => {
  const { show, onClose, filterData, handleFilterData } = props;
  const dispatch = useDispatch();
  const [isAvailable, setIsAvailable] = useState(true);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const allChargeSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);

  const initialValues = !_.isEmpty(filterData)
    ? { ...filterData }
    : { country: '', state: '', city: '', oem: '', charger_type: '', is_active: true, charger_availibity: 'Available' };

  const getAllChargingStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
    dispatch({ type: FETCH_OEM_VENDOR });
    dispatch({ type: FETCH_CHARGE_SPEED });
  }, []);

  return (
    <Offcanvas show={show} placement="end" onHide={onClose} className="station-list-filter-canvas__container">
      <div className="filter-canvas__inner scrollable">
        <div className="canvas__header-block">
          <div className="header-name">Filters</div>
          <div className="header-close-icon">
            <MdClose size={30} color="#be210b" onClick={onClose} />
          </div>
        </div>
        <div className="canvas__body-block">
          <div className="canvas__body-block--inner">
            <Formik
              enableReinitialize={!_.isEmpty(filterData)}
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                !values.country && delete values['country'];
                !values.state && delete values['state'];
                !values.city && delete values['city'];
                !values.oem && delete values['oem'];
                !values.charger_type && delete values['charger_type'];
                getAllChargingStations(values);
                handleFilterData(values);
                setSubmitting(false);
              }}
            >
              {({ values, handleSubmit, setFieldValue, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <Select
                      label="Country"
                      options={_.map(allCountries, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder="Select Country"
                      name="country"
                      value={values.country}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllCountry}
                      page={countryPage}
                      totalPage={countryTotalPage}
                      onChange={(val) => {
                        getStateByCountry({ country_name: val });
                        setFieldValue(`country`, val);
                        setFieldValue(`state`, '');
                        setFieldValue(`city`, '');
                      }}
                    />
                    <Select
                      label="State"
                      options={_.map(allState, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder="Select State"
                      name="state"
                      value={values.state}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                      page={statePage}
                      totalPage={stateTotalPage}
                      onChange={(val) => {
                        getCityByState({ state_name: val });
                        const currentState = _.find(allState, { name: val });
                        setFieldValue(`state`, currentState.name);
                        setFieldValue(`city`, '');
                      }}
                    />
                    <Select
                      label="City"
                      options={
                        values.state &&
                        _.map(allCity, (item) => {
                          return { label: item.name, value: item.name };
                        })
                      }
                      placeholder="Select City"
                      name="city"
                      value={values.city}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                      page={cityPage}
                      totalPage={cityTotalPage}
                      onChange={(val) => {
                        const currentCity = _.find(allCity, { name: val });
                        setFieldValue('city', currentCity.name);
                      }}
                    />
                    <Select
                      label="OEM Name"
                      options={_.map(allOemVendor, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      placeholder="Select OEM Name"
                      name="oem"
                      value={_.get(values, 'oem', '')}
                      onChange={(val) => setFieldValue(`oem`, val)}
                    />
                    <div className="canvas-selection__block">
                      <Select
                        label="Type Of Charger"
                        options={_.map(allChargeSpeed, (item) => {
                          return { label: item.name, value: item.id };
                        })}
                        placeholder="Select Charge Speed"
                        name="charger_type"
                        value={values.charger_type}
                        onChange={(val) => setFieldValue('charger_type', val)}
                      />
                    </div>
                    <div className="canvas-selection__block">
                      <label htmlFor="available_charger" className="form-select-label">
                        Availability
                      </label>
                      <div id="available_charger" className="selection-block__inner">
                        <Row>
                          <Col>
                            <div className={`selection--btn ${values.is_active ? 'active' : ''}`} onClick={() => setFieldValue('is_active', true)}>
                              In use
                            </div>
                          </Col>
                          <Col>
                            <div className={`selection--btn ${values.is_active ? '' : 'active'}`} onClick={() => setFieldValue('is_active', false)}>
                              Not In use
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="canvas-selection__block">
                      <label htmlFor="charger_status" className="form-select-label">
                        Status
                      </label>
                      <div id="charger_status" className="selection-block__inner">
                        <Row>
                          <Col>
                            <div
                              className={`selection--btn ${isAvailable ? 'active' : ''}`}
                              onClick={() => {
                                setFieldValue('charger_availibity', 'Available ');
                                setIsAvailable(true);
                              }}
                            >
                              Active
                            </div>
                          </Col>
                          <Col>
                            <div
                              className={`selection--btn ${isAvailable ? '' : 'active'}`}
                              onClick={() => {
                                setFieldValue('charger_availibity', 'Unavailable');
                                setIsAvailable(false);
                              }}
                            >
                              Inactive
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="canvas-btn__block">
                    <Button type="submit" className="apply--btn w-100">
                      Apply Filters
                    </Button>
                    <div
                      className="reset--block"
                      onClick={() => {
                        handleFilterData({});
                        getAllChargingStations();
                        resetForm();
                        onClose();
                      }}
                    >
                      <MdRefresh size={24} className="reset--icon" />
                      Reset
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};
StationFilterBlock.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  filterData: PropTypes.object,
  handleFilterData: PropTypes.func,
};
