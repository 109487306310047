export const FETCH_COUNTRY = 'FETCH_COUNTRY';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAIL = 'FETCH_COUNTRY_FAIL';

export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL';

export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAIL = 'CREATE_COUNTRY_FAIL';

export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_COUNTRY_SUCCESS = ' UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAIL = 'UPDATE_COUNTRY_FAIL';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAIL = ' DELETE_COUNTRY_FAIL';

export const FETCH_STATE = 'FETCH_STATE';
export const FETCH_STATE_SUCCESS = 'FETCH_STATE_SUCCESS';
export const FETCH_STATE_FAIL = 'FETCH_STATE_FAIL';

export const GET_STATE = 'GET_STATE';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAIL = 'GET_STATE_FAIL';

export const CREATE_STATE = 'CREATE_STATE';
export const CREATE_STATE_SUCCESS = 'CREATE_STATE_SUCCESS';
export const CREATE_STATE_FAIL = 'CREATE_STATE_FAIL';

export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_STATE_SUCCESS = ' UPDATE_STATE_SUCCESS';
export const UPDATE_STATE_FAIL = 'UPDATE_STATE_FAIL';

export const DELETE_STATE = 'DELETE_STATE';
export const DELETE_STATE_SUCCESS = 'DELETE_STATE_SUCCESS';
export const DELETE_STATE_FAIL = ' DELETE_STATE_FAIL';

export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_FAIL = 'FETCH_CITY_FAIL';

export const GET_CITY = 'GET_CITY';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAIL = 'GET_CITY_FAIL';

export const CREATE_CITY = 'CREATE_CITY';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_FAIL = 'CREATE_CITY_FAIL';

export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_CITY_SUCCESS = ' UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_FAIL = 'UPDATE_CITY_FAIL';

export const DELETE_CITY = 'DELETE_CITY';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAIL = ' DELETE_CITY_FAIL';

export const FETCH_AREA = 'FETCH_AREA';
export const FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCESS';
export const FETCH_AREA_FAIL = 'FETCH_AREA_FAIL';

export const GET_AREA = 'GET_AREA';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';
export const GET_AREA_FAIL = 'GET_AREA_FAIL';

export const CREATE_AREA = 'CREATE_AREA';
export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
export const CREATE_AREA_FAIL = 'CREATE_AREA_FAIL';

export const UPDATE_AREA = 'UPDATE_AREA';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
export const UPDATE_AREA_FAIL = 'UPDATE_AREA_FAIL';

export const DELETE_AREA = 'DELETE_AREA';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const DELETE_AREA_FAIL = 'DELETE_AREA_FAIL';

export const GET_CITY_BY_STATE = 'GET_CITY_BY_STATE';
export const GET_CITY_BY_STATE_SUCCESS = 'GET_CITY_BY_STATE_SUCCESS';
export const GET_CITY_BY_STATE_FAIL = 'GET_CITY_BY_STATE_FAIL';
