import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { CHANGE_PASSWORD } from 'actions/authAction';
import { cookie } from 'utils/cookies/cookies';

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required('Old password required!').trim('Space is not allowed').strict(true),
  new_password: Yup.string().required('New password required!').trim('Space is not allowed').strict(true).min(8, 'Minimum eight numbers is required'),
  confirm_password: Yup.string()
    .required('Confirm password required!')
    .strict(true)
    .oneOf([Yup.ref('new_password'), null], 'Password does not match')
    .trim('Space is not allowed')
    .min(8, 'Minimum eight numbers is required'),
});

const ChangePassword = () => {
  const dispatch = useDispatch();

  const resetPassword = useCallback((data) => {
    dispatch({
      type: CHANGE_PASSWORD,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Password changed successfully');
            cookie.clean();
            setTimeout(() => {
              window.location.href = '/login';
            }, 500);
          }
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <div className="change-password-form_main">
        <div className="change-password-form_block">
          <Formik
            initialValues={{
              old_password: '',
              new_password: '',
              confirm_password: '',
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              resetPassword(values, resetForm);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="change-password-form__inner">
                  <Row>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired
                        label="Old Password"
                        type="password"
                        value={values.old_password}
                        name="old_password"
                        onChange={handleChange}
                        error={errors.old_password && touched.old_password ? errors.old_password : null}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired
                        label="New Password"
                        type="password"
                        name="new_password"
                        value={values.new_password}
                        onChange={handleChange}
                        error={errors.new_password && touched.new_password ? errors.new_password : null}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired
                        label="Confirm Password"
                        type="password"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : null}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="change-password-save--btn--block">
                  <Button type="submit" className="save--btn" disabled={isSubmitting}>
                    Save Changes
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ChangePassword;
