import { get, post, deleteRequest, patch} from 'utils/api';

export const getZones = (data) =>{
    return get(`/zone`, data);
} 

export const addZones = (data) =>{
    return post(`/zone`,data)
}

export const deleteZones = (id) =>{
   return deleteRequest(`/zone/${id}`)
}

export const updateZones = (data) => {
    const id = data.id;
    delete data['id'];
    delete data['tenant']
    return patch(`/zone/${id}`,data)
}

export const getZonesbyId = (id) => {
    return get(`/zone/${id}`)
}

export const zoneManagement = {
    getZones,
    addZones,
    deleteZones,
    updateZones,
    getZonesbyId
}