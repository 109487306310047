import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { CREATE_MULTIPLE_VEHICLE, CREATE_VEHICLE, GET_VEHICLE, UPDATE_VEHICLE } from 'actions/vehicle';
import { FETCH_USER } from 'actions/user';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { FETCH_VEHICLE_MAKE } from 'actions/vehicleMake';
import { FETCH_VEHICLE_MODEL } from 'actions/vehicleModel';
// import moment from 'moment';
// import DatePickerInput from 'components/inputs/DatePickerInput';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

const VehicleSchema = Yup.object().shape({
  // name: Yup.string().required('Vehicle name is required!'),
  // type: Yup.string().required('Vehicle type is required!'),
  make: Yup.string().required('Vehicle make is  required!'),
  model: Yup.string().required('Vehicle model is required!'),
  // rto_no: Yup.string()
  //   .required('Vehicle registration no is required!')
  //   .strict(true)
  //   .trim('Space is not allowed')
  //   .matches(/^[A-Za-z0-9\s]+$/, 'Only alphabets and numbers are allowed')
  //   .max(100, 'Text limit should be less than 100 characters.'),
  // RFID: Yup.string()
  //   .required('RFID/IDTag is required!')
  //   .strict(true)
  //   .trim('Space is not allowed')
  //   .matches(/^[A-Za-z0-9\s]+$/, 'Only alphabets and numbers are allowed')
  //   .max(100, 'Text limit should be less than 100 characters.'),
});

const VehicleForm = () => {
  const dispatch = useDispatch();
  const { vehicleId } = useParams();

  const vehicleDetail = useSelector((state) => state.vehicle.vehicleDetail);
  const allVehicleMake = useSelector((state) => state.vehicleMake.vehicleMakes);
  const allVehicleModel = useSelector((state) => state.vehicleModel.vehicleModels);
  // const allUserData = useSelector((state) => state.user.users);
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const tenantId = _.get(profileDetail, 'tenant', '');
  const makePage = useSelector((state) => state.vehicleMake.page);
  const modelPage = useSelector((state) => state.vehicleModel.page);
  const makeTotalPage = useSelector((state) => state.vehicleMake.totalPages);
  const modelTotalPage = useSelector((state) => state.vehicleModel.totalPages);

  const initialValues =
    vehicleId && !_.isEmpty(vehicleDetail)
      ? {
          ...vehicleDetail,
          // owner: _.get(vehicleDetail, 'owner.id', ''),
          tenant: _.get(vehicleDetail, 'tenant.id', ''),
          ownership_type: _.get(vehicleDetail, 'ownership_type', 'b2b'),
          vin_num: _.get(vehicleDetail, 'vin_num', '').toString(),
          // insurance_expiry: moment(_.get(vehicleDetail, 'insurance_expiry')).utcOffset(0, true).format('YYYY-MM-DD'),
        }
      : {
          vin_num: '',
          // name: '',
          // type: '',
          make: '',
          model: '',
          mac: '',
          // rto_no: '',
          // RFID: '',
          max_charging_capacity: '',
          // insurance_expiry: moment(),
          // owner: '',
          documents: [],
          ownership_type: 'b2b',
          // picture: 'http://jbsdjcj.png',
        };

  const getVehicleDetails = useCallback((id) => {
    dispatch({ type: GET_VEHICLE, payload: id });
  }, []);

  const getAllUsers = useCallback((data) => {
    dispatch({ type: FETCH_USER, payload: data ? data : {} });
  }, []);

  useEffect(() => {
    if (vehicleId) {
      getVehicleDetails(vehicleId);
    }
  }, [vehicleId]);

  const addVehicle = useCallback((data) => {
    dispatch({
      type: CREATE_VEHICLE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            window.location.href = '/vehicles';
          }, 1000);
        }
      },
    });
  }, []);

  const addMultipleVehicle = useCallback((data) => {
    dispatch({
      type: CREATE_MULTIPLE_VEHICLE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          toast.success('Vehicle added successfully');
          setTimeout(() => {
            window.location.href = '/vehicles';
          }, 1000);
        }
      },
    });
  }, []);

  const editVehicleDetail = useCallback((data) => {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/vehicles';
        }
      },
    });
  }, []);

  const getAllVehicleMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MAKE, payload: data });
  }, []);

  const getVehicleModelByMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MODEL, payload: data });
  }, []);

  useEffect(() => {
    getAllVehicleMake();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (vehicleId && !_.isEmpty(vehicleDetail) && !_.isEmpty(allVehicleMake)) {
      const selectedMake = _.find(allVehicleMake, { name: _.get(vehicleDetail, 'make', '') });
      if (selectedMake && !_.isEmpty(selectedMake) && _.has(selectedMake, 'id')) {
        getVehicleModelByMake({ makeId: selectedMake.id });
      }
    }
  }, [vehicleId, vehicleDetail, allVehicleMake]);

  const Upload = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('fileUpload');
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    _.map(excelRows, (data) => {
      data.documents = JSON.parse(data.documents);
      data.max_charging_capacity = data.max_charging_capacity.toString();
    });
    addMultipleVehicle(excelRows);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${vehicleId ? 'Edit' : 'Add'} Vehicle`} backTo="/vehicles" />
        <div className="page-content-wrapper scrollable">
          <div className="add-vehicle-page-main">
            <Card className="vehicle-form-card">
              <div className="vehicle-form__block">
                <Formik
                  enableReinitialize={!!vehicleId}
                  initialValues={initialValues}
                  validationSchema={VehicleSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (vehicleId) {
                      // edit tenant api call....
                      const charging_capacity = _.map(allVehicleModel, (item) => item.max_charging_capacity);
                      editVehicleDetail({
                        ...values,
                        // delete below tenant, when vehicle-data have the tenant-data
                        tenant: values.tenant ? values.tenant : tenantId,
                        max_charging_capacity: charging_capacity.toString(),
                      });
                    } else {
                      //add tenant api call...
                      const charging_capacity = _.map(allVehicleModel, (item) => item.max_charging_capacity);
                      addVehicle({
                        ...values,
                        max_charging_capacity: charging_capacity.toString(),
                      });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="vehicle-form__inner">
                        <Row>
                          {/* <Col lg={6} md={12}>
                            <TextInput
                              label="Vehicle Name"
                              type="name"
                              value={values.name}
                              name="name"
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col> */}
                          {/* <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label="Vehicle Type"
                              placeholder="Select vehicle type"
                              options={[
                                { label: 'Bus', value: 'bus' },
                                { label: 'Car', value: 'car' },
                                { label: 'Two wheeler', value: 'two wheeler' },
                                { label: 'Three wheeler', value: 'three wheeler' },
                                { label: 'Other', value: 'other' },
                              ]}
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue('type', val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col> */}
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              className="mb-4"
                              label="Vehicle Brand"
                              options={_.map(allVehicleMake, (item) => {
                                return { label: item?.name, value: item?.name };
                              })}
                              placeholder="Select Brand"
                              value={values.make}
                              name="make"
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllVehicleMake}
                              page={makePage}
                              totalPage={makeTotalPage}
                              onChange={(val) => {
                                setFieldValue(`make`, val);
                                const currentMake = _.find(allVehicleMake, { name: val });
                                getVehicleModelByMake({ makeId: currentMake.id });
                              }}
                              error={errors.make && touched.make ? errors.make : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              className="mb-4"
                              placeholder="Select model"
                              options={_.map(allVehicleModel, (item) => {
                                return { label: item?.model, value: _.find(allVehicleModel, { id: item?.id })?.model };
                              })}
                              label="Vehicle Model"
                              name="model"
                              value={values.model}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getVehicleModelByMake({ ...data, makeId: values.make })}
                              page={modelPage}
                              totalPage={modelTotalPage}
                              onChange={(val) => setFieldValue(`model`, val)}
                              error={errors.model && touched.model ? errors.model : null}
                            />
                          </Col>
                          {/* <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder="Vehicle Registration No"
                              label="Vehicle Registration No"
                              name="rto_no"
                              value={values.rto_no}
                              onChange={handleChange}
                              error={errors.rto_no && touched.rto_no ? errors.rto_no : null}
                            />
                          </Col> */}
                          {/* <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              placeholder="RFID/ID Tag"
                              label="RFID/ID Tag"
                              name="RFID"
                              value={values.RFID}
                              onChange={handleChange}
                              error={errors.RFID && touched.RFID ? errors.RFID : null}
                            />
                          </Col> */}
                          {/*<Col lg={6} md={12}>*/}
                          {/*  <Select*/}
                          {/*    isRequired*/}
                          {/*    label="Vehicle Owner"*/}
                          {/*    placeholder="Select owner"*/}
                          {/*    options={_.map(allUserData, (item) => {*/}
                          {/*      return { label: _.startCase(item.name), value: item.id };*/}
                          {/*    })}*/}
                          {/*    name="owner"*/}
                          {/*    value={values.owner}*/}
                          {/*    onChange={(val) => setFieldValue('owner', val)}*/}
                          {/*    error={errors.owner && touched.owner ? errors.owner : null}*/}
                          {/*  />*/}
                          {/*</Col>*/}
                          {/*<Col lg={6} md={12}>*/}
                          {/*  <Select*/}
                          {/*    isRequired*/}
                          {/*    label="Ownership Type"*/}
                          {/*    placeholder="Select ownership type"*/}
                          {/*    options={[*/}
                          {/*      { label: 'B2B', value: 'b2b' },*/}
                          {/*      { label: 'B2C', value: 'b2c' },*/}
                          {/*      { label: 'Hybrid', value: 'hybrid' },*/}
                          {/*    ]}*/}
                          {/*    name="ownership_type"*/}
                          {/*    value={values.ownership_type}*/}
                          {/*    onChange={(val) => setFieldValue('ownership_type', val)}*/}
                          {/*    error={errors.ownership_type && touched.ownership_type ? errors.ownership_type : null}*/}
                          {/*  />*/}
                          {/*</Col>*/}
                          {/*<Col lg={6} md={12}>*/}
                          {/*  <DatePickerInput*/}
                          {/*    isRequired*/}
                          {/*    label="Expiry Insurance"*/}
                          {/*    value={values.insurance_expiry}*/}
                          {/*    onChange={(dateString) => setFieldValue('insurance_expiry', dateString)}*/}
                          {/*    error={errors.insurance_expiry && touched.insurance_expiry ? errors.insurance_expiry : null}*/}
                          {/*  />*/}
                          {/*</Col>*/}
                          <Col lg={6} md={12}>
                            <TextInput label="VIN Number" placeholder="VIN Number" name="vin_num" value={values.vin_num} onChange={handleChange} />
                          </Col>
                          
                          <Col lg={6} md={12}>
                            <TextInput label="MAC" placeholder="MAC" name="mac" value={values.mac} onChange={handleChange} />
                          </Col>
                          {!vehicleId && (
                            <Col lg={6} md={12}>
                              <TextInput label="Import Vehicle File " type="file" onChange={Upload} name="fileUpload" id="fileUpload" />
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div className="vehicle-save--btn--block">
                        <Button type="submit" className="vehicle-save-btn save--btn" disabled={isSubmitting}>
                          {vehicleId ? 'Edit' : 'Save and Add'} Vehicle
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default VehicleForm;
