import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import ConnectorTypeForm from './ConnectorTypeForm';
import { DELETE_CONNECTOR_TYPE, FETCH_CONNECTOR_TYPE, GET_CONNECTOR_TYPE } from 'actions/connectorType';
// import Avatar from 'components/inputs/Avatar';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';

const ConnectorTypeList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [allConnectorTypeData, setAllConnectorTypeData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [connectorTypeId, setConnectorTypeId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseConnectorTypeData, setPageWiseConnectorTypeData] = useState([]);

  const page = useSelector((state) => state.connectorType.page);
  const totalData = useSelector((state) => state.connectorType.total);
  const limit = useSelector((state) => state.connectorType.limit);
  const totalPages = useSelector((state) => state.connectorType.totalPages);
  const connectorTypeList = useSelector((state) => state.connectorType.connectorTypes);
  const isLoader = useSelector((state) => state.connectorType.isLoading);

  const connectorTypeData = connectorTypeList.filter(({ id: id1 }) => pageWiseConnectorTypeData.some(({ id: id2 }) => id2 === id1));

  const getConnectorType = useCallback(
    (data = {}) => {
      dispatch({
        type: FETCH_CONNECTOR_TYPE,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseConnectorTypeData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseConnectorTypeData]
  );

  const getConnectorTypeDetail = useCallback((id) => {
    dispatch({ type: GET_CONNECTOR_TYPE, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setConnectorTypeId('');
  };

  const deleteConnectorType = useCallback(
    () =>
      dispatch({
        type: DELETE_CONNECTOR_TYPE,
        payload: connectorTypeId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getConnectorType({ page });
          }
        },
      }),
    [connectorTypeId]
  );

  useEffect(() => {
    getConnectorType();
  }, []);

  useEffect(() => {
    setAllConnectorTypeData(
      _.filter(
        connectorTypeData,
        (item) =>
          startsWith(_.get(item, 'name', ''), searchText) ||
          startsWith(_.get(item, 'connector_standard', ''), searchText) ||
          startsWith(_.get(item, 'connector_mode', ''), searchText) ||
          startsWith(_.get(item, 'description', ''), searchText)
      )
    );
  }, [searchText, connectorTypeList, pageWiseConnectorTypeData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getConnectorType(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getConnectorType(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getConnectorType(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormConnectorType = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Connector Type" />
        <div className="page-content-wrapper scrollable">
          <div className="connectorTypeList-page">
            <Card>
              <div className="connectorTypeList-page-wrapper">
                <Row className="connectorTypeList-search-box">
                  <Col xl={10} lg={9} md={8} className="connectorTypeList-search-inputBox">
                    <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="connectorTypeList-search-boxButton">
                    <Button
                      className="connectorTypeList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      Add Connector Type
                    </Button>
                  </Col>
                </Row>
                <div className="connectorTypeList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Connector Type</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Icon</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Connector Mode</span>
                              <span className="ico" onClick={() => handleSorting('connector_mode', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Standard</span>
                              <span className="ico" onClick={() => handleSorting('connector_standard', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Description</span>
                              <span className="ico" onClick={() => handleSorting('description', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(allConnectorTypeData) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">No Connector Type Data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(allConnectorTypeData, (item, key) => {
                            return (
                              <>
                                <tr key={`connectorType-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  {/* <td>
                                    <Avatar imgSrc={_.get(item, 'icon', '')} className="connectorTypeList-logo" />
                                  </td> */}
                                  <td>{_.get(item, 'connector_mode', '')}</td>
                                  <td>{_.get(item, 'connector_standard', '')}</td>
                                  <td>{_.get(item, 'description', '')}</td>
                                  <td>
                                    <span
                                      className="connectorTypeList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getConnectorTypeDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 />
                                    </span>
                                    <span
                                      className="connectorTypeList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setConnectorTypeId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allConnectorTypeData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <ConnectorTypeForm onClose={handleCloseFormConnectorType} isEdit={isEdit} onSuccess={getConnectorType} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="connectorType" onClose={handleCloseDeleteModel} onRemove={deleteConnectorType} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default ConnectorTypeList;
