import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Col, Row, Modal } from 'react-bootstrap';
import SearchBox from 'components/general/SearchBox';
import { BsThreeDots, BsSearch } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import { startsWith } from 'components/common/utils';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_RFID_GROUP, FETCH_RFID_GROUP, GET_RFID_GROUP } from 'actions/rfidGroup';
import DropDown from 'components/inputs/DropDown';
import RfidGroupForm from './RfidGroupForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';

const RfidGroupList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allRfidGroupData, setAllRfidGroupData] = useState([]);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rfidGroupId, setRfidGroupId] = useState('');

  const allRfidGroup = useSelector((state) => state.rfidGroup.rfidGroup);
  const page = useSelector((state) => state.rfidGroup.page);
  const totalData = useSelector((state) => state.rfidGroup.total);
  const limit = useSelector((state) => state.rfidGroup.limit);
  const totalPages = useSelector((state) => state.rfidGroup.totalPages);

  const getRfidGroup = useCallback((data) => {
    dispatch({ type: FETCH_RFID_GROUP, payload: data });
  }, []);

  const deleteRfidGroup = useCallback(
    () =>
      dispatch({
        type: DELETE_RFID_GROUP,
        payload: rfidGroupId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
          }
        },
      }),
    [rfidGroupId]
  );

  const getRfidDetail = useCallback((id) => {
    dispatch({ type: GET_RFID_GROUP, payload: id });
  }, []);

  useEffect(() => {
    getRfidGroup();
  }, []);

  useEffect(() => {
    setAllRfidGroupData(
      _.filter(
        allRfidGroup,
        (item) =>
          startsWith(_.get(item, 'name'), searchText) ||
          startsWith(_.get(item, 'number_of_cards', '').toString(), searchText) ||
          startsWith(_.get(item, 'cashback_percent', '').toString(), searchText)
      )
    );
  }, [searchText, allRfidGroup]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getRfidGroup(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRfidGroup(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRfidGroup(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormRfidGroup = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setRfidGroupId('');
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="RFID Master" />
        <div className="page-content-wrapper scrollable">
          <div className="groupList-page">
            <Card>
              <div className={'mb-3 mt-3'}>
                <span className="groupList-header-text">RFID Groups</span>
              </div>
              <div className="groupList-page-wrapper">
                <Row className="groupList-search-box">
                  <Col xl={10} lg={8} md={7} className="groupList-search-inputBox">
                    <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                  <Col xl={2} lg={4} md={5} className="groupList-search-boxButton">
                    <Button
                      className="groupList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      Add New Group
                    </Button>
                  </Col>
                </Row>
                <div className="groupList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr. No</span>
                              {/*<span className="ico" onClick={() => handleSorting('name', sortByItem)}>*/}
                              {/*    <BiSort size={15} />*/}
                              {/*  </span>*/}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Group Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>No. of RFID cards</span>
                              <span className="ico" onClick={() => handleSorting('number_of_cards', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Cashback Offer (%)</span>
                              <span className="ico" onClick={() => handleSorting('cashback_percent', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allRfidGroupData) ? (
                          <tr>
                            <td colSpan={6} className="border-0">
                              <div className="empty-data-block">No RFID Group Data</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allRfidGroupData, (item, key) => {
                            return (
                              <tr key={`rfid-group-${key}`}>
                                <td>{key + 1}.</td>
                                <td>{_.get(item, 'name', '')}</td>
                                <td>{_.get(item, 'number_of_cards', '')}</td>
                                <td>{_.get(item, 'cashback_percent', '')} % </td>
                                <td>
                                  <div key={`action-${key}`} className="rfid-group">
                                    <span className="list-icon">
                                      <DropDown
                                        popupClass="bottom-left"
                                        trigger={
                                          <div className={'more-info'}>
                                            <BsThreeDots />
                                          </div>
                                        }
                                      >
                                        <ul>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              setShowForm(true);
                                              getRfidDetail(_.get(item, 'id', ''));
                                              setIsEdit(true);
                                            }}
                                          >
                                            Edit Group
                                          </li>
                                          <li className="item" onClick={() => history.push(`/rfidList/${_.get(item, 'id')}`)}>
                                            Manage Group
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              setShowDeleteModal(true);
                                              setRfidGroupId(_.get(item, 'id', ''));
                                            }}
                                          >
                                            Delete Group
                                          </li>
                                        </ul>
                                      </DropDown>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {!_.isEmpty(allRfidGroupData) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <RfidGroupForm onClose={handleCloseFormRfidGroup} isEdit={isEdit} onSuccess={getRfidGroup} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="Group" onClose={handleCloseDeleteModel} onRemove={deleteRfidGroup} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default RfidGroupList;
