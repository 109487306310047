import { get, post, deleteRequest, patch } from 'utils/api';

export const getUsers = (data) => {
  return get(`/users`, data);
};

export const getUserById = (id) => {
  return get(`/users/${id}`);
};

export const getRoles = (data) => {
  return get(`/roles`, data);
};

export const addUser = (data) => {
  return post(`/users`, data);
};

export const walletTransaction = (data) => {
  return post(`/wallets`, data);
};

export const deleteUser = (id) => {
  return deleteRequest(`/users/${id}`);
};

export const updateUser = (data) => {
  const userId = data.id;
  delete data['id'];
  return patch(`/users/${userId}`, data);
};

export const getCustomers = (data) => {
  return get(`/users/customers`, data);
};

export const user = {
  getUsers,
  getUserById,
  getRoles,
  addUser,
  deleteUser,
  updateUser,
  getCustomers,
  walletTransaction,
};
