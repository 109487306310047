import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_WITH_OTP,
  LOGIN_WITH_OTP_SUCCESS,
  LOGIN_WITH_OTP_FAIL,
  TENANT_ADMIN_LOGIN,
  TENANT_ADMIN_LOGIN_SUCCESS,
  TENANT_ADMIN_LOGIN_FAIL,
  LOGINAS,
  LOGINAS_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from 'actions/authAction';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const initialState = {
  loginData: {},
  logOutData: {},
  tenantAdminLoginData: {},
  loginasData: {},
  error: '',
  isLoading: false,
};

// const location = JSON.parse(localStorage.getItem('pathname')) ? JSON.parse(localStorage.getItem('pathname')) : '/dashboard';

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
    case LOGOUT:
    case CHANGE_PASSWORD:
    case LOGIN_WITH_OTP:
    case TENANT_ADMIN_LOGIN:
    case LOGINAS: {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }

    case LOGIN_SUCCESS: {
      toast.success('Login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);

      //   if (payload.user.role === ('super_admin' || 'cpo' || 'finance' || 'doco' || 'admin')) {
      //     localStorage.removeItem('pathname');
      //     setTimeout(() => {
      //       window.location.href = '/dashboard';
      //     }, 1000);
      //   } else {
      //     localStorage.removeItem('pathname');
      //     setTimeout(() => {
      //       window.location.href = '/dashboard';
      //     }, 1000);
      // }
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 1000);

      return {
        ...state,
        loginData: payload.user,
        isLoading: false,
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...state,
        logOutData: _.concat(state.logOutData, payload),
        isLoading: false,
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loginData: payload,
        isLoading: false,
      };
    }
    case LOGIN_WITH_OTP_SUCCESS: {
      toast.success('Login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 1000);
      return {
        ...state,
        loginData: payload.user,
        isLoading: false,
      };
    }

    case TENANT_ADMIN_LOGIN_SUCCESS: {
      toast.success('Tenant login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 1000);
      return {
        ...state,
        tenantAdminLoginData: payload.user,
        isLoading: false,
      };
    }

    case LOGIN_FAIL:
    case LOGOUT_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case LOGIN_WITH_OTP_FAIL:
    case TENANT_ADMIN_LOGIN_FAIL:
    case LOGINAS_FAIL: {
      return {
        ...state,
        error: payload.message,
        isLoading: false,
      };
    }

    default:
      return { ...state };
  }
};
export default authReducer;
