import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';

const LogoutModal = (props) => {
  const { onClose = _.noop(), onRemove = _.noop() } = props;

  return (
    <React.Fragment>
      <div className="logout-modal-main_page">
        <div className="logout-modal--inner">
          <div className="logout-modal---block_title">
            <div className="inner--block_title">
              <div className="title">Logout</div>
              <p className="sub-title">Are you sure you want to logout ?</p>
            </div>
          </div>
          <div className="logout-modal-input-box__block">
            <Row>
              <Col lg={6}>
                <Button className="logout-model--btn cancel--btn" onClick={onClose}>
                  Cancel
                </Button>
              </Col>
              <Col lg={6}>
                <Button className="logout-model--btn  remove--btn" onClick={onRemove}>
                  Logout
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
LogoutModal.propTypes = {
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
export default LogoutModal;
