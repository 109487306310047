import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { CREATE_OFFERS, GET_OFFERS, UPDATE_OFFERS } from 'actions/offers';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import CustomDatePickerInput from 'components/inputs/DatePickerInput';
import moment from 'moment';

const OffersSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  amount: Yup.number().required('Amount is required'),
  type: Yup.string().required('Type is required'),
  expiry: Yup.string().required('Expiry is required'),
  max_uses_per_user: Yup.number().required('Max uses per user is required'),
});

const OfferForm = () => {
  const dispatch = useDispatch();
  const { offerId } = useParams();

  const offerDetail = useSelector((state) => state.offers.offerDetail);

  const initialValues =
    offerId && !_.isEmpty(offerDetail)
      ? {
          ...offerDetail,
          expiry: moment(_.get(offerDetail, 'expiry', '')).format('YYYY-MM-DD'),
        }
      : {
          code: '',
          amount: 0,
          type: '',
          max_uses_per_user: 0,
          expiry: '',
          times_used: {
            user: '',
            date: '',
          },
        };

  const getOfferDetails = useCallback((id) => {
    dispatch({ type: GET_OFFERS, payload: id });
  }, []);

  const addOffers = useCallback((data) => {
    dispatch({
      type: CREATE_OFFERS,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          window.location.href = '/offers';
        }
      },
    });
  }, []);

  const editOffersDetail = useCallback((data) => {
    dispatch({
      type: UPDATE_OFFERS,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/offers';
        }
      },
    });
  }, []);

  useEffect(() => {
    if (offerId) {
      getOfferDetails(offerId);
    }
  }, [offerId]);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${offerId ? 'Edit' : 'Add'} Offer`} backTo="/offers" />
        <div className="page-content-wrapper scrollable">
          <div className="add-offer-page-main">
            <Card className="offer-form-card">
              <div className="offer-form__block">
                <Formik
                  enableReinitialize={!!offerId}
                  initialValues={initialValues}
                  validationSchema={OffersSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (offerId) {
                      const editOfferData = _.omit(values, ['times_used']);
                      editOffersDetail(editOfferData);
                    } else {
                      const addOfferData = _.omit(values, ['times_used']);
                      addOffers(addOfferData);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="offer-form__inner">
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label="Code"
                              placeholder="Code"
                              value={values.code}
                              name="code"
                              onChange={handleChange}
                              error={errors.code && touched.code ? errors.code : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label="Amount"
                              placeholder="Amount"
                              type="number"
                              value={values.amount}
                              name="amount"
                              onChange={handleChange}
                              error={errors.amount && touched.amount ? errors.amount : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label="Offers Type"
                              placeholder="Select Offer Type"
                              options={[
                                { label: 'Flat', value: 'flat' },
                                { label: 'percentage', value: 'percentage' },
                              ]}
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue('type', val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col>

                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label="Max Uses Per User"
                              placeholder="Max uses per user"
                              name="max_uses_per_user"
                              value={values.max_uses_per_user}
                              type="number"
                              onChange={handleChange}
                              error={errors.max_uses_per_user && touched.max_uses_per_user ? errors.max_uses_per_user : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <CustomDatePickerInput
                              isRequired
                              placeholder="Expiry"
                              label="Expiry"
                              name="expiry"
                              value={values.expiry}
                              onChange={(dateString) => setFieldValue('expiry', dateString)}
                              error={errors.expiry && touched.expiry ? errors.expiry : null}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="offer-save--btn--block">
                        <Button type="submit" className="offer-save-btn save--btn" disabled={isSubmitting}>
                          {offerId ? 'Edit' : 'Save and Add'} Offer
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default OfferForm;
