import { ACCESS_REPORT, ACCESS_REPORT_SUCCESS, ACCESS_REPORT_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  accessReport: [],
};

const accessReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACCESS_REPORT: {
      return { ...state, isLoading: true };
    }
    case ACCESS_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        accessReport: payload,
      };
    }

    case ACCESS_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default accessReportReducer;
