import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_PRIVACY, UPDATE_PRIVACY } from 'actions/privacy';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';

const PrivacySchema = Yup.object().shape({
  message: Yup.string().required('Message is required').strict(true).trim('Space is not allowed'),
  title: Yup.string()
    .required('Title is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters.'),
  order: Yup.number().required('Order is required'),
});

const PrivacyForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();

  const privacyDetail = useSelector((state) => state.privacy.privacyDetail);
  const isLoading = useSelector((state) => state.privacy.isLoading);

  const addPrivacy = useCallback((data) => {
    dispatch({
      type: CREATE_PRIVACY,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updatePrivacy = useCallback((data) => {
    dispatch({
      type: UPDATE_PRIVACY,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const initialValues = isEdit
    ? { ...privacyDetail }
    : {
        order: '',
        title: '',
        message: '',
      };
  return (
    <React.Fragment>
      <div className="privacy-form-page__main">
        <Card>
          <div className="privacy-form-header__block">
            <div className="privacy-header-name">
              <span>{isEdit ? 'Edit' : 'Add'} Privacy </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="privacy-form-body__block">
            <div className="privacy-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={PrivacySchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updatePrivacy(values);
                  } else {
                    addPrivacy(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6} md={6}>
                        <TextInput
                          label="Order"
                          type="number"
                          name="order"
                          value={_.get(values, 'order', '')}
                          onChange={handleChange}
                          error={errors.order && touched.order ? errors.order : null}
                        />
                      </Col>
                      <Col lg={6} md={6}>
                        <TextInput
                          label="Title"
                          placeholder="Title"
                          name="title"
                          value={_.get(values, 'title', '')}
                          onChange={handleChange}
                          error={errors.title && touched.title ? errors.title : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <TextInput
                          as="textarea"
                          rows="4"
                          label="Message"
                          placeholder="Message"
                          name="message"
                          value={_.get(values, 'message', '')}
                          onChange={handleChange}
                          error={errors.message && touched.message ? errors.message : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
PrivacyForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default PrivacyForm;
