import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { UPDATE_USER } from 'actions/user';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { useParams } from 'react-router';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { GET_CUSTOMER } from 'actions/customer';
import { useLocation } from 'react-router-dom';

const StationWiseAccess = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const location = useLocation();
  const pathToggel = location.state;
  const isLoading = useSelector((state) => state.user.isLoading);
  const userDetail = useSelector((state) => state.customer.customerDetail);

  const charginStations = useSelector((state) => state.chargingStation.chargingStations);
  const allStation = _.map(charginStations, (item) => item.id);
  const userProfileStations = userDetail.charging_stations;
  const stationList = !_.isEmpty(userProfileStations) ? charginStations.filter((x) => userProfileStations.indexOf(x.id) !== -1) : [];
  const allStations = _.isEqual(userProfileStations, allStation);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const getCustomerDetail = useCallback((id) => {
    dispatch({ type: GET_CUSTOMER, payload: id });
  });

  useEffect(() => {
    getAllStation();
    getCustomerDetail(userId);
  }, []);

  const updateUser = useCallback(
    (data) => {
      const userData = {
        charging_stations: data.charging_stations ? data.charging_stations : [],
        id: userId,
      };
      dispatch({
        type: UPDATE_USER,
        payload: userData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('User Updated successfully');
            setTimeout(() => {
              window.location.href = `${pathToggel?.name ? '/businessPartner' : "/setting?tab=role"}`;
            }, 1000);
          }
        },
      });
    },
    [userId]
  );

  const initialValues = !_.isEmpty(stationList)
    ? {
        charging_stations: allStations ? 'All' : _.map(stationList, (item) => item.id),
      }
    : { charging_stations: '' };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={` Station Access `} backTo={pathToggel?.name ? '/businessPartner' : "/setting?tab=role"} />
        <div className="page-content-wrapper scrollable">
          <div className="station-wise-filter_page__main">
            <Card className="station-wise-filter_page__main--card">
              <Formik
                enableReinitialize={userProfileStations !== undefined}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  !values.charging_stations && delete values['charging_stations'];
                  if (values.charging_stations === 'All') {
                    const filterData = { ...values, charging_stations: allStation };
                    updateUser(filterData);
                  } else {
                    updateUser(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <Row>
                        <Col lg={6} md={121}>
                          <Select
                            label="Station"
                            options={[
                              { label: 'All', value: 'All' },
                              ..._.map(charginStations, (state) => {
                                return { label: state.name, value: state.id };
                              }),
                            ]}
                            placeholder="Select Station"
                            name="charging_stations"
                            isMulti
                            value={values.charging_stations}
                            onMenuScrollDown={true}
                            onChange={(val) => {
                              if (_.includes(val, 'All')) {
                                setFieldValue(`charging_stations`, 'All');
                              } else if (_.isEmpty(val)) {
                                setFieldValue(`charging_stations`, '');
                              } else {
                                setFieldValue(`charging_stations`, val);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="filter-btn__block">
                      <span>
                        <Button type="submit" className="filter_btn" disabled={isSubmitting || isLoading} >
                          Save
                        </Button>
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default StationWiseAccess;
