import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header/index';
import SessionHeading from 'components/general/SessionHeading';
import Card from 'components/inputs/Card';
// import DatePicker from 'components/inputs/DatePicker';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import CheckBox from 'components/inputs/CheckBox';
import { BiPlug } from 'react-icons/bi';
import ActivityItem from 'components/general/ActivityItem';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_CHARGER_LOG, FETCH_CHARGER_LOG } from 'actions/activityLog';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Modal, Spinner } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { JsonToTable } from 'react-json-to-table';
import DeleteModal from 'components/general/DeleteModal';
import DateTimePicker from 'components/inputs/DateTimePicker';

const ChargerActivityDetail = () => {
  const dispatch = useDispatch();

  const allActivityLog = useSelector((state) => state.activityLog.activityLogs);
  const isLoader = useSelector((state) => state.userActivity.isLoading);
  const page = useSelector((state) => state.activityLog.page);
  const limit = useSelector((state) => state.activityLog.limit);
  const totalData = useSelector((state) => state.activityLog.total);
  const totalPages = useSelector((state) => state.activityLog.totalPages);

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [activityID, setActivityId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setActivityId('');
  };

  const getActivity = useCallback((data) => {
    dispatch({ type: FETCH_CHARGER_LOG, payload: data ? data : {} });
  });
  const deleteActivity = useCallback(
    () =>
      dispatch({
        type: DELETE_CHARGER_LOG,
        payload: activityID,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_CHARGER_LOG) {
            handleCloseDeleteModel();
            getActivity({ page });
          }
        },
      }),
    [activityID]
  );

  // const deleteActivityLog = useCallback((id) => {

  //   // dispatch({ type: DELETE_CHARGER_LOG, payload: id });
  // }, []);

  useEffect(() => {
    // getActivity({ limit: 20 });
    const filterDateData = {
      date_from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      date_to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
    };
    endDate && getActivity({ ...filterDateData, limit: 20 });
  }, [startDate, endDate]);

  const handlePageClick = (page) => {
    if (startDate && endDate) {
      const data = {
        date_from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        page: page.selected + 1,
        limit: limit || 20,
      };
      getActivity(data);
    } else {
      const data = {
        page: page.selected + 1,
        limit: limit || 20,
      };
      getActivity(data);
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Activity Log" />
        <div className="page-content-wrapper scrollable">
          <div className="charger-activity-log-page__main">
            <Card>
              <SessionHeading text="All Activity" className="activity-header--block">
                <div className="session-heading-right-content">
                  <DropDown
                    popupClass="main-menu bottom"
                    trigger={
                      <>
                        <div className="filter-block">
                          <div className="filter-icon">
                            <BsFilter />
                            <span className="filter-label">Filters</span>
                          </div>
                          <div className="arrow__icon">
                            <AiFillCaretDown />
                          </div>
                        </div>
                      </>
                    }
                    triggerClass="filter-dropdown"
                  >
                    <ul>
                      <li className="item">
                        Charger Authorised
                        <CheckBox />
                      </li>
                      <li className="item">
                        Charging Started
                        <CheckBox />
                      </li>
                      <li className="item">
                        Charging Stopped
                        <CheckBox />
                      </li>
                      <li className="item">
                        Incident Occured
                        <CheckBox />
                      </li>
                      <div className="underline" />
                      <div className="apply-content">
                        <a href="#" className="apply-label">
                          Apply
                        </a>
                      </div>
                    </ul>
                  </DropDown>
                  <DateTimePicker
                    onChangeOfStartDate={(item) => setStartDate(item)}
                    onChangeOfEndDate={(item) => setEndDate(item)}
                    initialValueOfStartDate={moment().startOf('month')}
                    initialValueOfEndDate={moment(new Date()).endOf('day')}
                  />
                </div>
              </SessionHeading>
              <div className="activity-conent__block-main">
              {isLoader ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                    <span className="ps-2">Loading...</span>
                  </div>
                ) : _.isEmpty(allActivityLog) ? (
                  <div className="empty-data-block">No ActivityLog</div>
                ) : (
                  !(isLoader || _.isUndefined(isLoader)) && (
                  <>
                    {_.map(allActivityLog, (activityLog, index) => {
                      return (
                        <ActivityItem
                          key={`activityLog-${index}`}
                          icon={<BiPlug />}
                          text={
                            <>
                              <span className="meta-info">
                                {_.get(activityLog, 'type') ? _.get(activityLog, 'type', '') : 'Message'} {''}
                              </span>
                              <span className="meta-info">
                                received from {_.get(activityLog, 'from') === 'server' ? 'server' : _.get(activityLog, 'charger.name', '')}
                              </span>
                            </>
                          }
                          // date={moment(_.get(activityLog, 'date_time.from')).format('ddd' + ', ' + 'DD MMM' + ', ' + 'hh:mm A')}
                          date={moment(_.get(activityLog, 'date_time.from', '')).tz('Asia/Dubai').format('llll')}
                          dropdownList={
                            <>
                              {/* <li className="item" onClick={() => deleteActivityLog(_.get(activityLog, 'id', ''))}> */}
                              <li
                                className="item"
                                onClick={() => {
                                  setShowDeleteModal(true);
                                  setActivityId(_.get(activityLog, 'id', ''));
                                }}
                              >
                                Delete
                              </li>
                              {_.has(activityLog, 'message') && (
                                <li
                                  className="item"
                                  key={index}
                                  onClick={() => {
                                    setShowModal(true);
                                    setMessage(_.get(activityLog, 'message', ''));
                                  }}
                                >
                                  View
                                </li>
                              )}
                            </>
                          }
                        />
                      );
                    })}
                    {/* <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} /> */}
                    
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allActivityLog) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                    )}
                  </>
                  )
                )}
                {showDeleteModal && (
                  <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                    <DeleteModal title="Activity" onClose={handleCloseDeleteModel} onRemove={deleteActivity} />
                  </Modal>
                )}
              </div>
            </Card>
          </div>
        </div>
        {showModal && (
          <Modal show={showModal} size="lg" centered>
            <div className="activityLog__main">
              <Card>
                <div className="activityLog__block">
                  <div className="activityLog-name">
                    <span> Message </span>
                  </div>
                  <div className="close-btn">
                    <MdClose size={30} color="#be210b" onClick={() => setShowModal(false)} />
                  </div>
                </div>
                <div className="json-table">
                  <JsonToTable json={message} />
                </div>
              </Card>
            </div>
          </Modal>
        )}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ChargerActivityDetail;
