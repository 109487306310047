import {
  FETCH_PERMISSION,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_FAIL,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAIL,
  CREATE_PERMISSION,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAIL,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_SUCCESS,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
} from 'actions/permissions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchPermission(action) {
  try {
    const response = yield call(API.getPermission, action.payload);
    yield put({ type: FETCH_PERMISSION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_PERMISSION_FAIL, payload: { error: e } });
  }
}

function* getPermissionById(action) {
  try {
    const response = yield call(API.getPermissionById, action.payload);
    yield put({ type: GET_PERMISSION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_PERMISSION_FAIL, payload: { error: e } });
  }
}

function* createPermission(action) {
  try {
    const response = yield call(API.addPermission, action.payload);
    yield put({ type: CREATE_PERMISSION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_PERMISSION_FAIL, payload: { error: e } });
  }
}

function* updatePermission(action) {
  try {
    const response = yield call(API.updatePermission, action.payload);
    yield put({ type: UPDATE_PERMISSION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_PERMISSION_FAIL, payload: { error: e } });
  }
}

function* deletePermission(action) {
  try {
    const response = yield call(API.deletePermission, action.payload);
    yield put({ type: DELETE_PERMISSION_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_PERMISSION_FAIL, payload: { error: e } });
  }
}

function* permissions() {
  yield takeLatest(FETCH_PERMISSION, fetchPermission);
  yield takeLatest(GET_PERMISSION, getPermissionById);
  yield takeLatest(CREATE_PERMISSION, createPermission);
  yield takeLatest(UPDATE_PERMISSION, updatePermission);
  yield takeLatest(DELETE_PERMISSION, deletePermission);
}
export default permissions;
