import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { CREATE_USER_GROUP, GET_USER_GROUP, UPDATE_USER_GROUP } from 'actions/userGroup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';

const UserGroupSchema = Yup.object().shape({
  name: Yup.string().required('User group name is required!'),
});

const UserGroupForm = () => {
  const dispatch = useDispatch();
  const { userGroupId } = useParams();

  const userGroupDetail = useSelector((state) => state.userGroup.userGroupDetail);

  const initialValues =
    userGroupId && !_.isEmpty(userGroupDetail)
      ? {
          ...userGroupDetail,
        }
      : {
          name: '',
        };

  const getUserGroupDetails = useCallback((id) => {
    dispatch({ type: GET_USER_GROUP, payload: id });
  }, []);

  useEffect(() => {
    if (userGroupId) {
      getUserGroupDetails(userGroupId);
    }
  }, [userGroupId]);

  const addUserGroup = useCallback((data) => {
    dispatch({
      type: CREATE_USER_GROUP,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          window.location.href = '/userGroupManagement';
        }
      },
    });
  }, []);

  const editUserGroupDetail = useCallback((data) => {
    dispatch({
      type: UPDATE_USER_GROUP,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/userGroupManagement';
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${userGroupId ? 'Edit' : 'Add'} User Group`} backTo="/userGroupManagement" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-form-page-main">
            <Card className="user-group-form-card">
              <div className="user-group-form__block">
                <Formik
                  enableReinitialize={!!userGroupId}
                  initialValues={initialValues}
                  validationSchema={UserGroupSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (userGroupId) {
                      // edit user group api call....
                      const editUserGroupData = _.omit(values, ['tenant']);
                      editUserGroupDetail(editUserGroupData);
                    } else {
                      //add user group api call...
                      addUserGroup(values);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="user-group-form__inner">
                        <Row>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label="User Group Name"
                              placeholder="User group name"
                              type="name"
                              value={values.name}
                              name="name"
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="user-group-form-save--btn--block">
                        <Button type="submit" className="user-group-form-save-btn save--btn" disabled={isSubmitting}>
                          {userGroupId ? 'Edit' : 'Add'} User Group
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default UserGroupForm;
