import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_CHARGER,
  CREATE_CHARGER_FAIL,
  CREATE_CHARGER_SUCCESS,
  FETCH_CHARGER,
  FETCH_CHARGER_SUCCESS,
  FETCH_CHARGER_FAIL,
  GET_CHARGER,
  GET_CHARGER_FAIL,
  GET_CHARGER_SUCCESS,
  DELETE_CHARGER,
  DELETE_CHARGER_SUCCESS,
  DELETE_CHARGER_FAIL,
  FETCH_CHARGER_BY_STATION,
  FETCH_CHARGER_BY_STATION_SUCCESS,
  FETCH_CHARGER_BY_STATION_FAIL,
  UPDATE_CHARGER,
  UPDATE_CHARGER_SUCCESS,
  UPDATE_CHARGER_FAIL,
  CHANGE_CHARGER_STATUS_SUCCESS,
  CHANGE_CHARGER_STATUS_FAIL,
  CHANGE_CHARGER_STATUS,
  CHANGE_CHARGER_IS_ACTIVE_STATUS,
  CHANGE_CHARGER_IS_ACTIVE_STATUS_FAIL,
  CHANGE_CHARGER_IS_ACTIVE_STATUS_SUCCESS,
} from 'actions/charger';

function* fetchCharger(action) {
  try {
    const response = yield call(API.getCharger, action.payload);
    yield put({ type: FETCH_CHARGER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_FAIL, payload: { error: e } });
  }
}

function* getCharger(action) {
  try {
    const response = yield call(API.getChargerById, action.payload);
    yield put({ type: GET_CHARGER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_FAIL, payload: { error: e } });
  }
}

function* createCharger(action) {
  try {
    const response = yield call(API.addCharger, action.payload);
    yield put({ type: CREATE_CHARGER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_CHARGER_FAIL, payload: { error: e } });
  }
}

function* fetchStationChargers(action) {
  try {
    const response = yield call(API.getChargersByStation, action.payload);
    yield put({ type: FETCH_CHARGER_BY_STATION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_BY_STATION_FAIL, payload: { error: e } });
  }
}

function* deleteCharger(action) {
  try {
    yield call(API.deleteCharger, action.payload);
    yield put({ type: DELETE_CHARGER_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CHARGER_FAIL, payload: { error: e } });
  }
}

function* updateCharger(action) {
  try {
    const response = yield call(API.updateCharger, action.payload);
    yield put({ type: UPDATE_CHARGER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_CHARGER_FAIL, payload: { error: e } });
  }
}

function* changeChargerStatus(action) {
  try {
    const response = yield call(API.updateCharger, action.payload);
    yield put({ type: CHANGE_CHARGER_STATUS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_CHARGER_STATUS_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeChargerIsActiveStatus(action) {
  try {
    const response = yield call(API.updateCharger, action.payload);
    yield put({ type: CHANGE_CHARGER_IS_ACTIVE_STATUS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_CHARGER_IS_ACTIVE_STATUS_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* charger() {
  yield takeLatest(FETCH_CHARGER, fetchCharger);
  yield takeLatest(GET_CHARGER, getCharger);
  yield takeLatest(CREATE_CHARGER, createCharger);
  yield takeLatest(DELETE_CHARGER, deleteCharger);
  yield takeLatest(FETCH_CHARGER_BY_STATION, fetchStationChargers);
  yield takeLatest(UPDATE_CHARGER, updateCharger);
  yield takeLatest(CHANGE_CHARGER_STATUS, changeChargerStatus);
  yield takeLatest(CHANGE_CHARGER_IS_ACTIVE_STATUS, changeChargerIsActiveStatus);
}
export default charger;
