import React, { useState, useEffect } from 'react';
import ADIconLogo from 'assets/images/icons/adnoc-logo.png';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/inputs/Input';
// import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
// import * as _ from 'lodash';
import Button from 'components/inputs/Button';
// import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'actions/authAction';
import { getFCMToken } from '../../firebase';
import PropTypes from 'prop-types';

const loginSchema = Yup.object({
  // phone: Yup.string().required('Phone number is Required').max(15, 'Text limit should be less than 15 digits.'),
  password: Yup.string().required('Password is Required').trim('Space is not allowed').strict(true),
  // country_code: Yup.string().required('Country Code Required'),
  email: Yup.string()
    .required('Email is Required')
    .strict(true)
    .trim('Space is not allowed')
    .email('Please enter valid email id')
    .max(100, 'Text limit should be less than 100 characters.'),
});

const LoginWithPartner = () => {
  const [firebaseToken, setFirebaseToken] = useState('');

  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getFCMToken(setFirebaseToken);
      return data;
    }
    tokenFunc();
  }, [setFirebaseToken]);

  return (
    <main className="login-page__main">
      <div className="login-bg-block">
        <div className="login-content-block">
          <div className="logo-block">
            <div className="logo--img">
              <img src={ADIconLogo} alt="Adnoc" />
            </div>
          </div>
          <div className="page-block__main">
            <div className="title">Login</div>
            <p className="sub-title">Welcome to Adnoc</p>
            <div className="login-tab">
              <div className="tab-block">
                <div className="tab-content-block">
                  <div className="tab-content__item active" id="login-tab">
                    {<LoginPage firebaseToken={firebaseToken} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const LoginPage = (props) => {
  const { firebaseToken } = props;
  // const [phoneError, setPhoneError] = useState('');
  // const [countryCode, setCountryCode] = useState('');
  const dispatch = useDispatch();
  // const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  // const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');

  return (
    <div className="login-form__block">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (firebaseToken) {
            const data = { ...values, loginwith: 'password', web_token: firebaseToken };
            dispatch({ type: LOGIN, payload: data });
          } else {
            const data = { ...values, loginwith: 'password' }; //country_code: getCountryCode, phone: getPhoneNumber,
            dispatch({ type: LOGIN, payload: data });
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-block--wrapper">
              <div className="form--content">
                {/* <PhoneNumberInput
                  label="Phone Number"
                  name="phone"
                  value={values.phone}
                  onChange={(val) => {
                    setFieldValue(`phone`, val);
                    setCountryCode(val || '');
                    if (!_.isEmpty(phoneError)) {
                      setPhoneError('');
                    }
                  }}
                  error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : null}
                /> */}
                <TextInput
                  isRequired
                  label="Email"
                  placeholder="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email ? errors.email : null}
                />
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password && touched.password ? errors.password : null}
                />
              </div>
              <div className="forgot-password-link--block">
                <Link to={'/forgotPassword'}>Forgot Password</Link>
              </div>
              <div className="login-button--block">
                <Button type="submit" className="login--btn" disabled={isSubmitting}>
                  Login
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

LoginPage.propTypes = {
  firebaseToken: PropTypes.string,
};

export default LoginWithPartner;
