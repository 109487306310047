import React, { useCallback, useState, useEffect } from 'react';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { FETCH_INVOICE } from 'actions/invoice';
import { useDispatch } from 'react-redux';
import { BiSort } from 'react-icons/bi';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import AdnocLogo from 'assets/images/icons/adnocLogoRed.png';
import Button from 'components/inputs/Button';
import { BsEye } from 'react-icons/bs';
import { GET_CHARGER_BOOKING } from 'actions/chargerBooking';
import { useParams } from 'react-router-dom';
import { roundOfDigit } from 'components/common/utils';

const InvoiceInfo = (props) => {
  const { startingDate, endingDate, todayDateData, weeklyDateData, monthlyDateData } = props;
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const invoiceList = useSelector((state) => state.invoice.invoices);
  const page = useSelector((state) => state.invoice.page);
  const totalData = useSelector((state) => state.invoice.total);
  const limit = useSelector((state) => state.invoice.limit);
  const totalPages = useSelector((state) => state.invoice.totalPages);

  const getInvoice = useCallback((data = {}) => {
    dispatch({ type: FETCH_INVOICE, payload: data });
  }, []);

  useEffect(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startingDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endingDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startingDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endingDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      endingDate && getInvoice(filterDateData);
    }
  }, [startingDate, endingDate, sortByItem]);

  const getSortByItem = (name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page && startingDate && endingDate) {
      const data = {
        from: moment(startingDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endingDate).format('YYYY-MM-DD HH:mm:ss'),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      endingDate && getInvoice(data);
    } else if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getInvoice(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      endingDate && getInvoice(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getInvoice(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        const filterDateData = {
          from: moment(startingDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endingDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
      } else if (startingDate && endingDate) {
        const data = {
          from: moment(startingDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endingDate).format('YYYY-MM-DD HH:mm:ss'),
          page: page.selected + 1,
        };
        getInvoice(data);
      } else if (todayDateData) {
        const data = {
          ...todayDateData,
          page: page.selected + 1,
        };
        getInvoice(data);
      } else if (weeklyDateData) {
        const data = {
          ...weeklyDateData,
          page: page.selected + 1,
        };
        getInvoice(data);
      } else if (monthlyDateData) {
        const data = {
          ...monthlyDateData,
          page: page.selected + 1,
        };
        getInvoice(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getInvoice(data);
      }
    },
    [sortByItem, startingDate, endingDate]
  );

  return (
    <div className="invoice-list--table">
      <div className="table-responsive">
        <table className="record-list-table">
          <thead>
            <tr>
              <th>
                <div className="sorting">
                  <span>Customer Name</span>
                  {/* <span className="ico" onClick={() => handleSorting('customer_user_booked', sortByItem)}>
                    <BiSort size={15} />
                  </span> */}
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Customer Number</span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Date & Time</span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Vehicle Registration Number </span>
                  {/* <span className="ico" onClick={() => handleSorting('vehicle.vin_num', sortByItem)}>
                    <BiSort size={15} />
                  </span> */}
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Station Name</span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>OCPP ID (Connector)</span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Charging Duration (HH:MM)</span>
                </div>
              </th>
              {/*<th>*/}
              {/*  <div className="sorting">*/}
              {/*    <span>Time Taken To Charge</span>*/}
              {/*    <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>*/}
              {/*      <BiSort size={15} />*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</th>*/}
              <th>
                <div className="sorting">
                  <span>Unit Consumed (kWh)</span>
                  <span className="ico" onClick={() => handleSorting('meterstart', sortByItem)}>
                    <BiSort size={15} />
                  </span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Cost Per Unit (INR)</span>
                  <span className="ico" onClick={() => handleSorting('estimated_units', sortByItem)}>
                    <BiSort size={15} />
                  </span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Total Cost (INR)</span>
                  <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                    <BiSort size={15} />
                  </span>
                </div>
              </th>
              {/*<th>*/}
              {/*  <div className="sorting">*/}
              {/*    <span>Payment Method</span>*/}
              {/*    <span className="ico">*/}
              {/*      <BiSort size={15} />*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</th>*/}
              <th>
                <div className="sorting">
                  <span>Invoice Number</span>
                </div>
              </th>
              <th>
                <div className="sorting">
                  <span>Invoice</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(invoiceList) ? (
              <tr>
                <td colSpan={14} className="border-0">
                  <div className="empty-data-block">No Invoice</div>
                </td>
              </tr>
            ) : (
              _.map(invoiceList, (item, key) => {
                //To find which connector is available
                const connectorData = _.find(item && item.charger ? _.get(item, 'charger.plugs', '') : '', {
                  connector_id: item && item.connectorId ? item.connectorId : '',
                });

                //calculation of unit Consumption
                const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                  ? '-'
                  : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);

                //calculation of price per Unit
                const pricePerUnit = unitConsumed
                  ? isNaN(parseFloat(_.get(item, 'invoice.subtotal') / unitConsumed).toFixed(2))
                    ? '-'
                    : parseFloat(_.get(item, 'invoice.subtotal') / unitConsumed).toFixed(2)
                  : '-';

                //Functionality of convert difference of two date to time
                const bookingStart = moment(_.get(item, 'booking_start', '-')).tz('Asia/Dubai').format('DD/MM/YYYY h:mm A');
                const bookingStop = moment(_.get(item, 'booking_stop', '-')).tz('Asia/Dubai').format('DD/MM/YYYY h:mm A');
                const chargingDurationHour = moment(bookingStop, 'DD/MM/YYYY h:mm A').diff(moment(bookingStart, 'DD/MM/YYYY h:mm A'), 'h');
                const chargingDurationMinute =
                  moment(bookingStop, 'DD/MM/YYYY h:mm A').diff(moment(bookingStart, 'DD/MM/YYYY h:mm A'), 'm') - chargingDurationHour * 60;

                return (
                  <tr key={`invoice-${key}`}>
                    <td>{_.get(item, 'customer_user_booked.name', 'Guest User')}</td>
                    <td>
                      {' '}
                      +{_.get(item, 'customer_user_booked.country_code', '')}
                      {_.get(item, 'customer_user_booked.phone', 'NA')}
                    </td>
                    <td>{moment(_.get(item, 'booking_start', '-')).tz('Asia/Dubai').format('DD/MM/YYYY H:mm:ss')}</td>
                    <td>{_.get(item, 'vehicle.vin_num', '-')}</td>
                    <td>{_.get(item, 'charger.charging_station.name', '-')}</td>
                    <td>{_.get(item, 'charger.charger_id', '') + ' ' + (connectorData ? `(${_.get(connectorData, 'name')})` : '')}</td>
                    <td>{`${chargingDurationHour}:${chargingDurationMinute}`}</td>
                    {/*<td>{_.get(item, 'time_taken', '-')}</td>*/}
                    <td>{unitConsumed}</td>
                    <td>{pricePerUnit}</td>
                    <td>{roundOfDigit(_.get(item, 'invoice.subtotal', '-'), 2)}</td>
                    {/*<td>Razorpay</td>*/}
                    <td>{_.get(item, 'invoice.id', '-')}</td>
                    <td>
                      <div className="invoice-list-icon" onClick={() => window.open(`/invoice/${_.get(item, 'id', '')}`)}>
                        <BsEye size={16} color="#ffffff" />
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {!_.isEmpty(invoiceList) && (
        // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
        <ReactPagination
          currentPage={page}
          limit={limit}
          total={totalData}
          handlePageClick={(pageVal) => handlePageClick(pageVal)}
          totalPages={totalPages}
          marginPagesDisplayed={1}
        />
      )}
    </div>
  );
};
InvoiceInfo.propTypes = {
  startingDate: PropTypes.string,
  endingDate: PropTypes.string,
  todayDateData: PropTypes.string,
  weeklyDateData: PropTypes.string,
  monthlyDateData: PropTypes.string,
};
export default InvoiceInfo;

export const InvoiceTable = () => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams();
  const invoiceDetail = useSelector((state) => state.chargerBooking.chargerBookingDetail);

  const getInvoiceById = useCallback((id) => {
    dispatch({ type: GET_CHARGER_BOOKING, payload: id });
  });

  useEffect(() => {
    getInvoiceById(invoiceId);
  }, []);

  const downloadInvoice = () => {
    //download Invoice
    let content = document.getElementById('downloadInvoice');
    let print = document.getElementById('invoicePrint').contentWindow;
    print.document.open();
    print.document.write(content.innerHTML);
    print.document.close();
    print.focus();
    print.print();
  };
  //To find which connector is available
  const connectorData = _.find(invoiceDetail && invoiceDetail.charger ? _.get(invoiceDetail, 'charger.plugs', '') : '', {
    connector_id: invoiceDetail && invoiceDetail.connectorId ? invoiceDetail.connectorId : '',
  });

  //calculation of unit Consumption.
  const unitConsumed = isNaN(_.get(invoiceDetail, 'meterstop') - _.get(invoiceDetail, 'meterstart') / 1000)
    ? '-'
    : (_.get(invoiceDetail, 'meterstop') - _.get(invoiceDetail, 'meterstart')) / 1000;

  //calculation of pricePerUnit Consumption.
  const pricePerUnit = unitConsumed
    ? isNaN(parseFloat(_.get(invoiceDetail, 'invoice.subtotal') / unitConsumed).toFixed(2))
      ? '-'
      : parseFloat(_.get(invoiceDetail, 'invoice.subtotal') / unitConsumed).toFixed(2)
    : '-';

  //Functionality of convert difference of two date to time
  const bookingStart = moment(_.get(invoiceDetail, 'booking_start', '-')).tz('Asia/Dubai').format('DD/MM/YYYY h:mm A');
  const bookingStop = moment(_.get(invoiceDetail, 'booking_stop', '-')).tz('Asia/Dubai').format('DD/MM/YYYY h:mm A');
  const chargingDurationHour = moment(bookingStop, 'DD/MM/YYYY h:mm A').diff(moment(bookingStart, 'DD/MM/YYYY h:mm A'), 'h');
  const chargingDurationMinute = moment(bookingStop, 'DD/MM/YYYY h:mm A').diff(moment(bookingStart, 'DD/MM/YYYY h:mm A'), 'm');

  return (
    <div className="invoice-page_main">
      <div className="print--btn">
        <Button onClick={downloadInvoice} className="px-5">
          Print
        </Button>
      </div>
      <iframe title="Invoice" id="invoicePrint" className="iframe-class" />
      <Card className="invoice-page--wrapper scrollable">
        <div id="downloadInvoice">
          <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td align="center">
                  <div className="logo--img">
                    <img style={{ height: 100, width: 200 }} src={AdnocLogo} alt="adnoc" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <hr size={4} noshade="" color="#ee2027" />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <h2 style={{ marginBottom: 0 }}>
                    Thank you for Charging with us, <font color="#ee2027">{_.get(invoiceDetail, 'customer_user_booked.name', '-')}!</font>
                  </h2>
                </td>
              </tr>
              <tr>
                <td height={60}>&nbsp;</td>
              </tr>
              <tr>
                <td align="left">
                  <table width="40%" border={1} bordercolor="#000000" cellSpacing={0} cellPadding={3}>
                    <tbody>
                      <tr>
                        <th width="51%" align="left" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Invoice No
                        </th>
                        <td width="49%" align="right">
                          {_.get(invoiceDetail, 'invoice.id', '-')}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Invoice Date
                        </th>
                        <td align="right">{moment(_.get(invoiceDetail, 'booking_start', '-')).tz('Asia/Dubai').format('DD/MM/YYYY')}</td>
                      </tr>
                      <tr>
                        <th align="left" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Booking ID
                        </th>
                        <td align="right"> {_.get(invoiceDetail, 'id', '-')}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={30}>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width="50%" valign="top">
                          <table width="98%" border={0} cellSpacing={0} cellPadding={3}>
                            <tbody>
                              <tr>
                                <th align="left" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                                  Invoice To:
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={3}>
                                    <tbody>
                                      <tr>
                                        <td width="30%">Name</td>
                                        <td width="70%">{_.get(invoiceDetail, 'customer_user_booked.name', '-')}</td>
                                      </tr>
                                      <tr>
                                        <td>Contact No</td>
                                        <td>
                                          {`+${_.get(invoiceDetail, 'customer_user_booked.country_code', '-')}` +
                                            ' ' +
                                            _.get(invoiceDetail, 'customer_user_booked.phone', '-')}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Email Id</td>
                                        <td>{_.get(invoiceDetail, 'customer_user_booked.email', '-')}</td>
                                      </tr>
                                      <tr>
                                        <td>EV Model</td>
                                        <td>{_.get(invoiceDetail, 'vehicle.make', '-') + ' ' + `(${_.get(invoiceDetail, 'vehicle.model', '-')})`}</td>
                                      </tr>
                                      <tr>
                                        <td>EV RN</td>
                                        <td>{_.get(invoiceDetail, 'vehicle.vin_num', '-')}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width="50%" valign="top">
                          <table width="98%" border={0} cellSpacing={0} cellPadding={3}>
                            <tbody>
                              <tr>
                                <th align="left" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                                  Station Info:
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={3}>
                                    <tbody>
                                      <tr>
                                        <td width="40%">Station Name</td>
                                        <td width="60%">{_.get(invoiceDetail, 'charger.charging_station.name', '-')}</td>
                                      </tr>
                                      <tr>
                                        <td>Charge Spot</td>
                                        <td>
                                          {_.get(invoiceDetail, 'charger.charger_id', '') +
                                            ' ' +
                                            (connectorData ? `(${_.get(connectorData, 'name')})` : '')}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Charging Date &amp; Time</td>
                                        <td>{moment(_.get(invoiceDetail, 'booking_start', '-')).tz('Asia/Dubai').format('DD/MM/YYYY h:mm:ss')}</td>
                                      </tr>
                                      <tr>
                                        <td>Charging Duration (h:mm)</td>
                                        <td>{`${chargingDurationHour}:${chargingDurationMinute}`}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={30}>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table width="100%" cellSpacing={0} cellPadding={3} border={0} bordercolor="#000000">
                    <tbody align="center">
                      <tr>
                        <th width="46%" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Description
                        </th>
                        <th width="18%" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Price Per Unit (INR)
                        </th>
                        <th width="18%" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Unit Consumed (kWh)
                        </th>
                        <th width="18%" bgcolor="#cccccc" style={{ backgroundColor: '#cccccc' }}>
                          Amount (INR)
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <strong>Service Charges</strong>
                        </td>
                        <td align="center">
                          <strong>{pricePerUnit}</strong>
                        </td>
                        <td align="center">
                          <strong>{unitConsumed}</strong>
                        </td>
                        <td align="center">
                          <strong>{_.get(invoiceDetail, 'invoice.subtotal', '-')}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>CGST(9.00 %)</strong>
                        </td>
                        <td align="center">&nbsp;</td>
                        <td align="center">&nbsp;</td>
                        <td align="center">
                          <strong>{isNaN(_.get(invoiceDetail, 'invoice.gst') / 2) ? '-' : _.get(invoiceDetail, 'invoice.gst') / 2}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>SGST(9.00 %)</strong>
                        </td>
                        <td align="center">&nbsp;</td>
                        <td align="center">&nbsp;</td>
                        <td align="center">
                          <strong>{isNaN(_.get(invoiceDetail, 'invoice.gst') / 2) ? '-' : _.get(invoiceDetail, 'invoice.gst') / 2}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td>
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} align="right">
                          <strong>Total Payable Amount</strong>
                        </td>
                        <td align="center">
                          <strong>{parseFloat(_.get(invoiceDetail, 'invoice.amount', '-')).toFixed(3)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={30}>&nbsp;</td>
              </tr>
              <tr>
                <td align="center">
                  <h1 style={{ marginBottom: 0 }}>
                    <font color="#ee2027">Total Amount Paid (INR): {parseFloat(_.get(invoiceDetail, 'invoice.amount', '-')).toFixed(3)}</font>
                  </h1>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  For any dispute, please email us at <a href="mailto:support@Adnoc.com"> support@Adnoc.com</a>. &nbsp;
                  {/*<strong>or</strong> Call us at Toll Free Number <strong>1800-121-2025.</strong>*/}
                  <br />
                  For more details, please visit us at{' '}
                  <a href="https://Adnoc.com/">
                    <strong> www.Adnoc.com</strong>
                  </a>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {/*<tr>*/}
              {/*  <td align="center" style={{ fontSize: 18 }}>*/}
              {/*    <strong>TecSo ChargeZone Ltd.</strong>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td>*/}
              {/*    <hr size={4} noshade="" color="#ee2027" />*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td align="center">401, Benison Complex, Opp.Shiv Mahal Palace, Old Padra Road, Vadodara – 390 007 Gujarat, India</td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td align="center">Contact No: +91 72269 99590</td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td align="center">*/}
              {/*    GST No: <strong>24AAGCT8628J1ZY</strong>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};
