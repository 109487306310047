import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { AiOutlineDownload } from 'react-icons/ai';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit, startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { MOST_VEHICLE_CHARGED_LIST } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
import Button from 'components/inputs/Button';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';

const MostVehicleChargedList = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [fieldValue, setFieldValue] = useState('All');
  const [allMostVehicleChargedListData, setAllMostVehicleChargedListData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const allMostVehicleChargedList = useSelector((state) => state.mostVehicleChargedList.mostVehicleChargedLists);

  const mostVehicleChargedList = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({ type: MOST_VEHICLE_CHARGED_LIST, payload: data });
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      mostVehicleChargedList(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && mostVehicleChargedList(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && mostVehicleChargedList(oldestData);
  //   fieldValue === 'All' && mostVehicleChargedList();
  // }, [fieldValue]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    setAllMostVehicleChargedListData(
      _.filter(
        _.filter(allMostVehicleChargedList, (item) => item),
        (item, key) =>
          startsWith(_.get(item.user[key], 'email'), searchText) ||
          startsWith(_.get(item.user[key], 'name'), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'transaction_amount'), 2).toString(), searchText) ||
          startsWith(_.get(item, 'transaction_count').toString(), searchText)
      )
    );
  }, [searchText, allMostVehicleChargedList]);

  useEffect(() => {
    mostVehicleChargedList();
  }, []);

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      mostVehicleChargedList(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      mostVehicleChargedList(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Most Vehicle Charged List" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 4 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                    <Col xl={`${showSearchBar ? 6 : 9}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).startOf('day')}
                          initialValueOfEndDate={moment(new Date()).endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block">
                      <div className="report-excel-icon">
                        {/* <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="User Charging History"
                            sheet="User Charging History"
                            buttonText={<SiMicrosoftexcel size={28} />}
                          /> */}
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Email</span>
                              <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Mobile No</span>
                              <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>No Of Transaction</span>
                              <span className="ico" onClick={() => handleSorting('transaction_count', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Total Transaction Amount</span>
                              <span className="ico" onClick={() => handleSorting('transaction_amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allMostVehicleChargedListData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No Most Vehicle Charged List Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allMostVehicleChargedListData, (item, key) => {
                            return _.map(_.get(item, 'user'), (data) => {
                              return (
                                <>
                                  <tr key={`most-vehicle-charged-list-${key}`}>
                                    <td>{key + 1}</td>
                                    <td>{_.get(data, 'name', 'Guest User')}</td>
                                    <td>{_.get(data, 'email', '')}</td>
                                    <td>{_.get(data, 'phone', '')}</td>
                                    <td>{_.get(item, 'transaction_count', '')}</td>
                                    <td>{roundOfDigit(_.get(item, 'transaction_amount', ''), 2)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default MostVehicleChargedList;
