import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import Button from 'components/inputs/Button';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FETCH_CITY, FETCH_STATE, FETCH_COUNTRY, FETCH_AREA } from 'actions/address';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TENANT, GET_TENANT, UPDATE_TENANT_CONTACT_INFORMATION } from 'actions/tenant';
import Select from 'components/inputs/Select';
import moment from 'moment';
import DatePickerInput from 'components/inputs/DatePickerInput';
import CheckBox from 'components/inputs/CheckBox';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import RadioButton from 'components/inputs/RadioButton';
import { BiPlus, BiTrash } from 'react-icons/bi';

const EditContactInformationSchema = Yup.object().shape({
  contact_person: Yup.object().shape({
    name: Yup.string()
      .required('Name is required!')
      .strict(true)
      .trim('Space is not allowed')
      .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
      .max(100, 'Text limit should be less than 100 characters.'),
    email: Yup.string()
      .required('Email is required')
      .strict(true)
      .trim('Space is not allowed')
      .email('Please enter valid email id')
      .max(100, 'Text limit should be less than 100 characters.'),
    // phone: Yup.string()
    //   .required('Phone Number is required!')
    //   .strict(true)
    //   .trim('Space is not allowed')
    //   .max(100, 'Text limit should be less than 100 characters.'),
    dob: Yup.string()
      .required('Dob is required!')
      .strict(true)
      .trim('Space is not allowed')
      .max(100, 'Text limit should be less than 100 characters.'),
    address: Yup.string()
      .required('Address is required')
      .strict(true)
      .trim('Space is not allowed')
      .max(100, 'Text limit should be less than 100 characters.'),
  }),
});

const EditTenantSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required!')
    .strict(true)
    .trim('Space is not allowed')
    .matches(/^[A-Za-z\s]/, 'Only alphabets are allowed')
    .max(100, 'Text limit should be less than 100 characters.'),
  charging_stations: Yup.number().required('Charging station is required!').min(1, 'Invalid number'),
  chargers: Yup.number().required('Charger is required!').min(0, 'Invalid number'),
  mode: Yup.string().required('Mode is required!'),
  license_expiry: Yup.string().required('License expiry is required!'),
  allow_remote_access: Yup.string().required('Remote access is required!'),
  max_user: Yup.number().required('Maximum user is required!').min(0, 'Invalid number'),
  address: Yup.object().shape({
    country: Yup.string().required('Country is required!'),
    state: Yup.string().required('State is required!'),
    city: Yup.string().required('City is required!'),
    area: Yup.string().required('Area is required!'),
    pincode: Yup.string().required('Pincode is required!'),
    details: Yup.string()
      .required('Address is required!')
      .strict(true)
      .trim('Space is not allowed')
      .max(100, 'Text limit should be less than 100 characters.'),
  }),
  details: Yup.object().shape({
    gst: Yup.array().of(
      Yup.object().shape({
        state: Yup.string().required('State is required!'),
        value: Yup.string().required('GST is required'),
      })
    ),
  }),
});

const TenantForm = () => {
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const history = useHistory();
  const [phoneError, setPhoneError] = useState('');

  const tenantDetail = useSelector((state) => state.tenant.tenantDetail);
  const allCountries = useSelector((state) => state.country.countries);
  const allStates = useSelector((state) => state.state.states);
  const allCities = useSelector((state) => state.city.city);
  const allArea = useSelector((state) => state.area.areas);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const areaPage = useSelector((state) => state.area.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const areaTotalPage = useSelector((state) => state.area.totalPages);

  const getTenant = useCallback((id) => {
    dispatch({ type: GET_TENANT, payload: id });
  }, []);

  const updateContactInformation = useCallback((data) => {
    dispatch({
      type: UPDATE_TENANT_CONTACT_INFORMATION,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/tenantAdmin';
        }
      },
    });
  }, []);

  const editTenantDetail = useCallback((data) => {
    dispatch({
      type: UPDATE_TENANT,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/tenantAdmin';
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_AREA, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
  }, []);

  useEffect(() => {
    if (tenantId && !_.isEmpty(tenantDetail)) {
      getStateByCountry({ country_name: tenantDetail.address.country });
      getCityByState({ state_name: tenantDetail.address.state });
      getAreaByCity({ city_name: tenantDetail.address.city, limit: 999 });
    }
  }, [tenantId, tenantDetail]);

  useEffect(() => {
    if (tenantId) {
      getTenant(tenantId);
    }
  }, [tenantId]);

  const initialValues = {
    ...tenantDetail,
    license_expiry: moment(_.get(tenantDetail, 'license_expiry')).utcOffset(0, true).format('YYYY-MM-DD'),
    allow_remote_access: _.get(tenantDetail, 'allow_remote_access', '').toString(),
    max_user: _.get(tenantDetail, 'max_user', 0),
    details: {
      gst: _.map(_.get(tenantDetail, 'details.gst'), (item) => {
        return { is_default: item.is_default, state: item.state, value: item.value };
      }),
      notes: _.get(tenantDetail, 'details.notes', ''),
    },
  };

  const contactInformationInitialValues = {
    contact_person: {
      name: _.get(tenantDetail, 'contact_person.name'),
      email: _.get(tenantDetail, 'contact_person.email'),
      dob: moment(_.get(tenantDetail, 'contact_person.dob')).format('YYYY-MM-DD'),
      address: _.get(tenantDetail, 'contact_person.address'),
      gender: _.get(tenantDetail, 'contact_person.gender'),
      phone: `+${_.get(tenantDetail, 'contact_person.country_code')} ${_.get(tenantDetail, 'contact_person.phone')}`.toString(),
    },
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`Edit Tenant`} backTo="/tenantAdmin" />
        <div className="page-content-wrapper scrollable">
          <div className="add-tenant-page__main">
            <Card className="tenant-form--card">
              <Formik
                enableReinitialize={!!tenantId}
                initialValues={initialValues}
                validationSchema={EditTenantSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const tenantData = _.omit(values, ['contact_person', 'user_count']);
                  const editTenantData = {
                    ...tenantData,
                    license_expiry: moment(_.get(values, 'license_expiry', '')).format('MM-DD-YYYY'),
                    allow_remote_access: JSON.parse(_.get(values, 'allow_remote_access').toLowerCase()),
                  };
                  editTenantDetail(editTenantData);
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <SessionHeading text="General Information" />
                      <div className="tenant-form--block pb-4">
                        <Row>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label="Company Name"
                              placeholder="Company name"
                              name="name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label="Mode"
                              options={[
                                { label: 'B2B', value: 'b2b' },
                                { label: 'B2C', value: 'b2c' },
                                { label: 'Hybrid', value: 'hybrid' },
                              ]}
                              placeholder="mode"
                              name="mode"
                              value={values.mode}
                              onChange={(val) => setFieldValue(`mode`, val)}
                              error={errors.mode && touched.mode ? errors.mode : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder="Charging station"
                              label="Charging Stations"
                              name="charging_stations"
                              type="number"
                              value={values.charging_stations}
                              onChange={handleChange}
                              error={errors.charging_stations && touched.charging_stations ? errors.charging_stations : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder="Chargers"
                              label="Chargers"
                              name="chargers"
                              type="number"
                              value={values.chargers}
                              onChange={handleChange}
                              error={errors.chargers && touched.chargers ? errors.chargers : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              placeholder="Maximum user"
                              label="Maximum User"
                              name="max_user"
                              type="number"
                              value={values.max_user}
                              onChange={handleChange}
                              error={errors.max_user && touched.max_user ? errors.max_user : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <DatePickerInput
                              isRequired
                              placeholder="License expiry"
                              label="License Expiry"
                              name="license_expiry"
                              value={values.license_expiry}
                              onChange={(dateString) => setFieldValue('license_expiry', dateString)}
                              error={errors.license_expiry && touched.license_expiry ? errors.license_expiry : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              as="textarea"
                              rows="4"
                              label="Notes"
                              placeholder="Notes"
                              name="details.notes"
                              value={_.get(values, 'details.notes')}
                              onChange={handleChange}
                              // error={getIn(errors, 'details.notes') && getIn(touched, 'details.notes') ? getIn(errors, 'details.notes') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label="Remote Access"
                              options={[
                                { label: 'True', value: 'true' },
                                { label: 'False', value: 'false' },
                              ]}
                              placeholder="Select remote access"
                              name="allow_remote_access"
                              value={values.allow_remote_access}
                              onChange={(val) => setFieldValue(`allow_remote_access`, val)}
                              error={errors.allow_remote_access && touched.allow_remote_access ? errors.allow_remote_access : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="notification-setting__block">
                              <label htmlFor="notification_allow" className="notification--label">
                                Notification Allow As:
                              </label>
                              <div id="notification_allow" className="notification-setting--inner">
                                <div className="notification--item">
                                  <CheckBox
                                    id="email"
                                    name="email"
                                    checked={_.includes(_.get(values, 'settings.notification_allowed'), 'Email')}
                                    onChange={() => {
                                      if (_.includes(_.get(values, 'settings.notification_allowed'), 'Email')) {
                                        const newNotificationData = _.remove(
                                          _.get(values, 'settings.notification_allowed'),
                                          (item) => item !== 'Email'
                                        );
                                        setFieldValue('settings.notification_allowed', newNotificationData);
                                      } else {
                                        setFieldValue(
                                          'settings.notification_allowed',
                                          _.concat(_.get(values, 'settings.notification_allowed'), 'Email')
                                        );
                                      }
                                    }}
                                  >
                                    Email
                                  </CheckBox>
                                </div>
                                <div className="notification--item">
                                  <CheckBox
                                    id="sms"
                                    name="sms"
                                    checked={_.includes(_.get(values, 'settings.notification_allowed'), 'SMS')}
                                    onChange={() => {
                                      if (_.includes(_.get(values, 'settings.notification_allowed'), 'SMS')) {
                                        const newNotificationData = _.remove(
                                          _.get(values, 'settings.notification_allowed'),
                                          (item) => item !== 'SMS'
                                        );
                                        setFieldValue('settings.notification_allowed', newNotificationData);
                                      } else {
                                        setFieldValue(
                                          'settings.notification_allowed',
                                          _.concat(_.get(values, 'settings.notification_allowed'), 'SMS')
                                        );
                                      }
                                    }}
                                  >
                                    SMS
                                  </CheckBox>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <div className="mt-3">
                            <Col lg={12} md={12}>
                              <SessionHeading text="GST Details" />
                              <div className="tenant-form-page__main">
                                <div className="gst-block__field-inputs">
                                  <FieldArray
                                    name="details.gst"
                                    render={(arrayHelpers) => (
                                      <>
                                        <Row>
                                          {_.map(values.details.gst, (field, index) => (
                                            <>
                                              <Col lg={12} key={`gst-${index}`}>
                                                <Row>
                                                  <Col lg={4} md={4}>
                                                    <Select
                                                      isRequired
                                                      className="mb-3"
                                                      label="State"
                                                      onFocus={() => getStateByCountry({ country_name: 'India' })}
                                                      options={_.map(allStates, (item) => {
                                                        return { label: item.name, value: item.name };
                                                      })}
                                                      placeholder="Select State"
                                                      name="state"
                                                      value={_.get(field, 'state')}
                                                      onMenuScrollDown={true}
                                                      getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: 'India' })}
                                                      page={statePage}
                                                      totalPage={stateTotalPage}
                                                      onChange={(val) => {
                                                        // getCityByState(val);
                                                        const currentState = _.find(allStates, { name: val });
                                                        setFieldValue(`details.gst[${index}].state`, currentState.name);
                                                      }}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].state`) && getIn(touched, `details.gst[${index}].state`)
                                                          ? getIn(errors, `details.gst[${index}].state`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={4} md={4}>
                                                    <TextInput
                                                      isRequired
                                                      label="GST"
                                                      name="value"
                                                      value={field.value}
                                                      onChange={(e) => setFieldValue(`details.gst[${index}].value`, e.target.value)}
                                                      error={
                                                        getIn(errors, `details.gst[${index}].value`) && getIn(touched, `details.gst[${index}].value`)
                                                          ? getIn(errors, `details.gst[${index}].value`)
                                                          : null
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={3} md={3}>
                                                    <div className="pt-5">
                                                      <label htmlFor="is_default" className="pe-2">
                                                        Is Default :
                                                      </label>
                                                      <CheckBox
                                                        name="is_default"
                                                        checked={field.is_default}
                                                        onChange={(e) => setFieldValue(`details.gst[${index}].is_default`, e.target.checked)}
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                                    {index > 0 && (
                                                      <Button className="tenant-field--btn" onClick={() => arrayHelpers.remove(index)}>
                                                        <BiTrash size={25} />
                                                      </Button>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </>
                                          ))}
                                        </Row>
                                        <div>
                                          <Button
                                            className="tenant-field--btn"
                                            onClick={() =>
                                              arrayHelpers.insert(values.details.gst.length, {
                                                state: '',
                                                value: '',
                                                is_default: false,
                                              })
                                            }
                                          >
                                            <BiPlus size={22} className="plus--icon" /> Add
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <SessionHeading text="Address Information" />
                      <div className="tenant-form--block">
                        <Row>
                          <Col lg={12} md={12}>
                            <TextInput
                              isRequired
                              label="Address"
                              placeholder="Address"
                              name="address.details"
                              value={_.get(values, 'address.details')}
                              onChange={handleChange}
                              error={getIn(errors, 'address.details') && getIn(touched, 'address.details') ? getIn(errors, 'address.details') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label="Country"
                              options={_.map(allCountries, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder="Select Country"
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllCountry}
                              page={countryPage}
                              totalPage={countryTotalPage}
                              name="address.country"
                              value={_.get(values, 'address.country')}
                              onChange={(val) => {
                                getStateByCountry({ country_name: val });
                                const currentCountry = _.find(allCountries, { name: val });
                                setFieldValue(`address.country`, currentCountry.name);
                                setFieldValue('address.state', '');
                                setFieldValue(`address.city`, '');
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.country') && getIn(touched, 'address.country') ? getIn(errors, 'address.country') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              className="mb-3"
                              label="State"
                              options={_.map(allStates, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder="Select State"
                              name="address.state"
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: _.get(values, 'address.country') })}
                              page={statePage}
                              totalPage={stateTotalPage}
                              value={_.get(values, 'address.state')}
                              onChange={(val) => {
                                getCityByState({ state_name: val });
                                const currentState = _.find(allStates, { name: val });
                                setFieldValue(`address.state`, currentState.name);
                                setFieldValue(`address.city`, '');
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.state') && getIn(touched, 'address.state') ? getIn(errors, 'address.state') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              className="mb-3"
                              label="City"
                              options={
                                _.get(values, 'address.state') &&
                                _.map(allCities, (item) => {
                                  return { label: item.name, value: item.name };
                                })
                              }
                              placeholder="Select City"
                              name="address.city"
                              value={_.get(values, 'address.city')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getCityByState({ ...data, state: _.get(values, 'address.state') })}
                              page={cityPage}
                              totalPage={cityTotalPage}
                              onChange={(val) => {
                                getAreaByCity({ city_name: val });
                                const currentCity = _.find(allCities, { name: val });
                                setFieldValue(`address.city`, currentCity.name);
                                setFieldValue(`address.area`, '');
                                setFieldValue(`address.pincode`, '');
                              }}
                              error={getIn(errors, 'address.city') && getIn(touched, 'address.city') ? getIn(errors, 'address.city') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label="Area"
                              options={
                                _.get(values, 'address.city') &&
                                _.map(allArea, (item) => {
                                  return { label: item.name, value: item.name };
                                })
                              }
                              placeholder="Select Area"
                              name="address.area"
                              value={_.get(values, 'address.area')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: _.get(values, 'address.city') })}
                              page={areaPage}
                              totalPage={areaTotalPage}
                              onChange={(val) => {
                                const currentArea = _.find(allArea, { name: val });
                                setFieldValue(`address.area`, currentArea.name);
                                setFieldValue(`address.pincode`, currentArea.postal_code);
                              }}
                              error={getIn(errors, 'address.area') && getIn(touched, 'address.area') ? getIn(errors, 'address.area') : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              disabled
                              label="Pincode"
                              placeholder="Pincode"
                              name="address.pincode"
                              value={_.get(values, 'address.pincode')}
                              onChange={handleChange}
                              error={getIn(errors, 'address.pincode') && getIn(touched, 'address.pincode') ? getIn(errors, 'address.pincode') : null}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <div className="add-cancel-btn-block">
                        <Button type="submit" className="add--btn" disabled={isSubmitting}>
                          Edit Tenant
                        </Button>
                        <span className="cancel-btn---block">
                          <Button className="cancel---btn" onClick={() => history.push(`/tenantAdmin`)}>
                            Cancel
                          </Button>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div>
                <Formik
                  enableReinitialize={!!tenantId}
                  initialValues={contactInformationInitialValues}
                  validationSchema={EditContactInformationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (!tenantId && values.contact_person.phone && !isValidPhoneNumber(values.contact_person.phone)) {
                      setPhoneError('Phone number is not valid!');
                    } else {
                      // const countryCode = _.get(parsePhoneNumber(values.contact_person.phone), 'countryCallingCode', '');
                      // const phoneNumber = _.get(parsePhoneNumber(values.contact_person.phone), 'nationalNumber', '');
                      const contactInformationData = {
                        ...values.contact_person,
                      };
                      updateContactInformation({ ..._.omit(contactInformationData, ['phone']), id: _.get(tenantDetail, 'contact_person.id', '') });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div>
                        <SessionHeading text="Contact Informations" />
                        <div className="tenant-form--block">
                          <Row>
                            <Col lg={6} md={6}>
                              <TextInput
                                isRequired
                                label="Tenant Admin Name"
                                placeholder="Admin name"
                                name="contact_person.name"
                                value={_.get(values, 'contact_person.name')}
                                onChange={handleChange}
                                error={
                                  getIn(errors, 'contact_person.name') && getIn(touched, 'contact_person.name')
                                    ? getIn(errors, 'contact_person.name')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <TextInput
                                isRequired
                                label="Email Address"
                                placeholder="Email"
                                name="contact_person.email"
                                value={_.get(values, 'contact_person.email')}
                                onChange={handleChange}
                                error={
                                  getIn(errors, 'contact_person.email') && getIn(touched, 'contact_person.email')
                                    ? getIn(errors, 'contact_person.email')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={6}>
                              <PhoneNumberInput
                                disabled={true}
                                label="Phone number"
                                name="contact_person.phone"
                                value={_.get(values, 'contact_person.phone')}
                                onChange={(val) => {
                                  setFieldValue(`contact_person.phone`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                // error={
                                //   getIn(errors, 'contact_person.phone') && getIn(touched, 'contact_person.phone')
                                //     ? getIn(errors, 'contact_person.phone')
                                //     : phoneError
                                //     ? phoneError
                                //     : null
                                // }
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <DatePickerInput
                                label="Date of birth"
                                value={_.get(values, 'contact_person.dob')}
                                onChange={(dateString) => setFieldValue('contact_person.dob', dateString)}
                                error={
                                  getIn(errors, 'contact_person.dob') && getIn(touched, 'contact_person.dob')
                                    ? getIn(errors, 'contact_person.dob')
                                    : null
                                }
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                isRequired
                                label="Address"
                                name="address"
                                value={_.get(values, 'contact_person.address')}
                                onChange={(e) => setFieldValue('contact_person.address', e.target.value)}
                                error={
                                  getIn(errors, 'contact_person.address') && getIn(touched, 'contact_person.address')
                                    ? getIn(errors, 'contact_person.address')
                                    : null
                                }
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={_.get(values, 'contact_person.gender') === 'male'}
                                  onChange={() => setFieldValue('contact_person.gender', 'male')}
                                >
                                  Male
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={_.get(values, 'contact_person.gender') === 'female'}
                                  onChange={() => setFieldValue('contact_person.gender', 'female')}
                                >
                                  Female
                                </RadioButton>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="add-cancel-btn-block">
                        <Button type="submit" className="add--btn" disabled={isSubmitting}>
                          Update Contact
                        </Button>
                        <span className="cancel-btn---block">
                          <Button className="cancel---btn" onClick={() => history.push(`/tenantAdmin`)}>
                            Cancel
                          </Button>
                        </span>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default TenantForm;
