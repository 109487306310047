import { B2B_STATION_CHARGING_HISTORY, B2B_STATION_CHARGING_HISTORY_SUCCESS, B2B_STATION_CHARGING_HISTORY_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  b2bStationChargingHistory: [],
};

const b2bStationChargingHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case B2B_STATION_CHARGING_HISTORY: {
      return { ...state, isLoading: true };
    }
    case B2B_STATION_CHARGING_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        b2bStationChargingHistory: payload,
      };
    }

    case B2B_STATION_CHARGING_HISTORY_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default b2bStationChargingHistoryReducer;
