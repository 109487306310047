export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const LOGIN_WITH_OTP = 'LOGIN_WITH_OTP';
export const LOGIN_WITH_OTP_SUCCESS = 'LOGIN_WITH_OTP_SUCCESS';
export const LOGIN_WITH_OTP_FAIL = 'LOGIN_WITH_OTP_FAIL';

export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const CREATE_NEW_PASSWORD_SUCCESS = 'CREATE_NEW_PASSWORD_SUCCESS';
export const CREATE_NEW_PASSWORD_FAIL = 'CREATE_NEW_PASSWORD_FAIL';

export const TENANT_ADMIN_LOGIN = 'TENANT_ADMIN_LOGIN';
export const TENANT_ADMIN_LOGIN_SUCCESS = 'TENANT_ADMIN_LOGIN_SUCCESS';
export const TENANT_ADMIN_LOGIN_FAIL = 'TENANT_ADMIN_LOGIN_FAIL';

export const LOGINAS = 'LOGINAS';
export const LOGINAS_SUCCESS = 'LOGINAS_SUCCESS';
export const LOGINAS_FAIL = 'LOGINAS_FAIL';
