import React, { useState, useCallback, useEffect } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import LayoutContainer from 'components/layout/LayoutContainer';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import Button from 'components/inputs/Button';
import SearchBox from 'components/general/SearchBox';
import Card from 'components/inputs/Card';
import DropDown from 'components/inputs/DropDown';
import AdminHeader from 'components/header';

import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import { GrMoreVertical } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import { /* CREATE_STATUS_CODE, */ DELETE_STATUS_CODE } from 'components/common/constant';
import { DELETE_CPO, FETCH_CPO, HANDSHAKE, PULL_TOKEN, GET_ENDPOINT } from 'actions/partnerManagement';
import { Formik, Form } from 'formik';
import { MdClose } from 'react-icons/md';

const Emsp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [emspId, setEmspId] = useState('');

  const [endpointModel, setEndpointModal] = useState({ isVisiblity: false, modalName: '', payload: null });

  const EMSPdata = useSelector((state) => state.partnerManagement.partnerDetails);
  const isLoader = useSelector((state) => state.partnerManagement.isLoading);
  const page = useSelector((state) => state.partnerManagement.page);
  const totalData = useSelector((state) => state.partnerManagement.total);
  const limit = useSelector((state) => state.partnerManagement.limit);
  const totalPages = useSelector((state) => state.partnerManagement.totalPages);

  const [allOcpiData, setAllOcpiData] = useState([]);

  const getEMSPdata = useCallback(
    (data) =>
      dispatch({
        type: FETCH_CPO,
        payload: data ? data : null,
      }),
    []
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setEmspId('');
  };

  const deleteEmsp = useCallback(
    () =>
      dispatch({
        type: DELETE_CPO,
        payload: emspId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getEMSPdata({ role: 'EMSP', page: 1 });
          }
        },
      }),
    [emspId]
  );

  useEffect(() => {
    getEMSPdata({ role: 'EMSP' });
  }, []);

  const handleSearch = () => {
    getEMSPdata({ role: 'EMSP' });
  };

  useEffect(() => {
    if (!searchText) {
      setAllOcpiData(EMSPdata);
      return;
    }
    setAllOcpiData(_.filter(allOcpiData, (data) => startsWith(_.get(data, 'owner', ''), searchText)));
  }, [searchText, EMSPdata]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getEMSPdata({ ...data, role: 'EMSP' });
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getEMSPdata({ ...data, role: 'EMSP' });
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getEMSPdata({ ...data, role: 'EMSP' });
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const getEndpointByToken = useCallback(
    (token) =>
      dispatch({
        type: GET_ENDPOINT,
        payload: token,
        cb: (res) => {
          if (_.get(res, 'status', '') === 200) {
            setEndpointModal({ isVisiblity: true, modalName: 'endpoints' });
          }
        },
      }),
    []
  );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Partner Management - eMSP" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-page_main">
            <div className="user-group-main-block">
              <Card>
                <div className="user-group-block">
                  <div className="user-group-search-box">
                    <Row>
                      <Col xl={10}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={2}>
                        <Button className="user-group-btn" onClick={() => history.push('/emsp/addEmsp')}>
                          Create eMSP
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="user-group-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{'Role'}</span>
                                <span className="ico" onClick={() => handleSorting('role', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{'PARTY ID'}</span>
                                <span className="ico" onClick={() => handleSorting('partyId', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{'COUNTRY CODE'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'WEBSITE URL'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'HANDSHAKING COMPLETED'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>{'OWNER'}</span>
                                <span className="ico" onClick={() => handleSorting('owner', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'ACTIONS'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'MORE'}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allOcpiData) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">No eMSP yet</div>
                              </td>
                            </tr>
                          ) : (
                            allOcpiData?.map((data, index) => (
                              <tr key={index}>
                                <td>{_.get(data, 'role')}</td>
                                <td>{_.get(data, 'partyId')}</td>
                                <td>{_.get(data, 'countryCode')}</td>
                                <td>{_.get(data, 'website_url')}</td>
                                <td>{_.get(data, 'handshake_completed') ? 'true' : 'false'}</td>
                                <td>{_.get(data, 'owner')}</td>

                                <td>
                                  <span
                                    className="user-group-table-editIcon"
                                    onClick={() => {
                                      const ocpiId = _.get(data, 'id');
                                      history.push(`/emsp/${ocpiId}`);
                                    }}
                                  >
                                    {<FiEdit2 />}
                                  </span>
                                  <span
                                    className="user-group-table-deleteIcon"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setEmspId(_.get(data, 'id'));
                                    }}
                                  >
                                    {<RiDeleteBinLine />}
                                  </span>
                                </td>
                                <td>
                                  <DropDown
                                    trigger={
                                      <span className="dropdown-icon">
                                        <GrMoreVertical />
                                      </span>
                                    }
                                    popupClass="bottom-left"
                                  >
                                    <ul>
                                      <li
                                        className="item"
                                        onClick={() => {
                                          const id = _.get(data, 'id');
                                          dispatch({
                                            type: HANDSHAKE,
                                            payload: id,
                                          });
                                        }}
                                      >
                                        Perform HandShaking
                                      </li>

                                      <li
                                        className="item"
                                        onClick={() => {
                                          const id = _.get(data, 'id');
                                          getEndpointByToken(id);
                                        }}
                                      >
                                        View Endponits
                                      </li>

                                      <li
                                        className="item"
                                        onClick={() => {
                                          const id = _.get(data, 'id');
                                          dispatch({
                                            type: PULL_TOKEN,
                                            payload: id,
                                          });
                                        }}
                                      >
                                        Pull Token
                                      </li>
                                    </ul>
                                  </DropDown>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(EMSPdata) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="eMSP " onClose={handleCloseDeleteModel} onRemove={deleteEmsp} />
                    </Modal>
                  )}

                  {endpointModel.isVisiblity && (
                    <Modal show={endpointModel.isVisiblity} size="xl" centered>
                      <ViewDataForm
                        onClose={() => {
                          setEndpointModal({ ...endpointModel, isVisiblity: false });
                        }}
                        modalData={endpointModel}
                      />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default Emsp;

//view endpoints

const ViewDataForm = ({ onClose = _.noop() }) => {
  // const dispatch = useDispatch();
  const viewEndpoints = useSelector((state) => state.ocpiPullRequest?.endpoints);

  const initialValues = {
    version: '',
    identifier: '',
    role: '',
    url: '',
  };

  // const data = _.map(
  //   _.filter(viewEndpoints, (data) => data.token === modalData.tokenId),
  //   (data) => data
  // );

  // const getEndpoint = useCallback((data) => {
  //   dispatch({
  //     type: VIEW_ENDPOINTS,
  //     payload: data,
  //     cb: (res) => {
  //       if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
  //         onClose();
  //       }
  //     },
  //   });
  // });

  // useEffect(() => {
  //   getEndpoint();
  // }, []);

  return (
    <React.Fragment>
      <div className="group-form-page__main">
        {viewEndpoints.isLoading ? (
          <div className="loader--block">
            <Spinner as="span" animation="border" size="sm" role="status" />
            <span className="ps-2">Loading...</span>
          </div>
        ) : (
          <Card>
            <div className="group-form-header__block">
              <div className="group-header-name">
                <span>{' View Endpoints'} </span>
              </div>
              <div className="close___btn">
                <MdClose size={30} color="#BE210B" onClick={onClose} />
              </div>
            </div>
            <div className="group-form-body__block">
              <div className="group-form--block">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>Version: {_.get(viewEndpoints, 'version')}</Col>
                      </Row>

                      <Row>
                        <Col>
                          <Card>
                            <div className="user-group-table">
                              <div className="table-responsive">
                                <table className="record-list-table">
                                  <thead>
                                    <tr>
                                      <th>
                                        <div className="sorting">
                                          <span>{'Identifier'}</span>
                                        </div>
                                      </th>
                                      <th>
                                        <div className="sorting">
                                          <span>{'Role'}</span>
                                        </div>
                                      </th>
                                      <th className="action-col">
                                        <div>
                                          <span>{'URL'}</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_.map(viewEndpoints.endpoints, (data) => (
                                      <tr key={_.get(data, '_id')}>
                                        <td>{_.get(data, 'identifier')}</td>
                                        <td>{_.get(data, 'role')}</td>
                                        <td>{_.get(data, 'url')}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <div className="form-btn__block">
                        <Button type="submit" className="form_btn">
                          Continue
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};
ViewDataForm.propTypes = {
  onClose: PropTypes.func,
};
