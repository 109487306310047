import { JSON_REQUEST_RESPONSE_REPORT_SUCCESS, JSON_REQUEST_RESPONSE_REPORT_FAIL, JSON_REQUEST_RESPONSE_REPORT } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  jsonRequestResponseReports: [],
};

const jsonRequestResponseReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case JSON_REQUEST_RESPONSE_REPORT: {
      return { ...state, isLoading: true };
    }

    case JSON_REQUEST_RESPONSE_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        jsonRequestResponseReports: payload,
      };
    }

    case JSON_REQUEST_RESPONSE_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default jsonRequestResponseReportReducer;
