import { APP_USER_DETAILS_LIST_SUCCESS, APP_USER_DETAILS_LIST_FAIL, APP_USER_DETAILS_LIST } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  appUserDetailsLists: [],
};

const appUserDetailsListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_USER_DETAILS_LIST: {
      return { ...state, isLoading: true };
    }
    case APP_USER_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        appUserDetailsLists: payload,
      };
    }

    case APP_USER_DETAILS_LIST_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default appUserDetailsListReducer;
