import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch, BsThreeDots } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import DropDown from 'components/inputs/DropDown';
import { FETCH_OFFERS } from 'actions/offers';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { roundOfDigit, startsWith } from 'components/common/utils';
import { DELETE_OFFERS } from 'actions/offers';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import moment from 'moment';

const OfferList = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [allOffersData, setAllOffersData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [offerId, setOfferId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const dispatch = useDispatch();
  const allOffers = useSelector((state) => state.offers.offers);
  const page = useSelector((state) => state.offers.page);
  const totalData = useSelector((state) => state.offers.total);
  const limit = useSelector((state) => state.offers.limit);
  const totalPages = useSelector((state) => state.offers.totalPages);

  const getOffer = useCallback((data) => {
    dispatch({ type: FETCH_OFFERS, payload: data });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setOfferId('');
  };

  const deleteOffer = useCallback(
    () =>
      dispatch({
        type: DELETE_OFFERS,
        payload: offerId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getOffer({ page });
          }
        },
      }),
    [offerId]
  );

  useEffect(() => {
    setAllOffersData(
      _.filter(
        allOffers,
        (item) =>
          startsWith(_.get(item, 'code', ''), searchText) ||
          startsWith(_.get(item, 'amount', '').toString(), searchText) ||
          startsWith(_.get(item, 'type', ''), searchText) ||
          startsWith(_.get(item, 'max_uses_per_user', '').toString(), searchText)
      )
    );
  }, [searchText, allOffers]);

  useEffect(() => {
    getOffer();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        -getOffer(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getOffer(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getOffer(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Offers" />
        <div className="page-content-wrapper scrollable">
          <div className="offer-management-page_main">
            <div className="offer-list-main-block">
              <Card>
                <div className="offer-list-block">
                  <div className="offer-search-box">
                    <Row>
                      <Col xl={10}>
                        <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                      </Col>
                      <Col xl={2}>
                        <Button className="add-offer-btn" onClick={() => history.push('/addOffer')}>
                          Add Offer
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="offer-list-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Sr no.</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Code</span>
                                <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Amount</span>
                                <span className="ico" onClick={() => handleSorting('amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Type</span>
                                <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Max Uses Per User</span>
                                <span className="ico" onClick={() => handleSorting('max_uses_per_user', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span> Expiry </span>
                                <span className="ico" onClick={() => handleSorting('expiry', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>Action</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(allOffersData) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">No Offers</div>
                              </td>
                            </tr>
                          ) : (
                            _.map(allOffersData, (offers, index) => {
                              return (
                                <tr key={`offers-data-item-${index}`}>
                                  <td>{index + 1}</td>
                                  <td>{_.get(offers, 'code', '')}</td>
                                  <td>{roundOfDigit(_.get(offers, 'amount', ''), 2)} </td>
                                  <td>{_.get(offers, 'type', '')}</td>
                                  <td>{_.get(offers, 'max_uses_per_user', '')}</td>
                                  <td>{moment(_.get(offers, 'expiry', '')).format('YYYY-MM-DD')}</td>
                                  <td>
                                    <div key={`action-${index}`} className="offer-list">
                                      <span className="list-icon">
                                        <DropDown
                                          popupClass="bottom-left"
                                          trigger={
                                            <div className={'more-info'}>
                                              <BsThreeDots />
                                            </div>
                                          }
                                        >
                                          <ul>
                                            <li className="item" onClick={() => history.push(`/editOffer/${offers.id}`)}>
                                              Edit
                                            </li>
                                            <li
                                              className="item"
                                              onClick={() => {
                                                setShowDeleteModal(true);
                                                setOfferId(_.get(offers, 'id', ''));
                                              }}
                                            >
                                              Delete
                                            </li>
                                          </ul>
                                        </DropDown>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!_.isEmpty(allOffersData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                    // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                  )}
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="offers" onClose={handleCloseDeleteModel} onRemove={deleteOffer} />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default OfferList;
