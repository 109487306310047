import React, { useState, useCallback, useEffect } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import DeleteModal from 'components/general/DeleteModal';
import DateTimePicker from 'components/inputs/DateTimePicker';
import DropDown from 'components/inputs/DropDown';
import ReactPagination from 'components/general/ReactPagination';
import LayoutContainer from 'components/layout/LayoutContainer';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';

import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import { GrMoreVertical } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { DELETE_CPO, FETCH_CPO, HANDSHAKE, GET_ENDPOINT, PULL_CDRS, PULL_LOCATION, PULL_SESSIONS, PULL_TARIFF } from 'actions/partnerManagement';
import { CREATE_STATUS_CODE, DELETE_STATUS_CODE } from 'components/common/constant';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import { Formik, Form } from 'formik';
import { MdClose } from 'react-icons/md';

const Cpo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [cpoId, setcpoId] = useState('');

  const [selectDateModal, setSelectDateModal] = useState({ isVisiblity: false, modalName: '', payload: null });
  const [endpointModel, setEndpointModal] = useState({ isVisiblity: false, modalName: '', payload: null });

  const CPOdata = useSelector((state) => state.partnerManagement.partnerDetails);
  const isLoader = useSelector((state) => state.partnerManagement.isLoading);
  const page = useSelector((state) => state.partnerManagement.page);
  const totalData = useSelector((state) => state.partnerManagement.total);
  const limit = useSelector((state) => state.partnerManagement.limit);
  const totalPages = useSelector((state) => state.partnerManagement.totalPages);

  const [allOcpiData, setAllOcpiData] = useState([]);

  const getCPOdata = useCallback(
    (data) =>
      dispatch({
        type: FETCH_CPO,
        payload: data ? data : null,
      }),
    []
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setcpoId('');
  };

  const deleteCpo = useCallback(
    () =>
      dispatch({
        type: DELETE_CPO,
        payload: cpoId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getCPOdata({ role: 'CPO', page: 1 });
          }
        },
      }),
    [cpoId]
  );

  useEffect(() => {
    getCPOdata({ role: 'CPO' });
  }, []);

  const handleSearch = () => {
    getCPOdata({ role: 'CPO' });
  };

  useEffect(() => {
    if (!searchText) {
      setAllOcpiData(CPOdata);
      return;
    }
    setAllOcpiData(_.filter(allOcpiData, (data) => startsWith(_.get(data, 'owner', ''), searchText)));
  }, [searchText, CPOdata]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          role: 'CPO',
          page: page.selected + 1,
        };
        getCPOdata(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        role: 'CPO',
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCPOdata(data);
    } else {
      const data = {
        role: 'CPO',
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCPOdata(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const getEndpointByToken = useCallback(
    (token) =>
      dispatch({
        type: GET_ENDPOINT,
        payload: token,
        cb: (res) => {
          if (_.get(res, 'status', '') === 200) {
            setEndpointModal({ isVisiblity: true, modalName: 'endpoints' });
          }
        },
      }),
    []
  );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Partner Management - CPO" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-page_main">
            <div className="user-group-main-block">
              <Card>
                <div className="user-group-block">
                  <div className="user-group-search-box">
                    <Row>
                      <Col xl={10}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={2}>
                        <Button className="user-group-btn" onClick={() => history.push('/cpo/addCpo')}>
                          Create CPO
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="user-group-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{'Role'}</span>
                                <span className="ico" onClick={() => handleSorting('role', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{'PARTY ID'}</span>
                                <span className="ico" onClick={() => handleSorting('partyId', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{'COUNTRY CODE'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'WEBSITE URL'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'HANDSHAKING COMPLETED'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div  className="sorting">
                                <span>{'OWNER'}</span>
                                <span className="ico" onClick={() => handleSorting('owner', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'ACTIONS'}</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>{'MORE'}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allOcpiData) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">No CPO yet</div>
                              </td>
                            </tr>
                          ) : (
                            allOcpiData?.map((data, index) => (
                              <tr key={index}>
                                <td>{_.get(data, 'role')}</td>
                                <td>{_.get(data, 'partyId')}</td>
                                <td>{_.get(data, 'countryCode')}</td>
                                <td>{_.get(data, 'website_url')}</td>
                                <td>{_.get(data, 'handshake_completed') ? 'true' : 'false'}</td>
                                <td>{_.get(data, 'owner')}</td>

                                <td>
                                  <span
                                    className="user-group-table-editIcon"
                                    onClick={() => {
                                      const ocpiId = _.get(data, 'id');
                                      history.push(`/cpo/${ocpiId}`);
                                    }}
                                  >
                                    {<FiEdit2 />}
                                  </span>
                                  <span
                                    className="user-group-table-deleteIcon"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setcpoId(_.get(data, 'id'));
                                    }}
                                  >
                                    {<RiDeleteBinLine />}
                                  </span>
                                </td>
                                <td>
                                  <div className="rfid-group">
                                    <span className="list-icon">
                                      <DropDown
                                        trigger={
                                          <span className="dropdown-icon">
                                            <GrMoreVertical />
                                          </span>
                                        }
                                        popupClass="bottom-left"
                                      >
                                        <ul>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: HANDSHAKE,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            Perform HandShaking
                                          </li>

                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              getEndpointByToken(id);
                                            }}
                                          >
                                            View Endponits
                                          </li>

                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: PULL_LOCATION,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            Pull location
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: PULL_TARIFF,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            Pull tariffs
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              setSelectDateModal({ isVisiblity: true, modalName: 'session', payload: _.get(data, 'id') });
                                            }}
                                          >
                                            Pull Sessions
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              setSelectDateModal({ isVisiblity: true, modalName: 'cdrs', payload: _.get(data, 'id') });
                                            }}
                                          >
                                            Pull Cdrs
                                          </li>
                                        </ul>
                                      </DropDown>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(CPOdata) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="CPO " onClose={handleCloseDeleteModel} onRemove={deleteCpo} />
                    </Modal>
                  )}

                  {selectDateModal.isVisiblity && (
                    <Modal show={selectDateModal.isVisiblity} size="lg" centered>
                      <PushDataForm
                        onClose={() => {
                          setSelectDateModal({ ...selectDateModal, isVisiblity: false });
                        }}
                        modalData={selectDateModal}
                      />
                    </Modal>
                  )}

                  {endpointModel.isVisiblity && (
                    <Modal show={endpointModel.isVisiblity} size="xl" centered>
                      <ViewDataForm
                        onClose={() => {
                          setEndpointModal({ ...endpointModel, isVisiblity: false });
                        }}
                        modalData={endpointModel}
                      />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default Cpo;

const PushDataForm = ({ onClose = _.noop(), modalData }) => {
  const dispatch = useDispatch();

  const initialValues = {
    dateFrom: moment(new Date()).startOf('day').utc().format('YYYY-MM-DD'),
    dateTo: moment(new Date()).startOf('day').utc().format('YYYY-MM-DD'),
    id: modalData.payload,
  };

  // pull session
  const pullSession = useCallback((data) => {
    dispatch({
      type: PULL_SESSIONS,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  });

  // pull CDRS
  const pullCdrs = useCallback((data) => {
    dispatch({
      type: PULL_CDRS,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  });

  // useEffect(() => {
  //   console.log('initialValues: ', initialValues);l
  // }, [startDate, endDate]);

  return (
    <React.Fragment>
      <div className="group-form-page__main">
        <Card>
          <div className="group-form-header__block">
            <div className="group-header-name">
              <span> Pull {modalData.modalName == 'session' ? 'session' : 'cdrs'} </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="group-form-body__block">
            <div className="group-form--block">
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  modalData.modalName == 'session' ? pullSession(values) : pullCdrs(values);
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, setValues }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setValues({ ...values, dateFrom: moment(item).format('YYYY-MM-DD') })}
                          onChangeOfEndDate={(item) => setValues({ ...values, dateTo: moment(item).format('YYYY-MM-DD') })}
                          initialValueOfStartDate={moment(new Date()).startOf('day').utc()}
                          initialValueOfEndDate={moment(new Date()).startOf('day').utc()}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button type="submit" className="form_btn">
                        Continue
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
PushDataForm.propTypes = {
  modalData: PropTypes.object,
  onClose: PropTypes.func,
};

//view endpoints

const ViewDataForm = ({ onClose = _.noop() }) => {
  // const dispatch = useDispatch();
  const viewEndpoints = useSelector((state) => state.ocpiPullRequest?.endpoints);

  const initialValues = {
    version: '',
    identifier: '',
    role: '',
    url: '',
  };

  // const data = _.map(
  //   _.filter(viewEndpoints, (data) => data.token === modalData.tokenId),
  //   (data) => data
  // );

  // const getEndpoint = useCallback((data) => {
  //   dispatch({
  //     type: VIEW_ENDPOINTS,
  //     payload: data,
  //     cb: (res) => {
  //       if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
  //         onClose();
  //       }
  //     },
  //   });
  // });

  // useEffect(() => {
  //   getEndpoint();
  // }, []);

  return (
    <React.Fragment>
      <div className="group-form-page__main">
        <Card>
          <div className="group-form-header__block">
            <div className="group-header-name">
              <span> View Endpoints </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="group-form-body__block">
            <div className="group-form--block">
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                }}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>Version: {_.get(viewEndpoints, 'version')}</Col>
                    </Row>

                    <Row>
                      <Col>
                        <Card>
                          <div className="user-group-table">
                            <div className="table-responsive">
                              <table className="record-list-table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div className="sorting">
                                        <span>Identifier</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="sorting">
                                        <span>Role</span>
                                      </div>
                                    </th>
                                    <th className="action-col">
                                      <div>
                                        <span>URL</span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.map(viewEndpoints.endpoints, (data) => (
                                    <tr key={_.get(data, '_id')}>
                                      <td>{_.get(data, 'identifier')}</td>
                                      <td>{_.get(data, 'role')}</td>
                                      <td>{_.get(data, 'url')}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>

                    <div className="form-btn__block">
                      <Button type="submit" className="form_btn">
                        Continue
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ViewDataForm.propTypes = {
  onClose: PropTypes.func,
};
