import React, { useCallback } from 'react';
import Button from 'components/inputs/Button';
import Card from 'components/inputs/Card';
import { GET_LOCAL_LIST } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const GetLocalListVersionForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const getGetLocalListVersion = useCallback((chargerId) => {
    const data = {
      id: chargerId,
    };
    dispatch({
      type: GET_LOCAL_LIST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Get local list version successfully');
          }
        }
      },
    });
  }, []);
  return (
    <React.Fragment>
      <div className="serverOperation-title-name">LOCAL LIST CONFIGURATION</div>
      <Card>
        <div className="p-2">
          <Button onClick={() => getGetLocalListVersion(chargerId)}>Get Local List Version</Button>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default GetLocalListVersionForm;
