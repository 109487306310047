import React, { useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { BiSave } from 'react-icons/bi';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import Pagination from 'components/general/Pagination';
import SearchBox from 'components/general/SearchBox';
import { CHANGE_CONFIGURATION, GET_CONFIGURATION } from 'actions/chargerOcpp';
import { toast } from 'react-toastify';
import TextInput from 'components/inputs/Input';
import { GoCircleSlash } from 'react-icons/go';
import { FETCH_CHARGER_LOG } from 'actions/activityLog';

const ConfigurationList = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [isLoader, setIsLoader] = useState();
  const [configurationValue, setConfigurationValue] = useState('');

  const getAllChargerLog = useSelector((state) => state.activityLog.activityLogs);
  const page = useSelector((state) => state.activityLog.page);
  const limit = useSelector((state) => state.activityLog.limit);
  // const totalData = useSelector((state) => state.activityLog.total);

  const getChargerLog = useCallback((data = {}) => {
    const chargerLogData = {
      charger: chargerId,
      from: 'server',
      type: 'GetConfiguration',
      limit: 1,
      ...data,
    };
    dispatch({
      type: FETCH_CHARGER_LOG,
      payload: chargerLogData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200 && _.isEmpty(_.get(res, 'data.results'))) {
            setIsLoader(true);
            setTimeout(() => {
              getChargerLog();
            }, 3000);
          } else {
            setIsLoader(false);
          }
        }
      },
    });
  }, []);

  // const handlePageClick = (page) => {
  //   const data = {
  //     page: page,
  //   };
  //   getChargerLog(data);
  // };

  const getConfiguration = useCallback((chargerId) => {
    const data = {
      id: chargerId,
    };
    dispatch({
      type: GET_CONFIGURATION,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            setIsLoader(true);
            toast.success('Get configuration successfully');
            setTimeout(() => {
              getChargerLog();
            }, 3000);
          } else {
            setIsLoader(false);
          }
        }
      },
    });
  }, []);

  let serialNum = limit * (page - 1);

  const changeConfigurationValue = (e) => {
    setConfigurationValue(e.target.value);
  };

  const changeConfigurationData = useCallback((data) => {
    dispatch({
      type: CHANGE_CONFIGURATION,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Change configuration successfully');
          }
        }
      },
    });
  }, []);

  const changeConfiguration = (key) => {
    const data = {
      key: key,
      value: configurationValue,
      id: chargerId,
    };
    changeConfigurationData(data);
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">GET SET CONFIGURATION KEY</div>
      <Card className="mb-3">
        <Button onClick={() => getConfiguration(chargerId)}>Get Configuration Key</Button>
      </Card>
      <div className="server-operation-table-page_main">
        <div className="server-operation-table-main-block">
          <Card>
            <div className="server-operation-table-block">
              <div className="server-operation-search-box">
                <Row>
                  <Col>
                    <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                </Row>
              </div>
              <div className="server-operation-table_list">
                <div className="table-responsive">
                  <table className="record-list-table">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>
                          <div className="sorting">
                            <span>Configuration Key</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>Configuration Value</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>Action</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoader ? (
                        <tr>
                          <td colSpan={8} className="border-0">
                            <div className="loader--block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                              <sapn className="ps-2">Loading...</sapn>
                            </div>
                          </td>
                        </tr>
                      ) : _.isEmpty(getAllChargerLog) ? (
                        <tr>
                          <td colSpan={8} className="border-0">
                            <div className="empty-data-block">No data available</div>
                          </td>
                        </tr>
                      ) : (
                        !(isLoader || _.isUndefined(isLoader)) &&
                        _.map(getAllChargerLog, (item) => {
                          return (
                            _.get(item, 'response.configurationKey') &&
                            _.map(_.get(item, 'response.configurationKey'), (data, index) => {
                              const readOnly =
                                typeof _.get(data, 'readonly') === 'string' ? JSON.parse(data.readonly.toLowerCase()) : _.get(data, 'readonly');
                              return (
                                <tr key={`configuration-data-item-${index}`}>
                                  <td>{serialNum + (index + 1)}</td>
                                  <td>{_.get(data, 'key')}</td>
                                  <td>
                                    {!readOnly ? (
                                      <TextInput
                                        key={index}
                                        onChange={(e) => {
                                          changeConfigurationValue(e);
                                        }}
                                        type="text"
                                        name={_.get(data, 'key')}
                                        defaultValue={_.get(data, 'value')}
                                      />
                                    ) : (
                                      <TextInput
                                        disabled={true}
                                        key={index}
                                        onChange={(e) => {
                                          changeConfigurationValue(e);
                                        }}
                                        type="text"
                                        name={_.get(data, 'key')}
                                        defaultValue={_.get(data, 'value')}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {!readOnly ? (
                                      <div className="button--block" onClick={() => changeConfiguration(_.get(data, 'key'))}>
                                        <BiSave size={22} />
                                      </div>
                                    ) : (
                                      <div className="button--block">
                                        <GoCircleSlash size={22} />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*{!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(getAllChargerLog) && (*/}
              {/*  <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />*/}
              {/*)}*/}
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfigurationList;
