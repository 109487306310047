import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/inputs/Card';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray, getIn } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_OEM, GET_OEM, UPDATE_OEM } from 'actions/oem';
import Select from 'components/inputs/Select';
import SessionHeading from 'components/general/SessionHeading';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { FETCH_CONNECTOR_TYPE } from 'actions/connectorType';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import { useParams, useHistory } from 'react-router-dom';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import EditOemModal from './EditOemModal';
import { FETCH_CHARGER } from 'actions/charger';

const OemSchema = Yup.object().shape({
  vendor: Yup.string().required('OEM Vendor name is required'),
  name: Yup.string()
    .required('Chargespot model name is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  input_voltage: Yup.string()
    .required('Input voltage is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  power_factor: Yup.string()
    .required('Power factor is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  efficiency: Yup.string()
    .required('Efficiency is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  input_frequency: Yup.string()
    .required('Input frequency is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  display: Yup.string()
    .required('Display is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  push_button: Yup.string()
    .required('Push button is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  dimension_w_h_d: Yup.string()
    .required('Dimension is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  plugs: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Plug name is required').strict(true).trim('Space is not allowed'),
      type: Yup.string().required('Type is required').strict(true).trim('Space is not allowed'),
      connector_type: Yup.string()
        .required('Connector type is required')
        .typeError('Connector must have some string value')
        .strict(true)
        .trim('Space is not allowed'),
      charge_speed: Yup.string()
        .required('Charge speed is required')
        .typeError('ChargeSpeed must have some value')
        .strict(true)
        .trim('Space is not allowed'),
      code: Yup.string().required('Code is required').strict(true).trim('Space is not allowed'),
      current: Yup.string().required('Current is required').strict(true).trim('Space is not allowed'),
      rating: Yup.string().required('Rating is required').strict(true).trim('Space is not allowed'),
      number: Yup.number().required('Number is required').typeError('It must be a number').positive('Only positive numbers are allowed'),
      format: Yup.string().required('Format is required').strict(true).trim('Space is not allowed'),
      power_type: Yup.string().required('Power type is required').strict(true).trim('Space is not allowed'),
    })
  ),
});

const OemForm = () => {
  const { oemId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const oemDetail = useSelector((state) => state.oem.oemDetail);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const oemPage = useSelector((state) => state.oemVendor.page);
  const connectorTypePage = useSelector((state) => state.connectorType.page);
  const chargeSpeedPage = useSelector((state) => state.chargeSpeed.page);
  const oemTotalPage = useSelector((state) => state.oemVendor.totalPages);
  const connectorTypeTotalPage = useSelector((state) => state.connectorType.totalPages);
  const chargeSpeedTotalPage = useSelector((state) => state.chargeSpeed.totalPages);
  const allConnectorType = useSelector((state) => state.connectorType.connectorTypes);
  const allChargeSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  const isLoading = useSelector((state) => state.oem.isLoading);
  const chargerData = useSelector((state) => state.charger.chargers);
  const [showEditOemModal, setShowEditOemModal] = useState(false);
  const [editData, setEditData] = useState();

  const chargerName = _.join(
    _.map(chargerData, (item) => item.charger_id),
    ' , '
  );

  const addOem = useCallback((data) => {
    dispatch({ type: CREATE_OEM, payload: data });
  }, []);

  const getOemDetail = useCallback((id) => {
    dispatch({ type: GET_OEM, payload: id });
  }, []);

  const getChargerId = useCallback(() => {
    const oemData = {
      oem: oemId,
    };
    dispatch({ type: FETCH_CHARGER, payload: oemData });
  }, []);

  const updateOem = useCallback((data) => {
    dispatch({ type: UPDATE_OEM, payload: data });
  }, []);

  const getOemVendor = useCallback((data = {}) => {
    dispatch({ type: FETCH_OEM_VENDOR, payload: data });
  }, []);

  const getConnectorType = useCallback((data = {}) => {
    dispatch({ type: FETCH_CONNECTOR_TYPE, payload: data });
  }, []);

  const getChargeSpeed = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGE_SPEED, payload: data });
  }, []);

  useEffect(() => {
    getOemVendor();
    getConnectorType();
    getChargeSpeed();
  }, []);

  useEffect(() => {
    if (oemId) {
      getOemDetail(oemId);
      getChargerId(oemId);
    }
  }, [oemId]);

  const handleCloseEditOemModel = () => {
    setShowEditOemModal(false);
  };

  const handleClick = () => {
    updateOem(editData);
    setShowEditOemModal(false);
  };

  const initialValues = oemId
    ? {
        ...oemDetail,
        vendor: _.get(oemDetail, 'vendor.id', ''),
        plugs: _.map(oemDetail.plugs, (item) => {
          return { ...item, charge_speed: item.charge_speed.id, connector_type: item.connector_type.id };
        }),
      }
    : {
        name: '',
        vendor: '',
        input_voltage: '',
        power_factor: '',
        efficiency: '',
        input_frequency: '',
        wires: '',
        ambient_temperature: '',
        storage_temperature: '',
        altitude: '',
        humidity: '',
        display: '',
        language: '',
        push_button: '',
        user_authentication: '',
        visual_indication: '',
        protection: '',
        charger_vehicle_communication: '',
        charger_cms_communication: '',
        ingress_protection: '',
        enclosure_protection: '',
        cooling: '',
        wire_length: '',
        dimension_w_h_d: '',
        image: 'https://imagepath.png',
        plugs: [
          {
            type: '',
            connector_type: '',
            charge_speed: '',
            name: '',
            code: '',
            current: '',
            rating: '',
            number: '',
            format: '',
            power_type: '',
          },
        ],
      };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${oemId ? 'Edit' : 'Add'} ChargeSpot Model`} backTo="/ChargeSpot" />
        <div className="page-content-wrapper scrollable">
          <div className="add-oem-page__main">
            <Card className="oem-form--card">
              <Formik
                enableReinitialize={!!oemId}
                initialValues={initialValues}
                validationSchema={OemSchema}
                onSubmit={(values, { setSubmitting }) => {
                  let uniqCode = _.uniqBy(values.plugs, 'code');
                  let uniqNumber = _.uniqBy(values.plugs, 'number');
                  if (uniqNumber.length === values.plugs.length) {
                    if (uniqCode.length === values.plugs.length) {
                      if (oemId) {
                        const updatedOemData = _.omit(values, ['make']);
                        const oemData = { ...updatedOemData, plugs: _.map(values.plugs, (item) => _.omit(item, ['_id'])) };
                        !_.isEmpty(chargerData) ? setShowEditOemModal(true) : updateOem(oemData);
                        setEditData(oemData);
                      } else {
                        addOem(values);
                      }
                    } else {
                      toast.error('Plugs code field must be uniq');
                    }
                  } else {
                    toast.error('Plugs number field must be uniq');
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <SessionHeading text="Basic" />
                    <div className="oem-form--block">
                      <Row>
                        <Col lg={6} md={12}>
                          <Select
                            isRequired
                            label="OEM Name"
                            options={_.map(allOemVendor, (item) => {
                              return { label: item.name, value: item.id };
                            })}
                            placeholder="Select OEM Name"
                            name="vendor"
                            value={_.get(values, 'vendor', '')}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getOemVendor}
                            page={oemPage}
                            totalPage={oemTotalPage}
                            onChange={(val) => setFieldValue(`vendor`, val)}
                            error={getIn(errors, `vendor`) && getIn(touched, `vendor`) ? getIn(errors, `vendor`) : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="ChargeSpot Model Name"
                            placeholder="ChargeSpot Model Name"
                            name="name"
                            value={_.get(values, 'name', '')}
                            onChange={handleChange}
                            error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Visual Indication"
                            placeholder="Visual Indication"
                            name="visual_indication"
                            value={_.get(values, 'visual_indication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Protection"
                            placeholder="Protection"
                            name="protection"
                            value={_.get(values, 'protection', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text="Input Power" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Input Voltage"
                            placeholder="Input Voltage"
                            name="input_voltage"
                            value={_.get(values, 'input_voltage', '')}
                            onChange={handleChange}
                            error={errors.input_voltage && touched.input_voltage ? errors.input_voltage : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Power Factor"
                            placeholder="Power Factor"
                            name="power_factor"
                            value={_.get(values, 'power_factor', '')}
                            onChange={handleChange}
                            error={errors.power_factor && touched.power_factor ? errors.power_factor : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Efficiency"
                            placeholder="Efficiency"
                            name="efficiency"
                            value={_.get(values, 'efficiency', '')}
                            onChange={handleChange}
                            error={errors.efficiency && touched.efficiency ? errors.efficiency : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Input Frequency"
                            placeholder="Input Frequency"
                            name="input_frequency"
                            value={_.get(values, 'input_frequency', '')}
                            onChange={handleChange}
                            error={errors.input_frequency && touched.input_frequency ? errors.input_frequency : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput label="Wires" placeholder="Wires" name="wires" value={_.get(values, 'wires', '')} onChange={handleChange} />
                        </Col>
                      </Row>
                      <SessionHeading text="Environment" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Ambient Temperature"
                            placeholder="Ambient Temperature"
                            name="ambient_temperature"
                            value={_.get(values, 'ambient_temperature', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Storage Temperature"
                            placeholder="Storage Temperature"
                            // type="number"
                            name="storage_temperature"
                            value={_.get(values, 'storage_temperature', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Altitude"
                            placeholder="Altitude"
                            // type="number"
                            name="altitude"
                            value={_.get(values, 'altitude', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Humidity"
                            placeholder="Humidity"
                            // type="number"
                            name="humidity"
                            value={_.get(values, 'humidity', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text="User Interface & Control" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Display"
                            placeholder="Display"
                            name="display"
                            value={_.get(values, 'display', '')}
                            onChange={handleChange}
                            error={errors.display && touched.display ? errors.display : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Language"
                            placeholder="Language"
                            name="language"
                            value={_.get(values, 'language', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Push Button"
                            placeholder="Push Button"
                            name="push_button"
                            value={_.get(values, 'push_button', '')}
                            onChange={handleChange}
                            error={errors.push_button && touched.push_button ? errors.push_button : null}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="User Authentication"
                            placeholder="User Authentication"
                            name="user_authentication"
                            value={_.get(values, 'user_authentication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text="Communication" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Charger Vehicle Communication"
                            placeholder="Charger Vehicle Communication"
                            name="charger_vehicle_communication"
                            value={_.get(values, 'charger_vehicle_communication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Charger Cms Communication"
                            placeholder="Charger Cms Communication"
                            name="charger_cms_communication"
                            value={_.get(values, 'charger_cms_communication', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <SessionHeading text="Mechanical" />
                      <Row>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Ingress Protection"
                            placeholder="Ingress Protection"
                            name="ingress_protection"
                            value={_.get(values, 'ingress_protection', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Enclosure Protection"
                            placeholder="Enclosure Protection"
                            name="enclosure_protection"
                            value={_.get(values, 'enclosure_protection', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Cooling"
                            placeholder="Cooling"
                            name="cooling"
                            value={_.get(values, 'cooling', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            label="Wire Length"
                            placeholder="Wire Length"
                            name="wire_length"
                            value={_.get(values, 'wire_length', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <TextInput
                            isRequired
                            label="Dimension"
                            placeholder="Dimension"
                            name="dimension_w_h_d"
                            value={_.get(values, 'dimension_w_h_d', '')}
                            onChange={handleChange}
                            error={errors.dimension_w_h_d && touched.dimension_w_h_d ? errors.dimension_w_h_d : null}
                          />
                        </Col>
                      </Row>
                    </div>
                    <SessionHeading text="Plug Information (Connectors)" />
                    <div className="oem-form--block">
                      <div className="plug-block__field-inputs">
                        <FieldArray
                          name="plugs"
                          render={(arrayHelpers) => (
                            <>
                              {_.map(values.plugs, (field, index) => (
                                <div key={`oem-plug-${index}`} className="oem-plug--item">
                                  <Row xxl={6} lg={4} md={4}>
                                    <Col>
                                      <Select
                                        isRequired
                                        label="Type"
                                        options={[
                                          { label: 'AC', value: 'AC' },
                                          { label: 'DC', value: 'DC' },
                                        ]}
                                        placeholder="Select Type"
                                        name="type"
                                        value={field.type || ''}
                                        onChange={(e) => {
                                          setFieldValue(`plugs[${index}].type`, e);
                                          setFieldValue(`plugs[${index}].connector_type`, '');
                                        }}
                                        error={
                                          getIn(errors, `plugs[${index}].type`) && getIn(touched, `plugs[${index}].type`)
                                            ? getIn(errors, `plugs[${index}].type`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label="Connector Type"
                                        options={_.map(_.filter(allConnectorType, { connector_mode: field.type }), (item) => {
                                          return {
                                            label: item.name,
                                            value: item.id,
                                          };
                                        })}
                                        placeholder="Select Connector Type"
                                        name="connector_type"
                                        value={field.connector_type || ''}
                                        onMenuScrollDown={true}
                                        getDataOnScrollDown={getConnectorType}
                                        page={connectorTypePage}
                                        totalPage={connectorTypeTotalPage}
                                        onChange={(e) => setFieldValue(`plugs[${index}].connector_type`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].connector_type`) && getIn(touched, `plugs[${index}].connector_type`)
                                            ? getIn(errors, `plugs[${index}].connector_type`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label="Charge Speed"
                                        options={_.map(allChargeSpeed, (item) => {
                                          return { label: item.name, value: item.id };
                                        })}
                                        placeholder="Select Charge Speed"
                                        name="charge_speed"
                                        value={field.charge_speed || ''}
                                        onMenuScrollDown={true}
                                        getDataOnScrollDown={getChargeSpeed}
                                        page={chargeSpeedPage}
                                        totalPage={chargeSpeedTotalPage}
                                        onChange={(e) => setFieldValue(`plugs[${index}].charge_speed`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].charge_speed`) && getIn(touched, `plugs[${index}].charge_speed`)
                                            ? getIn(errors, `plugs[${index}].charge_speed`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label="Name"
                                        name="name"
                                        placeholder="Name"
                                        value={field.name || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].name`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].name`) && getIn(touched, `plugs[${index}].name`)
                                            ? getIn(errors, `plugs[${index}].name`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label="Code"
                                        name="code"
                                        placeholder="Code"
                                        value={field.code || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].code`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].code`) && getIn(touched, `plugs[${index}].code`)
                                            ? getIn(errors, `plugs[${index}].code`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label="Current"
                                        name="current"
                                        placeholder="Current"
                                        value={field.current || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].current`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].current`) && getIn(touched, `plugs[${index}].connector_type`)
                                            ? getIn(errors, `plugs[${index}].current`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label="Rating"
                                        name="rating"
                                        placeholder="Rating"
                                        value={field.rating || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].rating`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].rating`) && getIn(touched, `plugs[${index}].rating`)
                                            ? getIn(errors, `plugs[${index}].rating`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <TextInput
                                        isRequired
                                        label="Number"
                                        type="number"
                                        name="number"
                                        placeholder="Number"
                                        value={field.number || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].number`, e.target.value)}
                                        error={
                                          getIn(errors, `plugs[${index}].number`) && getIn(touched, `plugs[${index}].number`)
                                            ? getIn(errors, `plugs[${index}].number`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label="Format"
                                        options={[
                                          { label: 'SOCKET', value: 'SOCKET' },
                                          { label: 'CABLE', value: 'CABLE' },
                                        ]}
                                        placeholder="Select Format Name"
                                        name="format"
                                        value={field.format || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].format`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].format`) && getIn(touched, `plugs[${index}].format`)
                                            ? getIn(errors, `plugs[${index}].format`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Select
                                        isRequired
                                        label="Power Type"
                                        options={[
                                          { label: 'AC_1_PHASE', value: 'AC_1_PHASE' },
                                          { label: 'AC_3_PHASE', value: 'AC_3_PHASE' },
                                          { label: 'DC', value: 'DC' },
                                        ]}
                                        placeholder="Select Power Type"
                                        name="power_type"
                                        value={field.power_type || ''}
                                        onChange={(e) => setFieldValue(`plugs[${index}].power_type`, e)}
                                        error={
                                          getIn(errors, `plugs[${index}].power_type`) && getIn(touched, `plugs[${index}].power_type`)
                                            ? getIn(errors, `plugs[${index}].power_type`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    {index > 0 && (
                                      <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                        <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                          <BiTrash size={26} />
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              ))}
                              <div>
                                {values.plugs.length < 4 && (
                                  <Button
                                    className="field--btn"
                                    onClick={() => {
                                      arrayHelpers.insert(values.plugs.length, {
                                        type: '',
                                        connector_type: '',
                                        charge_speed: '',
                                        name: '',
                                        code: '',
                                        current: '',
                                        rating: '',
                                        number: '',
                                        format: '',
                                        power_type: '',
                                      });
                                    }}
                                  >
                                    <BiPlus size={22} className="plus--icon" /> Add
                                  </Button>
                                )}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="form_btn__block">
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form__btn">
                        {oemId ? 'Edit' : 'Add'} ChargeSpot Model
                      </Button>
                      <span className="oem-cancel-btn--block">
                        <Button className="oem-cancel--btn" onClick={() => history.push(`/ChargeSpot`)}>
                          Cancel
                        </Button>
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
          {showEditOemModal && (
            <Modal show={showEditOemModal} centered onHide={handleCloseEditOemModel}>
              <EditOemModal subTitle={chargerName} title="chargeSpot model" onClose={handleCloseEditOemModel} editChargeSpotModel={handleClick} />
            </Modal>
          )}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default OemForm;
