import React from 'react';
import TenantAdminList from 'view/tenantAdmin/TenantAdminList';
import ChargerStatus from 'view/chargerStatus';
import { useSelector } from 'react-redux';

const HomePage = () => {
  const role = useSelector((state) => state.profile.userProfile.role);
  return <React.Fragment>{role === 'super_admin' ? <TenantAdminList /> : <ChargerStatus />}</React.Fragment>;
};

export default HomePage;
