import { BOOKING_HISTORY_SUCCESS, BOOKING_HISTORY_FAIL, BOOKING_HISTORY } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  bookingHistories: [],
};

const bookingHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BOOKING_HISTORY: {
      return { ...state, isLoading: true };
    }
    case BOOKING_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        bookingHistories: payload,
      };
    }

    case BOOKING_HISTORY_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default bookingHistoryReducer;
