export const FETCH_ZONES = 'FETCH_ZONES';
export const FETCH_ZONES_SUCCESS = 'FETCH_ZONES_SUCCESS';
export const FETCH_ZONES_FAIL='FETCH_ZONES_FAIL';

export const CREATE_ZONES = 'CREATE_ZONES';
export const CREATE_ZONES_SUCCESS = 'CREATE_ZONES_SUCCESS';
export const CREATE_ZONES_FAIL='CREATE_ZONES_FAIL';

export const DELETE_ZONES = 'DELETE_ZONES';
export const DELETE_ZONES_SUCCESS = 'DELETE_ZONES_SUCCESS';
export const DELETE_ZONES_FAIL='DELETE_ZONES_FAIL';

export const UPDATE_ZONES = 'UPDATE_ZONES';
export const UPDATE_ZONES_SUCCESS= 'UPDATE_ZONES_SUCCESS';
export const UPDATE_ZONES_FAIL='UPDATE_ZONES_FAIL';

export const GET_ZONE = 'GET_ZONE';
export const GET_ZONE_SUCCESS = 'GET_ZONE_SUCCESS';
export const GET_ZONE_FAIL = 'GET_ZONE_FAIL';
