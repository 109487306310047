import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { BsLightningCharge, BsPlug } from 'react-icons/bs';
// import { MdOutlineWatchLater } from 'react-icons/md';
// import { FiPhone } from 'react-icons/fi';
// import { AiOutlineHome, AiOutlineMail } from 'react-icons/ai';
import InformationBlock from 'components/general/InformationBlock';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
// import Button from 'components/inputs/Button';
import ContactPersonForm from './ContactPersonForm';
// import moment from 'moment';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { RiNumbersLine } from 'react-icons/ri';

const General = () => {
  const [showForm, setShowForm] = useState(false);
  const [activeMarker, setActiveMarker] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [center, setCenter] = useState({
    lat: 30.722475,
    lng: 76.6682639,
  });
  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Add your API key
  });

  const loadHandler = (map) => {
    setMapRef(map);
  };

  useEffect(() => {
    loadHandler();
  }, [mapRef, center, chargingStationData]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleCloseFormModel = () => {
    setShowForm(false);
  };

  return (
    <div className="general-details__main">
      <div className="general_layout">
        <div className="general-details--inner">
          <div className="general-details__block">
            <Row>
              <Col md={6} className="general-details--wrapper">
                <InformationBlock
                  infoText={'Total Energy Consumed'}
                  icon={<BsLightningCharge />}
                  infoTitle={`${_.get(chargingStationData, 'energy_consumed', 0) / 1000} kWh`}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="general-details--wrapper">
                <InformationBlock infoText={'Total Charger'} icon={<BsPlug />} infoTitle={`${_.get(chargingStationData, 'total_chargers', 0)}`} />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="general-details--wrapper">
                <InformationBlock
                  infoText={'Number of Sessions'}
                  icon={<RiNumbersLine />}
                  infoTitle={_.get(chargingStationData, 'total_chargers', 0)}
                />
              </Col>
            </Row>
            {/* <Row>
              {
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={'Number of EV Charged'}
                    icon={<AiOutlineCar />}
                    infoTitle={_.get(chargingStationData, 'average_charging_time', 0)}
                  />
                </Col>
              }
            </Row> */}
          </div>
        </div>
        <div className="general-info__main">
          <Row lg={12}>
            {/* <Col xl={6} xs={12} className="general-info__main-wrapper">
            <div className="operational-timing--block">
              <div className="operational-timing-inner">
                <div className="operation-timing-text">
                  <span className="timing-text-inner">Operational Timings</span>
                </div>
                {_.map(chargingStationData.working_hours, (data, index) => {
                  return (
                    <div className="operation-timing__content" key={`station-timing-${index}`}>
                      <div className="operation-timing--icon">
                        <MdOutlineWatchLater />
                      </div>
                      <div className="operation--time">
                        <span className="operation-time-text">{_.startCase(_.get(data, 'day', ''))}</span>
                        <span className="operation-time-icon">
                          <BsDot />
                        </span>
                        <span className="operation-time-text">
                          {moment(_.get(data, 'from', ''), 'HH:mm').format('LT')} - {moment(_.get(data, 'to', ''), 'HH:mm').format('LT')}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="contact-person-details__main--inner">
              <div className="details-heading--block">
                <div className="details-heading">Contact Person</div>
                <Button onClick={() => setShowForm(true)}>Edit Detail</Button>
              </div>
              <div className="contact-info__main">
                <div className="contact-info--block-item">
                  <BsPersonCircle className="contact-info---icon" />
                  <div>
                    <span className="info--label">Name :</span>
                    <span className="info--text">{_.get(chargingStationData, 'contact_person.name', '')}</span>
                  </div>
                </div>
                <div className="contact-info--block-item">
                  <FiPhone className="contact-info---icon" />
                  <div>
                    <span className="info--label">Phone number :</span>
                    <span className="info--text">
                      +{_.get(chargingStationData, 'contact_person.country_code', '')} {_.get(chargingStationData, 'contact_person.phone', '')}
                    </span>
                  </div>
                </div>
                <div className="contact-info--block-item">
                  <AiOutlineMail className="contact-info---icon" />
                  <div>
                    <span className="info--label">Email address :</span>
                    <span className="info--text">{_.get(chargingStationData, 'contact_person.email', '')}</span>
                  </div>
                </div>
                <div className="contact-info--block-item">
                  <AiOutlineHome className="contact-info---icon" />
                  <div>
                    <span className="info--label">Address :</span>
                    <span className="info--text">{_.get(chargingStationData, 'contact_person.address', '')}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
            <Col xl={12} xs={12} className="address__box">
              <div className="address__box--inner">
                <div className="address-heading">Address</div>
                <div className="station-address">
                  {_.get(chargingStationData, 'address', '')}, {_.get(chargingStationData, 'area', '')}, {_.get(chargingStationData, 'city', '')},{' '}
                  {_.get(chargingStationData, 'pincode', '')}, {_.get(chargingStationData, 'state', '')}, {_.get(chargingStationData, 'country', '')}
                </div>
                <div className="station-map-img">
                  {isLoaded && (
                    <GoogleMap
                      onLoad={loadHandler}
                      center={_.get(chargingStationData, 'location') ? _.get(chargingStationData, 'location') : center}
                      zoom={15}
                      onCenterChanged={() => mapRef && setCenter(_.get(mapRef, 'getCenter()').toJSON())}
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                    >
                      <Marker
                        key={_.get(chargingStationData, 'id', '')}
                        position={_.get(chargingStationData, 'location')}
                        onClick={handleActiveMarker}
                      >
                        {activeMarker ? (
                          <InfoWindow onCloseClick={() => setActiveMarker(false)}>
                            <div className="station-marker-text">{_.get(chargingStationData, 'name', '')}</div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    </GoogleMap>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {showForm && (
        <Modal show={showForm} size="lg" centered>
          <ContactPersonForm onClose={handleCloseFormModel} />
        </Modal>
      )}
    </div>
  );
};

export default General;
