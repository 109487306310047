import { WALLET_TRANSACTION_REPORT_SUCCESS, WALLET_TRANSACTION_REPORT_FAIL, WALLET_TRANSACTION_REPORT } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  walletTransactionReports: [],
};

const walletTransactionReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case WALLET_TRANSACTION_REPORT: {
      return { ...state, isLoading: true };
    }

    case WALLET_TRANSACTION_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        walletTransactionReports: payload,
      };
    }

    case WALLET_TRANSACTION_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default walletTransactionReportReducer;
