import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { FETCH_VEHICLE_MODEL, GET_VEHICLE_MODEL, DELETE_VEHICLE_MODEL } from 'actions/vehicleModel';
import ModelForm from './ModelForm';
import Avatar from 'components/inputs/Avatar';
import { startsWith } from 'components/common/utils';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';

const ModelList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [allModelData, setAllModelData] = useState([]);
  const [modelId, setModelId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseModelData, setPageWiseModelData] = useState([]);

  const page = useSelector((state) => state.vehicleModel.page);
  const totalData = useSelector((state) => state.vehicleModel.total);
  const limit = useSelector((state) => state.vehicleModel.limit);
  const isLoader = useSelector((state) => state.vehicleModel.isLoading);
  const modelList = useSelector((state) => state.vehicleModel.vehicleModels);
  const totalPages = useSelector((state) => state.vehicleModel.totalPages);
  const modelData = modelList.filter(({ id: id1 }) => pageWiseModelData.some(({ id: id2 }) => id2 === id1));

  const getModel = useCallback(
    (data = {}) => {
      dispatch({
        type: FETCH_VEHICLE_MODEL,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseModelData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseModelData]
  );

  const getModelDetail = useCallback((id) => {
    dispatch({ type: GET_VEHICLE_MODEL, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setModelId('');
  };

  const deleteModel = useCallback(
    () =>
      dispatch({
        type: DELETE_VEHICLE_MODEL,
        payload: modelId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getModel({ page });
          }
        },
      }),
    [modelId]
  );

  useEffect(() => {
    getModel();
  }, []);

  useEffect(() => {
    setAllModelData(
      _.filter(
        modelData,
        (item) =>
          startsWith(_.get(item, 'model', ''), searchText) ||
          startsWith(_.get(item, 'makeId.name', ''), searchText) ||
          startsWith(_.get(item, 'year', ''), searchText) ||
          startsWith(_.get(item, 'max_charging_capacity', ''), searchText)
      )
    );
  }, [searchText, modelList, pageWiseModelData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getModel(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getModel(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getModel(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };
  const handleCloseFormModel = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="EV Model" />
        <div className="page-content-wrapper scrollable">
          <div className="modelList-page">
            <Card>
              <div className="modelList-page-wrapper">
                <Row className="modelList-search-box">
                  <Col xl={10} lg={9} md={8} className="modelList-search-inputBox">
                    <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="modelList-search-boxButton">
                    <Button
                      className="modelList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      Add EV Model
                    </Button>
                  </Col>
                </Row>
                <div className="modelList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>EV Model Image</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>EV Model Name</span>
                              <span className="ico" onClick={() => handleSorting('model', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>EV Make Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Year</span>
                              <span className="ico" onClick={() => handleSorting('year', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Battery Capacity</span>
                              <span className="ico" onClick={() => handleSorting('max_charging_capacity', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Variant</span>
                              <span className="ico" onClick={() => handleSorting('variant', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Action</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                       {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(allModelData) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">No EV Model Data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(allModelData, (item, key) => {
                            return (
                              <tr key={`ev-model-${key}`}>
                                <td>
                                  <Avatar imgSrc={_.get(item, 'picture', '')} className="Model_logoList" />
                                </td>
                                <td>{_.get(item, 'model', '')}</td>
                                <td>{_.get(item, 'makeId.name', '')}</td>
                                <td>{_.get(item, 'year', '')}</td>
                                <td>{_.get(item, 'max_charging_capacity', '')} kWh</td>
                                <td>{_.get(item, 'variant', '-')}</td>
                                <td>
                                  <span
                                    className="modelList-table-editIcon"
                                    onClick={() => {
                                      setShowForm(true);
                                      getModelDetail(_.get(item, 'id', ''));
                                      setIsEdit(true);
                                    }}
                                  >
                                    <FiEdit2 />
                                  </span>
                                  <span
                                    className="modelList-table-deleteIcon"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setModelId(_.get(item, 'id', ''));
                                    }}
                                  >
                                    <RiDeleteBinLine />
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allModelData) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <ModelForm onClose={handleCloseFormModel} isEdit={isEdit} onSuccess={getModel} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="EV Model" onClose={handleCloseDeleteModel} onRemove={deleteModel} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default ModelList;
