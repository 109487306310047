import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { DATA_TRANSFER_SERVER } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const DataTransferServerSchema = Yup.object().shape({
  vendorId: Yup.string()
    .required('Vendor id is required!')
    .trim('Space is not allowed')
    .strict(true)
    .max(100, 'Text limit should be less than 100 characters.'),
  messageId: Yup.string()
    .required('Message id is required!')
    .trim('Space is not allowed')
    .strict(true)
    .max(100, 'Text limit should be less than 100 characters.'),
  data: Yup.string()
    .required('Data is required!')
    .trim('Space is not allowed')
    .strict(true)
    .max(100, 'Text limit should be less than 100 characters.'),
});

const DataTransferServerForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const getDataTransferServer = useCallback((data, resetForm) => {
    dispatch({
      type: DATA_TRANSFER_SERVER,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Data transfer server successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const initialValues = {
    vendorId: '',
    messageId: '',
    data: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">DATA TRANSFER SERVER</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={DataTransferServerSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  ...values,
                  id: chargerId,
                };
                getDataTransferServer(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label="Vendor Id"
                          placeholder="Vendor id"
                          value={values.vendorId}
                          name="vendorId"
                          onChange={handleChange}
                          error={errors.vendorId && touched.vendorId ? errors.vendorId : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label="Message Id"
                          placeholder="Message ID"
                          value={values.messageId}
                          name="messageId"
                          onChange={handleChange}
                          error={errors.messageId && touched.messageId ? errors.messageId : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label="Data"
                          placeholder="Data"
                          type="name"
                          value={values.data}
                          name="data"
                          onChange={handleChange}
                          error={errors.data && touched.data ? errors.data : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Data Transfer Server
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default DataTransferServerForm;
