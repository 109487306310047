import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
// import DummyImage from 'assets/images/icons/dummyImage.png';
// import Avatar from 'components/inputs/Avatar';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
// import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_TENANT, FETCH_TENANT, UPDATE_TENANT } from 'actions/tenant';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { Modal } from 'react-bootstrap';
import { DELETE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FaLock, FaUnlockAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { TENANT_ADMIN_LOGIN } from 'actions/authAction';
import { BiSort } from 'react-icons/bi';

const TenantAdminList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allTenantAdminData, setAllTenantAdminData] = useState([]);
  const [tenantAdminId, setTenantAdminId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const tenantAdminList = useSelector((state) => state.tenant.tenants);
  const isLoader = useSelector((state) => state.tenant.isLoading);
  const page = useSelector((state) => state.tenant.page);
  const totalData = useSelector((state) => state.tenant.total);
  const limit = useSelector((state) => state.tenant.limit);
  const totalPages = useSelector((state) => state.tenant.totalPages);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setTenantAdminId('');
  };

  const superAdminLogin = useCallback((id) => {
    const tenantData = {
      tenant: id,
    };
    dispatch({ type: TENANT_ADMIN_LOGIN, payload: tenantData });
  }, []);

  const deleteTenant = useCallback(
    () =>
      dispatch({
        type: DELETE_TENANT,
        payload: tenantAdminId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getTenant({ page });
          }
        },
      }),
    [tenantAdminId]
  );

  const getTenant = useCallback(
    (data = {}) => {
      if (searchText) {
        const tenantData = {
          ...data,
          name: searchText,
        };
        dispatch({ type: FETCH_TENANT, payload: tenantData });
      } else {
        dispatch({ type: FETCH_TENANT, payload: data });
      }
    },
    [searchText]
  );

  const editLockTenant = useCallback((id) => {
    const data = {
      id: id,
      is_active: 'false',
    };
    dispatch({
      type: UPDATE_TENANT,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Tenant is not active');
          }
        }
      },
    });
  }, []);

  const editUnLockTenant = useCallback((id) => {
    const data = {
      id: id,
      is_active: 'true',
    };
    dispatch({
      type: UPDATE_TENANT,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Tenant is active');
          }
        }
      },
    });
  }, []);

  useEffect(() => {
    getTenant();
  }, [searchText]);

  // useEffect(() => {
  //   setAllTenantAdminData(
  //     _.filter(
  //       tenantAdminList,
  //       (item) =>
  //         startsWith(_.get(item, 'name', ''), searchText) ||
  //         startsWith(_.get(item, 'chargers').toString(), searchText) ||
  //         startsWith(_.get(item, 'charging_stations').toString(), searchText) ||
  //         startsWith(_.get(item, 'user_count').toString(), searchText)
  //     )
  //   );
  // }, [searchText, tenantAdminList]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getTenant(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getTenant(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getTenant(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Tenant Management" />
        <div className="page-content-wrapper scrollable">
          <div className="tenantAdminList-page">
            <Card>
              <div className="tenantAdminList-page-wrapper">
                <Row className="tenantAdminList-search-box">
                  <Col xl={10} lg={9} md={8} className="tenantAdminList-search-inputBox">
                    <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="tenantAdminList-search-boxButton">
                    <Button className="tenantAdminList-search-box-Btn" onClick={() => history.push('/addTenant')}>
                      Add Tenant
                    </Button>
                  </Col>
                </Row>
                <div className="tenantAdminList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Company</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>No Of Stations</span>
                              <span className="ico" onClick={() => handleSorting('charging_stations', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>No Of Chargespots</span>
                              <span className="ico" onClick={() => handleSorting('chargers', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Maximum User</span>
                              <span className="ico" onClick={() => handleSorting('max_user', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>No Of Users</span>
                              <span className="ico" onClick={() => handleSorting('user_count', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Action</span>
                            </div>
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(tenantAdminList) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">No Tenant Data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(tenantAdminList, (item, key) => {
                            return (
                              <tr key={key}>
                                <td>{_.get(item, 'name', 'NA')}</td>
                                <td>{_.get(item, 'charging_stations', '0')}</td>
                                <td>{_.get(item, 'chargers', '0')}</td>
                                <td>{_.get(item, 'max_user', '0')}</td>
                                <td>{_.get(item, 'user_count', '0')}</td>
                                {/* <td>
                                    <div className="tenantAdminList-nameList">
                                      <Avatar imgSrc={DummyImage} className="tenantAdminList-avatarList" name={''} />
                                      <div className="user-info--name" onClick={() => history.push(`/tenantAdmin/${_.get(item, 'id', '')}`)}>
                                        <span>{_.get(item, 'contact_person.name', '')}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{_.get(item, 'contact_person.email', '')}</td>
                                  <td>{_.get(item, 'contact_person.phone', '')}</td> */}
                                <td>
                                  {_.get(item, 'is_active') ? (
                                    <span className="tenantAdminList-lock-unlock-icon" onClick={() => editLockTenant(item.id)}>
                                      <FaUnlockAlt size={22} />
                                    </span>
                                  ) : (
                                    <span className="tenantAdminList-lock-unlock-icon" onClick={() => editUnLockTenant(item.id)}>
                                      <FaLock size={22} />
                                    </span>
                                  )}
                                  <span className="tenantAdminList-table-editIcon" onClick={() => history.push(`/editTenant/${item.id}`)}>
                                    <FiEdit2 />
                                  </span>
                                  <span
                                    className="tenantAdminList-table-deleteIcon"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setTenantAdminId(_.get(item, 'id', ''));
                                    }}
                                  >
                                    <RiDeleteBinLine size={23} />
                                  </span>
                                </td>
                                <td>
                                  {_.get(item, 'allow_remote_access') === true ? (
                                    <Button
                                      className="tenantAdminList-search-box-Btn"
                                      onClick={() => superAdminLogin(_.get(item, 'contact_person.id', ''))}
                                    >
                                      Tenant Login
                                    </Button>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(tenantAdminList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="tenant admin" onClose={handleCloseDeleteModel} onRemove={() => deleteTenant(tenantAdminId)} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default TenantAdminList;
