import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_VEHICLE_MODEL, UPDATE_VEHICLE_MODEL } from 'actions/vehicleModel';
import Select from 'components/inputs/Select';
import { FETCH_VEHICLE_MAKE } from 'actions/vehicleMake';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';

const ModelSchema = Yup.object().shape({
  makeId: Yup.string().required('Select EV Make'),
  type: Yup.string().required('Vehicle type is required'),
  model: Yup.string()
    .required('EV Model name is required')
    .strict(true)
    .trim('Space is not allowed')
    .matches(/^[A-Za-z0-9\s]+$/, 'Only alphabets and numbers are allowed')
    .max(100, 'Text limit should be less than 100 characters.'),
  year: Yup.string()
    .required('Year is required')
    .strict(true)
    .trim('Space is not allowed')
    .matches(/^[0-9]+$/, 'Only numbers are allowed')
    .min(4, 'Year should be in YYYY format')
    .max(4, 'Year should be in YYYY format'),
  // vin_num: Yup.string()
  //   .required('VIN number is required')
  //   .strict(true)
  //   .trim('Space is not allowed')
  //   .matches(/^[A-Za-z0-9]+$/, 'Only alphabets and numbers are allowed'),
  max_charging_capacity: Yup.string()
    .required('Battery capacity is required')
    .strict(true)
    .trim('Space is not allowed')
    .matches(/^[0-9.]+$/, 'Only numbers are allowed'),
});

const ModelForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const token = cookie.get('token');
  const modelDetail = useSelector((state) => state.vehicleModel.vehicleModelDetail);
  const makeDetail = useSelector((state) => state.vehicleMake.vehicleMakes);
  const makePage = useSelector((state) => state.vehicleMake.page);
  const makeTotalPage = useSelector((state) => state.vehicleMake.totalPages);
  const isLoading = useSelector((state) => state.vehicleModel.isLoading);

  const addVehicleModel = useCallback((data) => {
    dispatch({
      type: CREATE_VEHICLE_MODEL,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateVehicleModel = useCallback((data) => {
    dispatch({
      type: UPDATE_VEHICLE_MODEL,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllVehicleMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MAKE, payload: data });
  }, []);

  useEffect(() => {
    getAllVehicleMake();
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const initialValues = isEdit
    ? { ...modelDetail, type: _.get(modelDetail, 'makeId.type', ''), makeId: _.get(modelDetail, 'makeId.id', '') }
    : {
        makeId: '',
        model: '',
        type: '',
        year: '',
        variant: '',
        // vin_num: '',
        max_charging_capacity: '',
        picture: '',
      };
  return (
    <React.Fragment>
      <div className="model-form-page__main">
        <Card>
          <div className="model-form-header__block">
            <div className="model-header-name">
              <span>{isEdit ? 'Edit' : 'Add'} EV Model</span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="model-form-body__block">
            <div className="model-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={ModelSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateVehicleModel(values);
                  } else {
                    addVehicleModel(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12} md={12}>
                        <Select
                          isRequired
                          label="Vehicle Type"
                          placeholder="Select vehicle type"
                          options={[
                            { label: 'Bus', value: 'bus' },
                            { label: 'Car', value: 'car' },
                            { label: 'Two wheeler', value: 'two wheeler' },
                            { label: 'Three wheeler', value: 'three wheeler' },
                            { label: 'Other', value: 'other' },
                          ]}
                          name="type"
                          // value={values.type}
                          value={_.get(values, 'type', '')}
                          onChange={(val) => setFieldValue('type', val)}
                          error={errors.type && touched.type ? errors.type : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <Select
                          isRequired
                          label="EV Make"
                          options={
                            values.type &&
                            _.map(
                              _.filter(makeDetail, (data) => data.type === values.type),
                              (item) => {
                                return { label: item.name, value: item.id };
                              }
                            )
                          }
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllVehicleMake}
                          page={makePage}
                          totalPage={makeTotalPage}
                          placeholder="Select EV make"
                          value={_.get(values, 'makeId', '')}
                          name="makeId"
                          onChange={(val) => setFieldValue(`makeId`, val)}
                          error={errors.makeId && touched.makeId ? errors.makeId : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <TextInput
                          isRequired
                          label="EV Model Name"
                          placeholder="EV Model name"
                          name="model"
                          value={_.get(values, 'model', '')}
                          onChange={handleChange}
                          error={errors.model && touched.model ? errors.model : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <TextInput
                          // isRequired
                          label="Variant"
                          placeholder="Variant"
                          name="variant"
                          value={_.get(values, 'variant', '')}
                          onChange={handleChange}
                        />
                      </Col>
                      <Row>
                        {isEdit ? (
                          <Col lg={12} md={12}>
                            <TextInput
                              type="file"
                              label="Add EV Model Picture"
                              name="picture"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'picture', setFieldValue)}
                              image={values.picture}
                            />
                          </Col>
                        ) : (
                          <Col lg={12} md={12}>
                            <TextInput
                              type="file"
                              label="Add EV Model Picture"
                              name="picture"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'picture', setFieldValue)}
                            />
                          </Col>
                        )}
                      </Row>
                      <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          label="Year"
                          placeholder="YYYY"
                          name="year"
                          value={values.year}
                          onChange={handleChange}
                          error={errors.year && touched.year ? errors.year : null}
                        />
                      </Col>
                      {/* <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          label="VIN number"
                          placeholder="VIN number"
                          name="vin_num"
                          value={values.vin_num}
                          onChange={handleChange}
                          error={errors.vin_num && touched.vin_num ? errors.vin_num : null}
                        />
                      </Col> */}
                      <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          post_text="kWh"
                          label="Battery Capacity"
                          placeholder="Battery capacity"
                          name="max_charging_capacity"
                          value={values.max_charging_capacity}
                          onChange={handleChange}
                          error={errors.max_charging_capacity && touched.max_charging_capacity ? errors.max_charging_capacity : null}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn--block">
                      <Button className="form--btn cancel_btn" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form--btn">
                        {isEdit ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ModelForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ModelForm;
