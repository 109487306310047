import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Col, Row } from 'react-bootstrap';
import {
  BsEye,
  BsFillFileEarmarkZipFill,
  BsSearch,
  // BsThreeDots,
  BsUpload,
} from 'react-icons/bs';
import { RiDeleteBin6Line, RiFileExcel2Line } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
// import DatePicker from 'components/inputs/DatePicker';
import * as _ from 'lodash';
import Avatar from 'components/inputs/Avatar';
import DummyImage from 'assets/images/icons/dummyImage.png';
import SearchBox from 'components/general/SearchBox';
import PropTypes from 'prop-types';
import { FETCH_CUSTOMER, WALLET_TRANSACTION } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
// import { startsWith } from 'components/common/utils';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import Button from 'components/inputs/Button';
// import { RiDeleteBinLine } from 'react-icons/ri';
// import { FiEdit2 } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import CustomerForm from './CustomerForm';
// import moment from 'moment';
import { AiOutlineFile, AiOutlineFileJpg, AiOutlineFilePdf } from 'react-icons/ai';
import { FcVideoFile } from 'react-icons/fc';
import { FaRegFileAudio } from 'react-icons/fa';
// import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { cookie } from 'utils/cookies/cookies';
import { MdClose } from 'react-icons/md';
import { Form, Formik } from 'formik';
import TextInput from 'components/inputs/Input';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import { toast } from 'react-toastify';
import { CREATE_STATUS_CODE } from 'components/common/constant';

const WalletTransactionSchema = Yup.object().shape({
  message: Yup.string().required('Message is Required'),
  type: Yup.string().required('Type is required'),
  amount: Yup.number().required('Amount is required'),
});

const CustomerList = () => {
  // const [startDate, setStartDate] = useState(moment().startOf('month'));
  // const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const currentUserRole = cookie.get('user-role');

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  // const [allCustomerData, setAllCustomerData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState([]);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showWalletForm, setShowWalletForm] = useState(false);
  const [customerList, setCustomerList] = useState({});

  const customers = useSelector((state) => state.customer.customers);
  const page = useSelector((state) => state.customer.page);
  const totalData = useSelector((state) => state.customer.total);
  const limit = useSelector((state) => state.customer.limit);
  const totalPages = useSelector((state) => state.customer.totalPages);

  const getCustomer = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_CUSTOMER, payload: customerData });
      } else {
        dispatch({ type: FETCH_CUSTOMER, payload: data });
      }
    },
    [searchText]
  );

  const walletTransaction = useCallback(
    (data = {}) => {
      dispatch({
        type: WALLET_TRANSACTION,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === CREATE_STATUS_CODE) {
              toast.success('Transaction in wallet successfully');
              setShowWalletForm(false);
              getCustomer({ page });
            }
          }
        },
      });
    },
    [page]
  );

  function showFile() {
    const file = document.getElementById('file');
    setFile((prev) => [...prev, file.files.item(0)]);
  }

  const onRemoveFile = (removedFile) => {
    const newFiles = file.filter((file) => file.name !== removedFile);
    setFile(newFiles);
  };

  // const getCustomerDetail = useCallback((id) => {
  //   dispatch({ type: GET_CUSTOMER, payload: id });
  // }, []);
  //
  // const deleteCustomer = useCallback((id) => dispatch({ type: DELETE_CUSTOMER, payload: id }), []);

  useEffect(() => {
    getCustomer();
  }, [searchText]);

  // useEffect(() => {
  //   setAllCustomerData(
  //     _.filter(customers, (item) => startsWith(_.get(item, 'name', ''), searchText) || startsWith(_.get(item, 'email', ''), searchText))
  //   );
  // }, [searchText, customers]);

  // useEffect(() => {
  //   getCustomer();
  // }, []);

  // const getSortByItem = (name, order, page, filterDateData) => {
  //   const sortedOrder = order || 'asc';
  //   if (page) {
  //     const data = {
  //       from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //       to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //       page: page.selected + 1,
  //     };
  //     endDate && getCustomer(data);
  //   } else if (page) {
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //       page: page.selected + 1,
  //     };
  //     getCustomer(data);
  //   } else if (filterDateData) {
  //     const data = {
  //       ...filterDateData,
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //     };
  //     endDate && getCustomer(data);
  //   } else {
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
  //     };
  //     getCustomer(data);
  //   }
  // };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCustomer(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCustomer(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  // const handlePageClick = (page) => {
  //   if (sortByItem.item && sortByItem.order) {
  //     const filterDateData = {
  //       from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //       to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //     };
  //     getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
  //   } else if (startDate && endDate) {
  //     const data = {
  //       from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //       to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //       page: page.selected + 1,
  //     };
  //     getCustomer(data);
  //   } else {
  //     const data = {
  //       page: page.selected + 1,
  //     };
  //     getCustomer(data);
  //   }
  // };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getCustomer(data);
      }
    },
    [sortByItem]
  );

  const handleCloseFormCustomer = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  const handleClose = () => {
    setShowWalletForm(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Customer Management" />
        <div className="page-content-wrapper scrollable">
          <div className="customer-management-page__main">
            <div className="customer-details--block">
              <Card>
                <div className="customer-details-search--block">
                  <Row>
                    <Col xl={`${currentUserRole === 'super_admin' ? 12 : 10}`} md={`${currentUserRole === 'super_admin' ? 12 : 8}`}>
                      <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    </Col>
                    {/* <Col xl={6} md={10} className="date-picker">
                      <DateTimePicker
                        onChangeOfStartDate={(item) => setStartDate(item)}
                        onChangeOfEndDate={(item) => setEndDate(item)}
                        initialValueOfStartDate={moment().startOf('month')}
                        initialValueOfEndDate={moment(new Date()).endOf('day')}
                      />
                    </Col> */}
                    <Col xl={2} md={4} className="customerList-add-boxButton">
                      {currentUserRole !== 'super_admin' && (
                        <Button
                          className="customerList-add-box-Btn"
                          onClick={() => {
                            setShowForm(true);
                            setIsEdit(false);
                          }}
                        >
                          Add Customer
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="customer-details-inner">
                  <Row className="customer-details-block">
                    <Col xl={4} md={6} className="customer-details-wrapper">
                      <CustomerInfoBlock icon={<FiUsers color="#3C7CDD" />} title={totalData} subtitle={'Total Customer'} />
                    </Col>
                    <Col xl={4} md={6} className="customer-details-wrapper">
                      <CustomerInfoBlock icon={<FiUsers color="#3C7CDD" />} title={0} subtitle={'New Customer'} />
                    </Col>
                    <Col xl={4} md={6} className="customer-details-wrapper">
                      <CustomerInfoBlock icon={<FiUsers color="#3C7CDD" />} title={0} subtitle={'Customer using chargers'} />
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
            <div className="new-customer--list">
              <Card>
                <div className="customer-text">Customer Details</div>
                <div className="customer-list-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Customer Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Email ID</span>
                              <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Phone No.</span>
                              <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          {/*<th>*/}
                          {/*  <div className="sorting">*/}
                          {/*    <span>Charging Station</span>*/}
                          {/*  </div>*/}
                          {/*</th>*/}
                          {/*<th>*/}
                          {/*  <div className="sorting">*/}
                          {/*    <span>Vehicle</span>*/}
                          {/*  </div>*/}
                          {/*</th>*/}
                          <th className="action-col">
                            <span>Action</span>
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {searchText && _.isEmpty(customers) ? (
                          <tr>
                            <td colSpan={4} className="border-0">
                              <div className="empty-data-block">No customer</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(customers, (item, key) => {
                            return (
                              <tr key={`customer-${key}`}>
                                <td>
                                  <div className="customer-name-list">
                                    <Avatar imgSrc={DummyImage} className="customer-avatar" name={''} />
                                    <span>{_.get(item, 'name', 'Guest User')}</span>
                                  </div>
                                </td>
                                <td>{_.get(item, 'email', '')}</td>
                                <td>
                                  +{_.get(item, 'country_code', '')}
                                  {_.get(item, 'phone', 'NA')}
                                </td>
                                {/*<td className="data-list--cell">*/}
                                {/*  <div className="list-items">*/}
                                {/*    <p>*/}
                                {/*      {_.has(item, 'favorites') && !_.isEmpty(item.favorites)*/}
                                {/*        ? _.join(*/}
                                {/*            _.map(item.favorites, (item) => _.get(item, 'name', '')),*/}
                                {/*            ', '*/}
                                {/*          )*/}
                                {/*        : '-'}*/}
                                {/*    </p>*/}
                                {/*  </div>*/}
                                {/*</td>*/}
                                {/*<td className="data-list--cell">*/}
                                {/*  <div className="list-items">*/}
                                {/*    <p>*/}
                                {/*      {_.has(item, 'vehicles') && !_.isEmpty(item.vehicles)*/}
                                {/*        ? _.join(*/}
                                {/*            _.map(item.vehicles, (item) => `${_.get(item, 'make', '')}(${_.get(item, 'model', '')})`),*/}
                                {/*            ', '*/}
                                {/*          )*/}
                                {/*        : '-'}*/}
                                {/*    </p>*/}
                                {/*  </div>*/}
                                {/*</td>*/}
                                <td>
                                  <span className="customer-list-icon">
                                    <BsEye />
                                  </span>
                                </td>
                                <td>
                                  {currentUserRole === 'super_admin' && (
                                    <Button
                                      onClick={() => {
                                        setShowWalletForm(true);
                                        setCustomerList(item);
                                      }}
                                    >
                                      Do transaction
                                    </Button>
                                  )}
                                </td>
                                {/*<td className="">*/}
                                {/*  <span*/}
                                {/*    className="customerList-table-editIcon"*/}
                                {/*    onClick={() => {*/}
                                {/*      setShowForm(true);*/}
                                {/*      getCustomerDetail(_.get(item, 'id', ''));*/}
                                {/*      setIsEdit(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    <FiEdit2 />*/}
                                {/*  </span>*/}
                                {/*  <span className="customerList-table-deleteIcon" onClick={() => deleteCustomer(_.get(item, 'id', ''))}>*/}
                                {/*    <RiDeleteBinLine />*/}
                                {/*  </span>*/}
                                {/*</td>*/}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!_.isEmpty(customers) && (
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </Card>
            </div>
            <div className="users-group-list--block">
              <Card>
                <div className="user-group-inner">
                  <div className="user-group-text">User Group</div>
                  <div className="upload-file-block">
                    <input onChange={showFile} type="file" id="file" className="upload-file--input" />
                    <label className="upload-file-inner" htmlFor="file">
                      <BsUpload color="#e12f17" />
                      <span className="upload-file-text">Upload</span>
                    </label>
                  </div>
                </div>
                <div className="users-group-list">
                  {file.length > 0 ? (
                    _.map(file, (item) => {
                      return (
                        <>
                          {item['type'] === 'image/jpeg' ? (
                            <UploadedUserList
                              fileIcon={<AiOutlineFileJpg size={20} />}
                              fileName={item.name}
                              removeFile={() => onRemoveFile(item.name)}
                            />
                          ) : item['type'] === 'video/mp4' ? (
                            <UploadedUserList fileIcon={<FcVideoFile size={20} />} fileName={item.name} removeFile={() => onRemoveFile(item.name)} />
                          ) : item['type'] === 'application/zip' ? (
                            <UploadedUserList
                              fileIcon={<BsFillFileEarmarkZipFill />}
                              fileName={item.name}
                              removeFile={() => onRemoveFile(item.name)}
                            />
                          ) : item['type'] === 'audio/mpeg' ? (
                            <UploadedUserList
                              fileIcon={<FaRegFileAudio size={20} />}
                              fileName={item.name}
                              removeFile={() => onRemoveFile(item.name)}
                            />
                          ) : item['type'] === 'application/pdf' ? (
                            <UploadedUserList
                              fileIcon={<AiOutlineFilePdf size={20} />}
                              fileName={item.name}
                              removeFile={() => onRemoveFile(item.name)}
                            />
                          ) : item['type'] === ('application/vnd.ms-excel' || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? (
                            <UploadedUserList
                              fileIcon={<RiFileExcel2Line size={20} />}
                              fileName={item.name}
                              removeFile={() => onRemoveFile(item.name)}
                            />
                          ) : (
                            <UploadedUserList
                              fileIcon={<AiOutlineFile size={20} />}
                              fileName={item.name}
                              removeFile={() => onRemoveFile(item.name)}
                            />
                          )}
                        </>
                      );
                    })
                  ) : (
                    <div className="empty-data-block">No Files</div>
                  )}
                  {/* <UploadedUserList fileIcon={<RiFileExcel2Line />} fileName={'User_Group2.xls'} /> */}
                </div>
              </Card>
            </div>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="xl" centered>
            <CustomerForm onClose={handleCloseFormCustomer} isEdit={isEdit} />
          </Modal>
        )}
        {showWalletForm && (
          <Modal show={showWalletForm} size="lg" centered>
            <div className="wallet-transaction_main">
              <Card>
                <div className="wallet-transaction-header__block">
                  <div className="wallet-transaction-name">
                    <span>Wallet Transaction For Customer</span>
                  </div>
                  <div className="close-btn">
                    <MdClose size={30} color="#be210b" onClick={handleClose} />
                  </div>
                </div>
                <div className="wallet-transaction_form">
                  <Formik
                    initialValues={{
                      amount: '',
                      user: _.get(customerList, 'id', ''),
                      type: '',
                      message: '',
                    }}
                    validationSchema={WalletTransactionSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      walletTransaction(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, setFieldValue, handleSubmit, handleChange, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <TextInput
                              isRequired
                              label="Amount"
                              type="number"
                              placeholder="Amount"
                              name="amount"
                              value={_.get(values, 'amount')}
                              onChange={handleChange}
                              error={errors.amount && touched.amount ? errors.amount : null}
                            />
                          </Col>
                          <Col lg={6}>
                            <Select
                              isRequired
                              label="Type"
                              options={[
                                { label: 'Credit', value: 'credit' },
                                { label: 'Debit', value: 'debit' },
                              ]}
                              placeholder="Select Type"
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue(`type`, val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col>
                          <Col lg={12}>
                            <TextInput
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              placeholder="Message"
                              label="Message"
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              error={errors.message && touched.message ? errors.message : null}
                            />
                          </Col>
                        </Row>
                        <div className="wallet-transaction_button">
                          <Button className="wallet-transaction-btn cancel_btn" onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button type="submit" disabled={isSubmitting} className="wallet-transaction-btn create-btn">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default CustomerList;

const CustomerInfoBlock = (props) => {
  const { icon, title, subtitle } = props;
  return (
    <div className="customer-info--box">
      <div className="customer-info-icon">{icon}</div>
      <div className="customer-info--wrapper">
        <div className="total-customer">{title}</div>
        <div className="info-text">{subtitle}</div>
      </div>
    </div>
  );
};
CustomerInfoBlock.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.number,
  subtitle: PropTypes.string,
};

const UploadedUserList = (props) => {
  const { fileIcon, fileName, removeFile } = props;
  return (
    <div className="users-group-list-wrapper">
      <div className="user-group--name">
        <span>{fileIcon}</span>
        <span className="list-text">{fileName}</span>
      </div>
      <div className="users-group-list-icon" onClick={removeFile}>
        <RiDeleteBin6Line />
      </div>
    </div>
  );
};
UploadedUserList.propTypes = {
  fileIcon: PropTypes.object,
  fileName: PropTypes.string,
  removeFile: PropTypes.func,
};
