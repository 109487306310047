import React, { useState, useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form, getIn, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
// import { FiEdit2 } from 'react-icons/fi';
import { BiPlus, BiTrash } from 'react-icons/bi';
// import { RiDeleteBinLine } from 'react-icons/ri';
// import Pagination from 'components/general/Pagination';
import SearchBox from 'components/general/SearchBox';
import Select from 'components/inputs/Select';
import SessionHeading from 'components/general/SessionHeading';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SEND_LOCAL_LIST } from 'actions/chargerOcpp';
import { FETCH_CHARGER_LOG } from 'actions/activityLog';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { AiOutlineCar } from 'react-icons/ai';

const SendLocalSchema = Yup.object().shape({
  listVersion: Yup.number().required('List version is required!'),
  updateType: Yup.string().required('Update type is required!').max(100, 'Text limit should be less than 100 characters.'),
  localAuthorizationList: Yup.array().of(
    Yup.object().shape({
      idTag: Yup.string().required('Id tag is required').strict(true).trim('Space is not allowed'),
      expiryDate: Yup.string().required('Expiry date is required!'),
      parentIdTag: Yup.string().required('Parent id tag is required!'),
      status: Yup.string().required('status is required!'),
    })
  ),
});

const SendLocalListForm = () => {
  const [searchText, setSearchText] = useState('');
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState();
  const [localAuthorizationData, setLocalAuthorizationData] = useState([]);

  const allSendLocalListData = useSelector((state) => state.activityLog.activityLogs);
  const page = useSelector((state) => state.activityLog.page);
  // const totalData = useSelector((state) => state.activityLog.total);
  const limit = useSelector((state) => state.activityLog.limit);
  let serialNum = limit * (page - 1);

  const getAllChargerLog = useCallback((data = {}) => {
    const chargerData = {
      charger: chargerId,
      from: 'server',
      type: 'SendLocalList',
      limit: 1,
      ...data,
    };
    dispatch({ type: FETCH_CHARGER_LOG, payload: chargerData });
  }, []);

  const getSendLocalList = useCallback((data, resetForm) => {
    dispatch({
      type: SEND_LOCAL_LIST,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Send local list successfully');
            setIsLoader(false);
            resetForm();
            setTimeout(() => {
              getAllChargerLog();
            }, 1000);
          }
        }
      },
    });
  }, []);

  // const handlePageClick = (page) => {
  //   const data = {
  //     page: page,
  //   };
  //   getAllChargerLog(data);
  // };

  const localAuthorizationList = !_.isEmpty(localAuthorizationData)
    ? { localAuthorizationList: localAuthorizationData }
    : {
        localAuthorizationList: [
          {
            idTag: '',
            expiryDate: '',
            parentIdTag: '',
            status: '',
          },
        ],
      };

  const initialValues = {
    listVersion: '',
    // localAuthorizationList: [
    //   {
    //     idTag: '',
    //     idTagInfo: {
    //       expiryDate: '',
    //       parentIdTag: '',
    //       status: '',
    //     },
    //   },
    // ],
    ...localAuthorizationList,
    updateType: '',
  };

  const Upload = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('fileUpload');
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5');
      }
    } else {
      toast.error('Please upload a valid Excel file');
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    //getting header of excel sheet
    const header = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 })[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    if (JSON.stringify(header) === '["idTag","expiryDate","parentIdTag","status"]') {
      _.map(excelRows, (data) => {
        data.idTag = data.idTag.toString();
        data.parentIdTag = data.parentIdTag.toString();
        data.status = data.status.toString();
        data.expiryDate = moment((data.expiryDate - 25569) * 86400000).format('YYYY-MM-DD');
      });
      setLocalAuthorizationData(excelRows);
    } else {
      setLocalAuthorizationData([]);
      toast.error('Please upload a valid Excel file');
    }
  };

  const sampleLocalAuthorization = [
    {
      idTag: '',
      expiryDate: '',
      parentIdTag: '',
      status: '',
    },
  ];

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const downloadSampleLocalAuthorization = (file, fileName) => {
    // download static Excel file
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">SEND LOCAL LIST</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              enableReinitialize={!_.isEmpty(localAuthorizationData)}
              validationSchema={SendLocalSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  ...values,
                  id: chargerId,
                  localAuthorizationList: _.map(values.localAuthorizationList, (item) => {
                    return {
                      idTag: item.idTag,
                      idTagInfo: {
                        expiryDate: item.expiryDate,
                        parentIdTag: item.parentIdTag,
                        status: item.status,
                      },
                    };
                  }),
                };
                getSendLocalList(formData, resetForm);
                setIsLoader(true);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={6}>
                        <TextInput
                          isRequired
                          label="List Version"
                          placeholder="List version"
                          type="number"
                          value={values.listVersion}
                          name="listVersion"
                          onChange={handleChange}
                          error={errors.listVersion && touched.listVersion ? errors.listVersion : null}
                        />
                      </Col>
                      <Col>
                        <Select
                          isRequired
                          label="Update Type"
                          placeholder="Update type"
                          value={values.updateType}
                          options={[
                            { label: 'Differential', value: 'Differential' },
                            { label: 'Full', value: 'Full' },
                          ]}
                          name="updateType"
                          onChange={(val) => {
                            setFieldValue(`updateType`, val);
                          }}
                          error={errors.updateType && touched.updateType ? errors.updateType : null}
                        />
                      </Col>
                      <Col md={6}>
                        <div>
                          <TextInput label="Import Local Authorization File" name="fileUpload" id="fileUpload" onChange={Upload} type="file" />
                          {/*<div className="import--btn">*/}
                          {/*  <Button>Import</Button>*/}
                          {/*</div>*/}
                        </div>
                      </Col>
                      <Col md={6} className="download-sample--block">
                        <div
                          className="download-sample-file"
                          onClick={() => downloadSampleLocalAuthorization(sampleLocalAuthorization, 'LocalAuthorizationList')}
                        >
                          <AiOutlineCar color="#58d37d" size={20} />( Download Sample Local Authorization List )
                        </div>
                      </Col>
                    </Row>
                    <div className="serverOperation-form--block">
                      <SessionHeading text="Local Authorization List" />
                      <div className="serverOperation-block__field-inputs">
                        <FieldArray
                          name="localAuthorizationList"
                          render={(arrayHelpers) => (
                            <>
                              {_.map(values.localAuthorizationList, (field, index) => (
                                <div key={`serverOperation-${index}`} className="serverOperation--item">
                                  <Row>
                                    <Col xl={4} lg={6} md={6}>
                                      <TextInput
                                        isRequired
                                        label="Id Tag"
                                        name="idTag"
                                        placeholder="Id tag"
                                        value={field.idTag || ''}
                                        onChange={(e) => setFieldValue(`localAuthorizationList[${index}].idTag`, e.target.value)}
                                        error={
                                          getIn(errors, `localAuthorizationList[${index}].idTag`) &&
                                          getIn(touched, `localAuthorizationList[${index}].idTag`)
                                            ? getIn(errors, `localAuthorizationList[${index}].idTag`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col xl={4} lg={6} md={6}>
                                      <TextInput
                                        isRequired
                                        label="Expiry Date"
                                        type="date"
                                        name="expiryDate"
                                        placeholder="Expiry date"
                                        value={field.expiryDate || ''}
                                        onChange={(e) => setFieldValue(`localAuthorizationList[${index}].expiryDate`, e.target.value)}
                                        error={
                                          getIn(errors, `localAuthorizationList[${index}].expiryDate`) &&
                                          getIn(touched, `localAuthorizationList[${index}].expiryDate`)
                                            ? getIn(errors, `localAuthorizationList[${index}].expiryDate`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col xl={4} lg={6} md={6}>
                                      <TextInput
                                        isRequired
                                        label="Parent Id Tag"
                                        name="parentIdTag"
                                        placeholder="Parent id tag"
                                        value={field.parentIdTag || ''}
                                        onChange={(e) => setFieldValue(`localAuthorizationList[${index}].parentIdTag`, e.target.value)}
                                        error={
                                          getIn(errors, `localAuthorizationList[${index}].parentIdTag`) &&
                                          getIn(touched, `localAuthorizationList[${index}].parentIdTag`)
                                            ? getIn(errors, `localAuthorizationList[${index}].parentIdTag`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    <Col xl={4} lg={6} md={6}>
                                      <Select
                                        isRequired
                                        label="Status"
                                        options={[
                                          { label: 'Accepted', value: 'Accepted' },
                                          { label: 'Blocked', value: 'Blocked' },
                                          { label: 'Expired', value: 'Expired' },
                                          { label: 'Invalid', value: 'Invalid' },
                                          { label: 'ConcurrentTx', value: 'ConcurrentTx' },
                                        ]}
                                        placeholder="Select status"
                                        name="status"
                                        value={field.status || ''}
                                        onChange={(e) => setFieldValue(`localAuthorizationList[${index}].status`, e)}
                                        error={
                                          getIn(errors, `localAuthorizationList[${index}].status`) &&
                                          getIn(touched, `localAuthorizationList[${index}].status`)
                                            ? getIn(errors, `localAuthorizationList[${index}].status`)
                                            : null
                                        }
                                      />
                                    </Col>
                                    {index > 0 && (
                                      <Col xl={8} lg={6} md={6} className="d-flex align-items-center">
                                        <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                          <BiTrash size={26} />
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              ))}
                              <div>
                                <Button
                                  className="field--btn"
                                  onClick={() =>
                                    arrayHelpers.insert(values.localAuthorizationList.length, {
                                      idTag: '',
                                      expiryDate: '',
                                      parentIdTag: '',
                                      status: '',
                                    })
                                  }
                                >
                                  <BiPlus size={22} className="plus--icon" /> Add
                                </Button>
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Send Local List
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="server-operation-table-page_main">
            <div className="server-operation-table-main-block">
              <div className="server-operation-table-block">
                <div className="server-operation-search-box">
                  <Row>
                    <Col>
                      <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    </Col>
                  </Row>
                </div>
                <div className="server-operation-table_list">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>List Version</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Id Tag</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Parent Id Tag</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Status</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Expiry Date</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>UTC Expiry Date</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allSendLocalListData) ? (
                          <tr>
                            <td colSpan={8} className="border-0">
                              <div className="empty-data-block">No data available in table</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(allSendLocalListData, (item, index) => {
                            return (
                              <tr key={`configuration-data-item-${index}`}>
                                <td>{serialNum + (index + 1)}</td>
                                <td>{_.get(item, 'message.listVersion', '')}</td>
                                <td>
                                  {_.map(_.get(item, 'message.localAuthorizationList'), (item) => (
                                    <p>{item.idTag}</p>
                                  ))}
                                </td>
                                <td>
                                  {_.map(_.get(item, 'message.localAuthorizationList'), (item) => (
                                    <p>{item.idTagInfo.parentIdTag}</p>
                                  ))}
                                </td>
                                <td>
                                  {_.map(_.get(item, 'message.localAuthorizationList'), (item) => (
                                    <p>{item.idTagInfo.status}</p>
                                  ))}
                                </td>
                                <td>
                                  {_.map(_.get(item, 'message.localAuthorizationList'), (item) => (
                                    <p>{item.idTagInfo.expiryDate}</p>
                                  ))}
                                </td>
                                <td>
                                  {_.map(_.get(item, 'message.localAuthorizationList'), (item) => (
                                    <p>{moment(item.idTagInfo.expiryDate).utc().format('YYYY-MM-DD')}</p>
                                  ))}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* {!_.isEmpty(allSendLocalListData) && !(isLoader || _.isUndefined(isLoader)) && (
                  <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )} */}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default SendLocalListForm;
