import React from 'react';
import ADIconLogo from 'assets/images/icons/adnoc-logo.png';
import Button from 'components/inputs/Button';

import PropTypes from 'prop-types';

const Login = () => {
  return (
    <main className="login-page__main">
      <div className="login-bg-block">
        <div className="login-content-block">
          <div className="logo-block">
            <div className="logo--img">
              <img src={ADIconLogo} alt="Adnoc" />
            </div>
          </div>
          <div className="page-block__main">
            <div className="title">Login</div>
            <p className="sub-title">Welcome to Adnoc</p>
            <div className="login-tab">
              <div className="tab-block">
                <div className="tab-content-block">
                  <div className="tab-content__item active" id="login-tab">
                    {<LoginPage />}
                  </div>
                  {/* <div style={{ textAlign: 'center' }} className="login-button--block">
                    <Button type="submit" onClick={() => (window.location.href = `/loginWithPartner`)} className="login--btn">
                      Login with Partner
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const LoginPage = () => {
  const redirect = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/util/sso`;
  };
  return (
    <div className="login-form__block">
      <div style={{ textAlign: 'center' }} className="login-button--block">
        <Button type="submit" onClick={redirect} className="login--btn">
          Login with SSO
        </Button>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  firebaseToken: PropTypes.string,
};

export default Login;
