import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col, Modal } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import DropDown from 'components/inputs/DropDown';
import { useSelector, useDispatch } from 'react-redux';
import { BsThreeDots, BsUpload } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import { CREATE_BULK_RFID, DELETE_RFID, FETCH_RFID, GET_RFID } from 'actions/rfid';
import moment from 'moment';
import DeleteModal from 'components/general/DeleteModal';
import { CREATE_STATUS_CODE, DELETE_STATUS_CODE } from 'components/common/constant';
import RfidEditList from './RfidEditList';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { GET_RFID_GROUP } from 'actions/rfidGroup';
import { useParams } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';

const RfidList = () => {
  const dispatch = useDispatch();
  const { rfidId } = useParams();

  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [id, setId] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const rfidGroupDetail = useSelector((state) => state.rfidGroup.rfidGroupDetail);
  const allRfid = useSelector((state) => state.rfid.rfid);
  const page = useSelector((state) => state.rfid.page);
  const totalData = useSelector((state) => state.rfid.total);
  const limit = useSelector((state) => state.rfid.limit);
  const totalPages = useSelector((state) => state.rfid.totalPages);

  const initialValues = {
    name: '',
    cashback_percent: 0,
  };

  const getRfid = useCallback((data) => {
    dispatch({ type: FETCH_RFID, payload: data });
  }, []);

  const getRfidDetail = useCallback((id) => {
    dispatch({ type: GET_RFID, payload: id });
  }, []);

  const deleteRfid = useCallback(
    () =>
      dispatch({
        type: DELETE_RFID,
        payload: id,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
          }
        },
      }),
    [id]
  );

  const addBulkRfid = useCallback((data) => {
    dispatch({
      type: CREATE_BULK_RFID,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          toast.success('RFID added successfully');
        }
      },
    });
  }, []);

  const getRfidGroupDetail = useCallback((id) => {
    dispatch({ type: GET_RFID_GROUP, payload: id });
  }, []);

  useEffect(() => {
    if (rfidId) {
      getRfidGroupDetail(rfidId);
      getRfid();
    } else {
      getRfid();
    }
  }, [rfidId]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getRfid(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRfid(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRfid(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const upload = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('file');
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    _.map(excelRows, (data) => {
      data.date_of_issuance = moment(data.date_of_issuance).format('YYYY/MM/DD');
      data.expiry_date = moment(data.date_of_issuance).format('YYYY/MM/DD');
      data.offer_expiry_date = moment(data.offer_expiry_date).format('YYYY/MM/DD');
    });
    addBulkRfid(excelRows);
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setId('');
  };

  const handleCloseFormRfid = () => {
    setShowForm(false);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Add New Group" backTo="/rfidGroup" />
        <div className="page-content-wrapper scrollable">
          <div className="rfid-page-main">
            <Card className="rfid-form-card">
              <div className="rfid-form__block">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    values, setSubmitting(false);
                  }}
                >
                  {({ handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xl={6}>
                          <TextInput
                            disabled
                            isRequired
                            label="Group Name"
                            placeholder="Group name"
                            name="name"
                            value={_.get(rfidGroupDetail, 'name', '')}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col xl={6}>
                          <TextInput
                            disabled
                            isRequired
                            label="Cashback Offer"
                            type="number"
                            placeholder="Cashback offer"
                            name="cashback_percent"
                            value={_.get(rfidGroupDetail, 'cashback_percent', '')}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Col xl={6}>
                        <div className="upload-file-block">
                          <input onChange={upload} type="file" id="file" className="upload-file--input" />
                          <label className="upload-file-inner" htmlFor="file">
                            <div className="import-btn__block">
                              <div className="import_btn">
                                <span className="rfid-upload-file-box-icon">
                                  <BsUpload />
                                </span>
                                <span className="rfid-upload-file-box-text">import Data</span>
                              </div>
                            </div>
                          </label>
                        </div>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
            <div className="rfid-btn-block">
              <Button
                className="rfid-btn"
                onClick={() => {
                  setShowForm(true);
                  setIsEdit(false);
                }}
              >
                Add New RFID
              </Button>
            </div>
            <Card>
              <div className="rfidList-table">
                <div className="table-responsive">
                  <table className="record-list-table">
                    <thead>
                      <tr>
                        <th>
                          <div className="sorting">
                            <span>Sr. No</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>RFID Tag</span>
                            <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>Date of issuance</span>
                            <span className="ico" onClick={() => handleSorting('date_of_issuance', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>Expiry Date</span>
                            <span className="ico" onClick={() => handleSorting('expiry_date', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>Offer Expiry Date</span>
                            <span className="ico" onClick={() => handleSorting('offer_expiry_date', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th className="action-col">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isEmpty(allRfid) ? (
                        <tr>
                          <td colSpan={6} className="border-0">
                            <div className="empty-data-block">No RFID Data</div>
                          </td>
                        </tr>
                      ) : (
                        _.map(allRfid, (item, key) => {
                          return (
                            <tr key={`rfid-${key}`}>
                              <td>{key + 1}.</td>
                              <td>{_.get(item, 'code', '')}</td>
                              <td>{moment(_.get(item, 'date_of_issuance', '')).format('DD MMM YYYY')}</td>
                              <td>{moment(_.get(item, 'expiry_date', '')).format('DD MMM YYYY')}</td>
                              <td>{moment(_.get(item, 'offer_expiry_date', '')).format('DD MMM YYYY')}</td>
                              <td>
                                <div key={`action-${key}`} className="rfid">
                                  <span className="list-icon">
                                    <DropDown
                                      popupClass="bottom-left"
                                      trigger={
                                        <div className={'more-info'}>
                                          <BsThreeDots />
                                        </div>
                                      }
                                    >
                                      <ul>
                                        <li
                                          className="item"
                                          onClick={() => {
                                            setShowForm(true);
                                            getRfidDetail(_.get(item, 'id', ''));
                                            setIsEdit(true);
                                          }}
                                        >
                                          Edit RFID
                                        </li>
                                        <li
                                          className="item"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setId(_.get(item, 'id', ''));
                                          }}
                                        >
                                          Delete RFID
                                        </li>
                                      </ul>
                                    </DropDown>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {!_.isEmpty(allRfid) && (
                <ReactPagination
                  currentPage={page}
                  limit={limit}
                  total={totalData}
                  handlePageClick={(pageVal) => handlePageClick(pageVal)}
                  totalPages={totalPages}
                  marginPagesDisplayed={1}
                />
              )}
              <div className="rfid-table-btn__block">
                <Button type="submit" className="table_btn">
                  Save and Add Group
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
      {showForm && (
        <Modal show={showForm} size="xl" centered>
          <RfidEditList onClose={handleCloseFormRfid} onSuccess={getRfid} isEdit={isEdit} />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
          <DeleteModal title="RFID" onClose={handleCloseDeleteModel} onRemove={deleteRfid} />
        </Modal>
      )}
    </React.Fragment>
  );
};
export default RfidList;
