import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_EMAIL, UPDATE_EMAIL } from 'actions/email';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';

const EmailSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').strict(true).trim('Space is not allowed').max(100, 'Text limit should be less than 100 characters'),
  slug: Yup.string().required('Slug is required').strict(true).trim('Space is not allowed').max(100, 'Text limit should be less than 100 characters'),
  subject: Yup.string()
    .required('Subject is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
});

const EmailForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();

  const emailDetail = useSelector((state) => state.email.emailDetail);
  const isLoading = useSelector((state) => state.email.isLoading);

  const addEmail = useCallback((data) => {
    dispatch({
      type: CREATE_EMAIL,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateEmail = useCallback((data) => {
    dispatch({
      type: UPDATE_EMAIL,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const initialValues = isEdit
    ? { ...emailDetail }
    : {
        name: '',
        body_html: '',
        slug: '',
        subject: '',
      };
  return (
    <React.Fragment>
      <div className="email-form-page__main">
        <Card>
          <div className="email-form-header__block">
            <div className="email-header-name">
              <span>{isEdit ? 'Edit' : 'Add'} Email </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="email-form-body__block">
            <div className="email-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={EmailSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateEmail(values);
                  } else {
                    addEmail(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label="Name"
                          placeholder="Name"
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label="Slug"
                          placeholder="Slug"
                          name="slug"
                          value={_.get(values, 'slug', '')}
                          onChange={handleChange}
                          error={errors.slug && touched.slug ? errors.slug : null}
                        />
                      </Col>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label="Subject"
                          placeholder="Subject"
                          name="subject"
                          value={_.get(values, 'subject', '')}
                          onChange={handleChange}
                          error={errors.subject && touched.subject ? errors.subject : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
EmailForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default EmailForm;
