export const FETCH_PERMISSION = 'FETCH_PERMISSION';
export const FETCH_PERMISSION_SUCCESS = 'FETCH_PERMISSION_SUCCESS';
export const FETCH_PERMISSION_FAIL = 'FETCH_PERMISSION_FAIL';

export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAIL = 'GET_PERMISSION_FAIL';

export const CREATE_PERMISSION = 'CREATE_PERMISSION';
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_FAIL = 'CREATE_PERMISSION_FAIL';

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const UPDATE_PERMISSION_SUCCESS = ' UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAIL = 'UPDATE_PERMISSION_FAIL';

export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAIL = ' DELETE_PERMISSION_FAIL';
