import React from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { UPDATE_CPO } from 'actions/partnerManagement';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useParams } from 'react-router-dom';




const Financials = () => {
  const { ocpiId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const roleId = useSelector((state) => state.partnerManagement.partnerInfo.id);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const CpoFinanceSchema = Yup.object().shape({
    bankName: Yup.string().required('Bank Name is required!'),
    branch: Yup.string().required('Branch is required!'),
    account_no: Yup.string().required('Account Number is required!'),
    ifscCode: Yup.string().required('IFSC Code is required!'),
    owner: Yup.string().required('Owner is required!'),
    commission: Yup.string().required('Commission is required!'),
    discount: Yup.string().required('Discount is required!'),
    serviceCharge: Yup.string().required('Service Charge is required!'),
  });

  const initialValues = ocpiId
    ? {
      bankName: ocpiData.bankName,
      branch: ocpiData.branch,
      account_no: ocpiData.account_no,
      ifscCode: ocpiData.ifscCode,
      owner: ocpiData.owner,
      commission: ocpiData.commission,
      discount: ocpiData.discount,
      serviceCharge: ocpiData.serviceCharge,
    }
    : {
      bankName: '',
      branch: '',
      account_no: '',
      ifscCode: '',
      owner: '',
      commission: '',
      discount: '',
      serviceCharge: '',
    };

  return (
    <React.Fragment>
      <LayoutContainer>
        <Card className="customerBooking-table">
          <Formik
            initialValues={initialValues}
            validationSchema={CpoFinanceSchema}
            onSubmit={(data) =>
              dispatch({
                type: UPDATE_CPO,
                payload: { data, roleId },
                cb: (res) => {
                  if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                    history.push(`/cpo`);
                  }
                },
              })
            }
          >
            {({ handleSubmit, values, errors, touched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <div className="address_details_sec">
                    <div className="cpo_title">
                      <span>Bank Details</span>
                    </div>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Bank Name"
                          placeholder="Enter Bank Name"
                          name="bankName"
                          value={values.bankName}
                          onChange={(val) => setFieldValue('bankName', val.target.value)}
                          error={errors.bankName && touched.bankName ? errors.bankName : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label="Branch"
                          placeholder="Enter Branch"
                          name="branch"
                          value={values.branch}
                          onChange={(val) => setFieldValue('branch', val.target.value)}
                          error={errors.branch && touched.branch ? errors.branch : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Account Number"
                          placeholder="Enter Account Number"
                          name="ac"
                          value={values.account_no}
                          onChange={(val) => setFieldValue('account_no', val.target.value)}
                          error={errors.account_no && touched.account_no ? errors.account_no : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label="IFSC Code"
                          placeholder="Enter IFSC Code"
                          name="ifscCode"
                          value={values.ifscCode}
                          onChange={(val) => setFieldValue('ifscCode', val.target.value)}
                          error={errors.ifscCode && touched.ifscCode ? errors.ifscCode : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Owner"
                          placeholder="Enter Owner"
                          name="owner"
                          value={values.owner}
                          onChange={(val) => setFieldValue('owner', val.target.value)}
                          error={errors.owner && touched.owner ? errors.owner : null}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>

                <div>&nbsp;</div>

                {/* Billing Information */}

                <Card>
                  <div className="address_details_sec">
                    <div className="cpo_title">
                      <span>Billing Information</span>
                    </div>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Commission"
                          placeholder="Commission"
                          value={values.commission}
                          onChange={(val) => setFieldValue('commission', val.target.value)}
                          error={errors.commission && touched.commission ? errors.commission : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired
                          label="Discount"
                          placeholder="Discount"
                          value={values.discount}
                          onChange={(val) => setFieldValue('discount', val.target.value)}
                          error={errors.discount && touched.discount ? errors.discount : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Service Charge"
                          placeholder="Service Charge"
                          value={values.serviceCharge}
                          onChange={(val) => setFieldValue('serviceCharge', val.target.value)}
                          error={errors.serviceCharge && touched.serviceCharge ? errors.serviceCharge : null}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>

                <div>&nbsp;</div>

                <div className="cpo_save_btn">
                  <Button type='submit' className="cpo_save_btn__1 ">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default Financials;
