import { get, post, deleteRequest, patch } from 'utils/api';

export const getAllChargingStation = (data) => {
  return get(`/chargingStations`, data);
};

export const getChargingStation = (id) => {
  return get(`/chargingStations/${id}`);
};

export const addChargingStation = (data) => {
  return post(`/chargingStations`, data);
};

export const deleteChargingStation = (id) => {
  return deleteRequest(`/chargingStations/${id}`);
};

export const updateChargingStation = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/chargingStations/${id}`, data);
};

export const getStationReview = (data) => {
  return get('/reviewStations', data);
};

export const updateContactPerson = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/users/${id}`, data);
};

export const chargingStation = {
  getAllChargingStation,
  getChargingStation,
  addChargingStation,
  deleteChargingStation,
  updateChargingStation,
  getStationReview,
  updateContactPerson,
};
