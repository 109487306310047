import React, { useCallback, useState, useEffect } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Col, FloatingLabel, Row } from 'react-bootstrap';
import { BsLightningCharge, BsSearch } from 'react-icons/bs';
import { AiOutlineDollar } from 'react-icons/ai';
// import DatePicker from 'components/inputs/DatePicker';
import Select from 'components/inputs/Select';
import PropTypes from 'prop-types';
import SearchBox from 'components/general/SearchBox';
import InvoiceInfo from './InvoiceInfo';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FETCH_INVOICE } from 'actions/invoice';
import DateTimePicker from 'components/inputs/DateTimePicker';

const BillingInvoice = () => {
  const dispatch = useDispatch();
  const [fieldValue, setFieldValue] = useState();
  const [currentTab, setCurrentTab] = useState('today');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const getInvoice = useCallback((data = {}) => {
    dispatch({ type: FETCH_INVOICE, payload: data });
  }, []);

  useEffect(() => {
    getInvoice(todayDateData);
  }, []);

  // useEffect(() => {
  //   const filterDateData = {
  //     from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
  //     to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  //   };
  //   endDate && getInvoice(filterDateData);
  // }, [startDate, endDate]);

  useEffect(() => {
    const newestData = {
      sortBy: '1',
    };
    fieldValue === 'Newest' && getInvoice(newestData);
    const oldestData = {
      sortBy: '-1',
    };
    fieldValue === 'Oldest' && getInvoice(oldestData);
    fieldValue === 'All' && getInvoice();
  }, [fieldValue]);

  const todayDateData = {
    from: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  };

  const weeklyDateData = {
    from: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
  };

  const monthlyDateData = {
    from: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Billing and Invoicing" />
        <div className="page-content-wrapper scrollable">
          <div className="billing-invoicing__main">
            <div className="billing--box">
              <Card>
                <div className="billing-search--block">
                  <Row>
                    <Col xl={12} md={5}>
                      <SearchBox preIcon={<BsSearch />} />
                    </Col>
                  </Row>
                </div>
                <div className="billing-content--box">
                  <Row>
                    <Col xl={6} md={12}>
                      <BillingInvoiceInfo icon={<AiOutlineDollar />} title={'$'} subtitle="Revenue generated" />
                    </Col>
                    <Col xl={6} md={12}>
                      <BillingInvoiceInfo icon={<BsLightningCharge />} title={''} subtitle="Energy Unit Consumed" />
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="text-center my-5">Revenue Chart will here....</div>
                </div>
              </Card>
            </div>
            <div className="invoice--box">
              <Card>
                <div className="tab-block">
                  <div className="invoice-tab__block">
                    <div className="invoice--text">All Invoices</div>
                    <div className="tab-nav-block">
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'today' ? 'active' : ''}`}
                        onClick={(e) => {
                          setTab(e, 'today'), getInvoice(todayDateData);
                        }}
                        data-content="tab-today"
                      >
                        Today
                      </a>
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'weekly' ? 'active' : ''}`}
                        onClick={(e) => {
                          setTab(e, 'weekly'), getInvoice(weeklyDateData);
                        }}
                        data-content="tab-weekly"
                      >
                        Weekly
                      </a>
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'monthly' ? 'active' : ''}`}
                        onClick={(e) => {
                          setTab(e, 'monthly'), getInvoice(monthlyDateData);
                        }}
                        data-content="tab-monthly"
                      >
                        Monthly
                      </a>
                      <div className="tab-nav-block__right">
                        <div className="date-select-box--content">
                          <span className="date-icon">
                            <div className="billing-date-picker">
                              {/* <DatePicker
                                defaultValueOfStartDate={new Date()}
                                defaultValueOfEndDate={new Date()}
                                onChangeOfStartDate={(item) => setStartDate(item)}
                                onChangeOfEndDate={(item) => setEndDate(item)}
                              /> */}
                              <DateTimePicker onChangeOfStartDate={(item) => setStartDate(item)} onChangeOfEndDate={(item) => setEndDate(item)} />
                            </div>
                          </span>
                          <span className="list-select--box">
                            <FloatingLabel label="Status :" className="list-select-box-label">
                              <Select
                                options={[
                                  { label: 'Newest', value: 'Newest' },
                                  { label: 'Oldest', value: 'Oldest' },
                                  { label: 'All', value: 'All' },
                                ]}
                                name=""
                                value={fieldValue}
                                selectClassName="list-select-box-input"
                                onChange={(val) => {
                                  setFieldValue(val);
                                }}
                              />
                            </FloatingLabel>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content-block">
                    <div className="tab-content__item active" id="invoice-details-tab">
                      {currentTab === 'today' && <InvoiceInfo startingDate={startDate} endingDate={endDate} todayDateData={todayDateData} />}
                      {currentTab === 'weekly' && <InvoiceInfo startingDate={startDate} endingDate={endDate} weeklyDateData={weeklyDateData} />}
                      {currentTab === 'monthly' && <InvoiceInfo startingDate={startDate} endingDate={endDate} monthlyDateData={monthlyDateData} />}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

const BillingInvoiceInfo = (props) => {
  const { icon, title, subtitle } = props;
  return (
    <div className="billing-info--box">
      <div className="billing-info-icon">{icon}</div>
      <div className="billing-info--wrapper">
        <span className="billing-title">{title}</span>
        <span className="billing-subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

BillingInvoiceInfo.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default BillingInvoice;
