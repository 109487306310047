import React, { useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import Card from 'components/inputs/Card';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { CREATE_CPO, GET_CPO, UPDATE_CPO } from 'actions/partnerManagement';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_COUNTRY, FETCH_STATE, FETCH_CITY } from 'actions/address';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';

const CpoInformation = ({ infoToConnectivity }) => {
  const dispatch = useDispatch();

  const { ocpiId } = useParams();
  const token = cookie.get('token');

  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const isLoading = useSelector((state) => state.partnerManagement.isLoading);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const getCPOdata = useCallback(() => {
    dispatch({ type: GET_CPO, payload: ocpiId });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  useEffect(() => {
    ocpiId && getCPOdata();
    getAllCountry();
    ocpiId && getStateByCountry({ country_name: ocpiData.country });
    ocpiId && getCityByState({ state_name: ocpiData.state });
  }, [ocpiData.country, ocpiData.state]);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, [imgUrlData.url]);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name');
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const CpoSchema = Yup.object().shape({
    role: Yup.string().required('Role is required!'),
    country: Yup.string().required('Country Name is required!'),
    //category: Yup.string().required('Category is required!'),
    firstAddress: Yup.string().required('Address is required!'),
    secondAddress: Yup.string(),
    state: Yup.string().required('State is required!'),
    city: Yup.string().required('City is required!'),
    pincode: Yup.string().required('Pincode is required!'),
    website_url: Yup.string().required('Website URL is required!'),
    gst_no: Yup.string().required('GST Number is required!'),
  });

  const initialValues = ocpiId
    ? {
        role: ocpiData.role,
        country: ocpiData.country,
        //category: ocpiData.category,
        firstAddress: ocpiData.firstAddress,
        secondAddress: ocpiData.secondAddress,
        state: ocpiData.state,
        city: ocpiData.city,
        pincode: ocpiData.pincode,
        website_url: ocpiData.website_url,
        gst_no: ocpiData.gst_no,
        logo: ocpiData.logo,
      }
    : {
        role: 'CPO',
        country: '',
        //category: '',
        firstAddress: '',
        secondAddress: '',
        state: '',
        city: '',
        pincode: '',
        website_url: '',
        gst_no: '',
        logo: '',
      };

  return (
    <React.Fragment>
      <LayoutContainer>
        {isLoading ? (
          <div className="loader--block">
            <Spinner as="span" animation="border" size="sm" role="status" />
            <span className="ps-2">Loading...</span>
          </div>
        ) : (
          <Card className="customerBooking-table">
            {
              <Formik
                initialValues={initialValues}
                validationSchema={CpoSchema}
                onSubmit={(val) => {
                  if (ocpiId) {
                    const { firstAddress, secondAddress, state, city, pincode, website_url, gst_no, logo } = val;
                    const data = { firstAddress, secondAddress, state, city, pincode, website_url, gst_no, logo };
                    dispatch({
                      type: UPDATE_CPO,
                      payload: { data, roleId: ocpiId },
                      cb: (res) => {
                        if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                          infoToConnectivity();
                        }
                      },
                    });
                  } else {
                    dispatch({
                      type: CREATE_CPO,
                      payload: val,
                      cb: (res) => {
                        if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
                          infoToConnectivity();
                        }
                      },
                    });
                  }
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Card>
                      <div className="cpo_sec">
                        <div className="business_details_sec">
                          <div className="cpo_title">
                            <span>Business Details</span>
                          </div>
                          <Row>
                            <Col>
                              <TextInput
                                isRequired={ocpiId ? false : true}
                                disabled
                                label="Role"
                                placeholder="role"
                                name="role"
                                value={ocpiId ? ocpiData.role : values.role}
                                onChange={(val) => setFieldValue('role', val.target.value)}
                                error={errors.role && touched.role ? errors.role : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Select
                                isRequired={ocpiId ? false : true}
                                label="Country"
                                options={_.map(allCountries, (item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                placeholder="Select country"
                                name="country"
                                value={values.country}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={getAllCountry}
                                page={countryPage}
                                totalPage={countryTotalPage}
                                onChange={(val) => {
                                  getStateByCountry({ country_name: val });
                                  setFieldValue(`country`, val);
                                }}
                                error={errors.country && touched.country ? errors.country : null}
                              />
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col>
                              <Select
                                isRequired
                                label="Select Category"
                                options={[
                                  { label: 'Category 1', value: 'Category 1' },
                                  { label: 'Category 2', value: 'Category 2' },
                                ]}
                                placeholder="Select Category"
                                value={values.category}
                                onMenuScrollDown={true}
                                onChange={(val) => setFieldValue('category', val)}
                                error={errors.category && touched.category ? errors.category : null}
                              />
                            </Col>
                          </Row> */}
                          <Row>
                            <Col>
                              {ocpiId ? (
                                <TextInput
                                  type="file"
                                  label="Partner Logo"
                                  name="logo"
                                  accept="image/*"
                                  onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                                  // error={errors.variant && touched.variant ? errors.variant : null}
                                  image={values.logo}
                                />
                              ) : (
                                <TextInput
                                  type="file"
                                  label="Partner Logo"
                                  name="logo"
                                  accept="image/*"
                                  onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                                  // error={errors.variant && touched.variant ? errors.variant : null}
                                />
                              )}
                            </Col>
                          </Row>
                        </div>
                        <div className="address_details_sec">
                          <div className="cpo_title">
                            <span>Address Information</span>
                          </div>
                          <Row>
                            <Col>
                              <TextInput
                                isRequired
                                label="First Address"
                                name="type"
                                placeholder="First Address"
                                value={values.firstAddress}
                                onChange={(val) => setFieldValue('firstAddress', val.target.value)}
                                error={errors.firstAddress && touched.firstAddress ? errors.firstAddress : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                isRequired
                                label="Second Address"
                                placeholder="Second Address"
                                value={values.secondAddress}
                                onChange={(val) => setFieldValue('secondAddress', val.target.value)}
                                error={errors.secondAddress && touched.secondAddress ? errors.secondAddress : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Select
                                isRequired
                                label="State"
                                options={_.map(allState, (item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                placeholder="Select State"
                                name="state"
                                value={values.state}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                                page={statePage}
                                totalPage={stateTotalPage}
                                onChange={(val) => {
                                  getCityByState({ state_name: val });
                                  const currentState = _.find(allState, { name: val });
                                  setFieldValue(`state`, currentState.name);
                                }}
                                error={errors.state && touched.state ? errors.state : null}
                              />
                            </Col>
                            <Col>
                              <Select
                                isRequired
                                label="City"
                                options={
                                  values.state &&
                                  _.map(allCity, (item) => {
                                    return { label: item.name, value: item.name };
                                  })
                                }
                                placeholder="Select City"
                                name="city"
                                value={values.city}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                                page={cityPage}
                                totalPage={cityTotalPage}
                                onChange={(val) => {
                                  const currentCity = _.find(allCity, { name: val });
                                  setFieldValue('city', currentCity.name);
                                }}
                                error={errors.city && touched.city ? errors.city : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                isRequired
                                label="Pincode"
                                placeholder="Pincode"
                                value={values.pincode}
                                onChange={(val) => setFieldValue('pincode', val.target.value)}
                                error={errors.pincode && touched.pincode ? errors.pincode : null}
                              />
                            </Col>
                            <Col>
                              <TextInput
                                isRequired
                                label="website_url "
                                placeholder="website_url "
                                value={values.website_url}
                                onChange={(val) => setFieldValue('website_url', val.target.value)}
                                error={errors.website_url && touched.website_url ? errors.website_url : null}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextInput
                                isRequired
                                label="GST No. "
                                placeholder="GST No. "
                                value={values.gst_no}
                                onChange={(val) => setFieldValue('gst_no', val.target.value)}
                                error={errors.gst_no && touched.gst_no ? errors.gst_no : null}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Card>
                    <div>&nbsp;</div>
                    <div className="cpo_save_btn">
                      <Button type="submit" className="cpo_save_btn__1">
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
                {/*  */}
              </Formik>
            }
          </Card>
        )}
      </LayoutContainer>
    </React.Fragment>
  );
};
CpoInformation.propTypes = {
  infoToConnectivity: PropTypes.func,
};
export default CpoInformation;
