import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Chart from 'chart.js/auto';
import { BiSort } from 'react-icons/bi';
import moment from 'moment';
import { BsDownload } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { CAPACITY_UTILISATION, DOWNLOAD_CAPACITY_DATA } from 'actions/dataReport';
import * as _ from 'lodash';
import DatePicker from 'components/inputs/DatePicker';
import fileDownload from 'js-file-download';
import Select from 'components/inputs/Select';

const Capacityutilization = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).subtract(1, 'day').endOf('day'));
  //const [sortByItem, setSortByItem] = useState(null);
  const [sortByItem, setSortByItem] = useState({ item: '', order: 'asc' });
  const capacityUtilisations = useSelector((state) => state?.capacityUtilisation?.CapacityUtilisation);
  const isLoader = useSelector((state) => state?.capacityUtilisation?.isLoading);

  const getCapacityUtilisations = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      dispatch({
        type: CAPACITY_UTILISATION,
        payload: data,
      });
    },
    [startDate, endDate, capacityUtilisations]
  );

  // useEffect(() => {
  //   console.log(totalPages, totalData, page, limit);
  // }, [totalPages, totalData, page, limit]);

  useEffect(() => {
    const data = {
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    if (sortByItem) {
      const filterDateData = {
        ...data,
        sortBy: `${sortByItem.order === 'asc' ? '' : '-'}${sortByItem.item}`,
      };
      getCapacityUtilisations(filterDateData);
    } else {
      getCapacityUtilisations(data);
    }
  }, [startDate, endDate, sortByItem]);

  const downloadCapacityUtilisation = useCallback(() => {
    const data = {
      excel: true,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    dispatch({
      type: DOWNLOAD_CAPACITY_DATA,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Capacity Utilization'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate, capacityUtilisations]);

  const downloadFile = () => {
    downloadCapacityUtilisation();
  };

  const [chartData, setChartData] = useState({});
  const [pieData, setpieData] = useState({});
  /* eslint-disable no-unused-vars */
  const [data, setData] = useState([]);
  const [order, setorder] = useState('ASC');
  const sortedData1 = capacityUtilisations.slice(0, 10).sort((a, b) => b.utilized - a.utilized);

  const Charger_labels = sortedData1.map((d) => _.get(d, 'charger.charger_id', ''));
  const utilized_value = sortedData1.map((d) => d.utilized);
  const ideal_value = sortedData1.map((d) => d.ideal);
  const maintenance_value = sortedData1?.map((d) => d.inactive);
  const datasets = [
    {
      label: 'Utilized',
      data: utilized_value,
      backgroundColor: '#6457A6',
    },
    {
      label: 'Idle',
      data: ideal_value,
      backgroundColor: '#7D7ABC',
    },
    {
      label: 'Closed',
      data: maintenance_value,
      backgroundColor: '#EF767A',
    },
  ];

  const [selectedDataset, setSelectedDataset] = useState('Utilized');

  const datasetOptions = datasets.map((dataset) => ({
    label: dataset.label,
    value: dataset.label,
  }));
  const sorting = (col) => {
    if (sortByItem.item === col) {
      const newOrder = sortByItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: col, order: newOrder });
    } else {
      setSortByItem({ item: col, order: 'asc' });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Sort capacityUtilisations array in descending order based on 'utilized' property

      Chart.defaults.font.size = 16;

      setChartData({
        labels: Charger_labels,
        datasets: datasets.filter((dataset) => dataset.label === selectedDataset),
      });
    };

    fetchData();
  }, [startDate, endDate, capacityUtilisations, selectedDataset]);

  // ... Your imports and other code ...

  useEffect(() => {
    const fetchData = async () => {
      const data = capacityUtilisations.filter((d) => {
        const currentDate = moment(d.createdAt).subtract(1, 'day').startOf('day');
        return currentDate;
      });

      const utilized_sum = data.reduce((acc, d) => acc + parseFloat(d.utilized), 0);
      const ideal_sum = data.reduce((acc, d) => acc + parseFloat(d.ideal), 0);
      const inactive_sum = data.reduce((acc, d) => acc + parseFloat(d.inactive), 0);
      const sum = utilized_sum + ideal_sum + inactive_sum;

      setpieData({
        labels: ['Utilized', 'Idle', 'Closed'],
        datasets: [
          {
            label: 'Utilized',
            data: [(utilized_sum * 100) / sum, (ideal_sum * 100) / sum, (inactive_sum * 100) / sum],
            backgroundColor: ['#6457A6', '#7D7ABC', '#EF767A'],
            borderColor: 'none',
            borderWidth: 0,
          },
        ],
      });
    };

    fetchData();
  }, [startDate, endDate, capacityUtilisations]);

  useEffect(() => {
    const chart = new Chart('myChart', {
      type: 'bar',
      data: chartData,

      options: {
        height: 0,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'TOP 10 Utilized',
            color: 'white', // Set the title text color to red
            font: {
              size: 15,
            },
            padding: {
              bottom: 80,
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [chartData]);

  useEffect(() => {
    const chart = new Chart('myChart2', {
      type: 'doughnut',
      data: pieData,
      options: {
        height: 0,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
          animateScale: false,
        },
        centerText: {
          display: true,
          text: 'Doughnut Chart',
          fontColor: '#333333',
          fontSize: 32,
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: capacityUtilisations.length.toString() + " Charger's Overall Utilization",
            color: 'white', // Set the title text color to red
            font: {
              size: 15, // Set the title font size to 20 pixels
            },
            padding: {
              bottom: 40,
            },
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [pieData]);

  const [page, setPage] = useState(1);
  const limit = 10; // The number of items to display per page. You can adjust this as needed.
  const totalData = capacityUtilisations.length; // Replace 'capacityUtilisations' with the actual data array.
  const totalPages = Math.ceil(totalData / limit);

  // Function to handle page change
  const handlePageClick = (pageVal) => {
    setPage(pageVal.selected + 1); // ReactPaginate starts indexing from 0, so add 1 to get the current page.
  };

  // Calculate the slice of data to display based on the current page and limit
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, totalData);
  const sortedData = _.orderBy(capacityUtilisations, [sortByItem.item], [sortByItem.order]);
  const dataToShow = sortedData.slice(startIndex, endIndex);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title='Capacity Utilization' />
          <div className="page-content-wrapper scrollable">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                background: '#1b2a38',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.03)',
                borderRadius: '20px',
                padding: '1em',
                width: 'fit-content',
              }}
            >
              {/* <DateTimePicker
              onChangeOfStartDate={(item) => setStartDate(item)}
              onChangeOfEndDate={(item) => setEndDate(item)}
              initialValueOfStartDate={moment(new Date()).format('DD/MM/YYYY')}
              initialValueOfEndDate={moment(new Date()).format('DD/MM/YYYY')}
            /> */}
              <DatePicker
                onChangeOfStartDate={(item) => setStartDate(item)}
                onChangeOfEndDate={(item) => setEndDate(item)}
                defaultValueOfStartDate={moment(new Date()).subtract(1, 'day').startOf('day')}
                defaultValueOfEndDate={moment(new Date()).subtract(1, 'day').endOf('day')}
                maxDateOfStartDate={moment(new Date()).subtract(1, 'day').startOf('day')}
                maxDateOfEndDate={moment(new Date()).subtract(1, 'day').endOf('day')}
              />{' '}
              <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                  <button className="downloadbtn" onClick={downloadFile}>
                    <BsDownload size={28} color={'#3c7cdd'} />
                  </button>
              </div>
            </div>
            <div
              style={{
                background: 'none',
                boxSizing: 'border-box',
                padding: '20px 0px',
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                width: '100%',
              }}
              className="r_main"
            >
              <div style={{ width: '60%' }} className="r_chart">
                <Select className={'custom_select'} value={selectedDataset} onChange={setSelectedDataset} options={datasetOptions} />
                <canvas
                  id="myChart"
                  className="chart-canvas"
                  style={{ background: '$primary-bg-color', borderRadius: '10px', padding: '5px' }}
                ></canvas>
              </div>
              <div style={{ width: '40%' }} className="r_chart">
                <canvas id="myChart2" style={{ background: '$primary-bg-color', borderRadius: '10px', padding: '5px' }}></canvas>
              </div>
            </div>

            <div
              style={{
                Width: '450px',
                margin: '0px',
                padding: '1rem',
                //background: '$primary-bg-color',
                marginTop: '0px',
                boxSizing: 'border-box',
                background: '#1b2a38',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.03)',
                borderRadius: '20px',
              }}
            >
              <table className="t_chart">
                <thead>
                  <tr>
                    <th onClick={() => sorting('chargername')}>
                      Charger Name
                      <BiSort size={15} className={'sicon'} />
                    </th>
                    <th onClick={() => sorting('location')}>
                      Station Name
                      <BiSort size={15} className={'sicon'} />
                    </th>
                    <th onClick={() => sorting('utilized')}>
                      Utilized %
                      <BiSort size={15} className={'sicon'} />
                    </th>
                    <th onClick={() => sorting('ideal')}>
                      Idle %
                      <BiSort size={15} className={'sicon'} />
                    </th>
                    <th onClick={() => sorting('maintenance')}>
                      InActive %
                      <BiSort size={15} className={'sicon'} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan={5} className="border-0">
                        <div className="loader--block">
                        </div>
                      </td>
                    </tr>
                  ) : _.isEmpty(capacityUtilisations) ? (
                    <tr>
                      <td colSpan={5} className="border-0">
                        <div className="empty-data-block">No Capacity Utilisations Found</div>
                      </td>
                    </tr>
                  ) : (
                    dataToShow.map((item, index) => (
                      <tr key={`b2c-station-charging-history-${index}`}>
                        <td>{_.get(item, 'charger.charger_id', '')}</td>
                        <td>{_.get(item, 'charging_station.name', '')}</td>
                        <td>{_.get(item, 'utilized', '')}</td>
                        <td>{_.get(item, 'ideal', '')}</td>
                        <td>{_.get(item, 'inactive', '')}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={handlePageClick}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            </div>
          </div>
      </LayoutContainer>
    </>
  );
};

export default Capacityutilization;
