import React, { useState, useEffect, useCallback } from 'react';
import { BiPlug } from 'react-icons/bi';
import { BsDot } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { FETCH_CHARGER, FETCH_CHARGER_BY_STATION } from 'actions/charger';
import { CHANGE_CHARGER_IS_ACTIVE_STATUS, FETCH_CHARGER_BY_STATION } from 'actions/charger';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_CHARGER_STATUS } from 'actions/charger';
import * as _ from 'lodash';
import { MdOutlineGpsFixed } from 'react-icons/md';
import ReactPagination from 'components/general/ReactPagination';

const Chargers = () => {
  const dispatch = useDispatch();
  const { stationId } = useParams();

  const [switchTab, setSwitchTab] = useState('publish');
  const getStationChargers = useSelector((state) => state.charger.stationChargers);
  const page = useSelector((state) => state.charger.page);
  const totalData = useSelector((state) => state.charger.total);
  const limit = useSelector((state) => state.charger.limit);
  const totalPages = useSelector((state) => state.charger.totalPages);

  const publishedChargers = _.filter(getStationChargers, { is_active: true });
  const draftChargers = _.filter(getStationChargers, { is_active: false });

  // useEffect(() => {
  //   dispatch({ type: FETCH_CHARGER, payload: {} });
  // }, []);

  const getChargerByStation = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGER_BY_STATION, payload: data });
  }, []);

  useEffect(() => {
    getChargerByStation({ id: stationId });
  }, [stationId]);

  const handlePageClick = useCallback(
    (page) => {
      const data = {
        id: stationId,
        page: page.selected + 1,
      };
      getChargerByStation(data);
    },
    [stationId]
  );

  return (
    <div className="chargers-details__main">
      <div className="charger-details--tabs">
        <div className={`charger-status-tab ${switchTab === 'publish' ? 'active' : ''}`} onClick={() => setSwitchTab('publish')}>
          Published <span className="charger-count--badge">{_.size(publishedChargers)}</span>
        </div>
        <div className={`charger-status-tab ${switchTab === 'draft' ? 'active' : ''}`} onClick={() => setSwitchTab('draft')}>
          In Draft<span className="charger-count--badge draft-badge">{_.size(draftChargers)}</span>
        </div>
      </div>
      <div className="charger-list--block">
        {switchTab === 'publish' &&
          (_.isEmpty(publishedChargers) ? (
            <div className="empty-data-block">No Chargers</div>
          ) : (
            _.map(publishedChargers, (charger, index) => (
              <ChargerDataItem
                key={`charger-${index}`}
                isPublished
                icon={<BiPlug />}
                chargerId={charger.id}
                chargerName={charger.charger_id}
                is_enabled={_.get(charger, 'is_enabled')}
                chargerStatus={_.get(charger, 'charger_status', '')}
                getChargerByStation={getChargerByStation}
                stationId={stationId}
                chargerPlugs={_.map(_.get(charger, 'plugs', []), (item) => item)}
              />
            ))
          ))}
        {switchTab === 'draft' &&
          (_.isEmpty(draftChargers) ? (
            <div className="empty-data-block">No Chargers</div>
          ) : (
            _.map(draftChargers, (charger, index) => (
              <ChargerDataItem
                key={`charger-${index}`}
                icon={<BiPlug />}
                chargerId={charger.id}
                chargerName={charger.charger_id}
                is_enabled={_.get(charger, 'is_enabled')}
                chargerStatus={_.get(charger, 'charger_status', '')}
                getChargerByStation={getChargerByStation}
                stationId={stationId}
              />
            ))
          ))}
      </div>
      {!_.isEmpty(getStationChargers) && (
        <ReactPagination
          currentPage={page}
          limit={limit}
          total={totalData}
          handlePageClick={(pageVal) => handlePageClick(pageVal)}
          totalPages={totalPages}
          marginPagesDisplayed={1}
        />
        // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
      )}
    </div>
  );
};

const ChargerDataItem = (props) => {
  const { isPublished, icon, chargerId, is_enabled, chargerName, chargerStatus, getChargerByStation, stationId, chargerPlugs } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  let statusClassName = chargerStatus === 'Unavailable' ? 'disabled--text' : 'available--text',
    btnLabel,
    btnClassName;
  if (isPublished) {
    if (chargerStatus === 'In Use') {
      statusClassName = 'in-use--text';
      btnLabel = 'Stop';
      btnClassName = 'btn--red';
    } else if (is_enabled === false) {
      // statusClassName = 'disabled--text';
      btnLabel = 'Re-enable';
      btnClassName = 'btn--blue';
    }
  } else {
    btnLabel = 'Publish Now';
    btnClassName = 'btn--red';
  }

  const goToChargerDetail = () => history.push(`/charger/${chargerId}`);
  const publishNow = useCallback(() => {
    const chargerData = {
      chargerId: chargerId,
      is_active: true,
    };
    dispatch({
      type: CHANGE_CHARGER_IS_ACTIVE_STATUS,
      payload: chargerData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            getChargerByStation({ id: stationId });
          }
        }
      },
    });
  }, [chargerId, stationId]);

  const changeStatus = useCallback(() => {
    const chargerData = {
      chargerId: chargerId,
      is_enabled: is_enabled !== true,
    };
    dispatch({
      type: CHANGE_CHARGER_STATUS,
      payload: chargerData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            getChargerByStation({ id: stationId });
          }
        }
      },
    });
  }, [is_enabled]);

  const handleClick = () => {
    if (btnLabel === 'Publish Now') {
      publishNow();
    }
    if (btnLabel === 'Re-enable') {
      changeStatus();
    }
  };

  return (
    <div className="charger--list charger-status--info">
      <div className="list-info">
        {icon}
        <span className="list-name" onClick={goToChargerDetail}>
          {chargerName}
        </span>
      </div>
      {isPublished && (
        <div className={`charger-status--block ${statusClassName}`}>
          <div className={'charger-details--status'}>
            {is_enabled === false && (
              <>
                <div className="list-dot-icon">
                  <BsDot color={'#a5b6c6'} />
                  <span className="disabled-list-text">Disabled</span>
                </div>
              </>
            )}
            <div className="list-dot-icon">
              <BsDot />
              <span className="list-text">{chargerStatus}</span>
            </div>
          </div>
        </div>
      )}
      <div className="plug-info--block">
        <div className="plug-info-wrapper">
          <div className="plug-info--inner">
            {_.map(chargerPlugs, (data, index) => {
              return (
                <div key={index} className="plug-info-content">
                  <div className="charger-round_icon">
                    <MdOutlineGpsFixed />
                  </div>
                  <div>
                    <span>{data.connector_id}</span>
                    <span className="ps-2">({data.status})</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="charger-list--button">
        {btnLabel && (
          <Button onClick={handleClick} className={`charger-action--btn ${btnClassName}`}>
            {btnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

ChargerDataItem.propTypes = {
  isPublished: PropTypes.bool,
  chargerName: PropTypes.string,
  chargerStatus: PropTypes.string,
  is_enabled: PropTypes.bool,
  chargerId: PropTypes.string,
  icon: PropTypes.node,
  getChargerByStation: PropTypes.func,
  stationId: PropTypes.string,
  chargerPlugs: PropTypes.array,
};

export default Chargers;
