import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { TRIGGER_MESSAGE } from 'actions/chargerOcpp';
import Select from 'components/inputs/Select';

const TriggerMessageSchema = Yup.object().shape({
  connectorId: Yup.number().required('Connector name is required!'),
  requestedMessage: Yup.string().required('Requested message is required!').max(100, 'Text limit should be less than 100 characters.'),
});

const TriggerMessageForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);

  const getTriggerMessage = useCallback((data, resetForm) => {
    dispatch({
      type: TRIGGER_MESSAGE,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Trigger message successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const initialValues = {
    connectorId: '',
    requestedMessage: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">TRIGGER MESSAGE</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={TriggerMessageSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  ...values,
                  id: chargerId,
                };
                getTriggerMessage(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col>
                        <Select
                          isRequired
                          label="Connector Name"
                          placeholder="Select Connector Name"
                          options={_.map(_.get(chargerDetailData, 'plugs', []), (item) => {
                            return { label: `${item.name} - ${item.connector_id}`, value: item.connector_id };
                          })}
                          name="connectorId"
                          value={values.connectorId}
                          onChange={(val) => setFieldValue(`connectorId`, val)}
                          error={errors.connectorId && touched.connectorId ? errors.connectorId : null}
                        />
                      </Col>
                      <Col>
                        <Select
                          isRequired
                          label="Requested Message"
                          type="name"
                          placeholder="Select requested message"
                          options={[
                            { label: 'BootNotification', value: 'BootNotification' },
                            { label: 'DiagnosticsStatusNotification', value: 'DiagnosticsStatusNotification' },
                            { label: 'FirmwareStatusNotification', value: 'FirmwareStatusNotification' },
                            { label: 'Heartbeat', value: 'Heartbeat' },
                            { label: 'MeterValues', value: 'MeterValues' },
                            { label: 'StatusNotification', value: 'StatusNotification' },
                          ]}
                          value={values.requestedMessage}
                          name="requestedMessage"
                          onChange={(val) => {
                            setFieldValue(`requestedMessage`, val);
                          }}
                          error={errors.requestedMessage && touched.requestedMessage ? errors.requestedMessage : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Trigger Message
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default TriggerMessageForm;
