import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { GET_DIAGNOSTICS } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import moment from 'moment';

const GetDiagnosticsSchema = Yup.object().shape({
  location: Yup.string().required('Location is required').max(100, 'Text limit should be less than 100 characters.'),
  // retries: Yup.number(),
  // retryInterval: Yup.number(),
  // stopDate: Yup.string(),
  // startTimes: Yup.string(),
  // startDate: Yup.string(),
  // stopTimes: Yup.string(),
});

const GetDiagnosticsForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const getDiagnostics = useCallback((data, resetForm) => {
    dispatch({
      type: GET_DIAGNOSTICS,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Diagnostics successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const initialValues = {
    stopDate: '',
    startTimes: '',
    startDate: '',
    stopTimes: '',
    retries: '',
    location: '',
    retryInterval: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">GET DIAGNOSTICS</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={GetDiagnosticsSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const startTime = moment(values.startDate + values.startTimes, 'YYYY-MM-DDLT');
                const stopTime = moment(values.stopDate + values.stopTimes, 'YYYY-MM-DDLT');
                const formData = { ...values, id: chargerId, startTime: startTime, stopTime: stopTime };
                if (formData.startTime._i && formData.stopTime._i) {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                } else if (formData.startTime._i) {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes', 'stopTime']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                } else if (formData.stopTime._i) {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes', 'startTime']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                } else {
                  const diagnosticsFormData = _.omit(formData, ['stopDate', 'startTimes', 'startDate', 'stopTimes', 'startTime', 'stopTime']);
                  getDiagnostics(diagnosticsFormData, resetForm);
                }
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col md={12}>
                        <TextInput
                          isRequired
                          label="Location"
                          value={values.location}
                          name="location"
                          onChange={handleChange}
                          error={errors.location && touched.location ? errors.location : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Retry Interval"
                          type="number"
                          value={values.retryInterval}
                          name="retryInterval"
                          onChange={handleChange}
                          // error={errors.retryInterval && touched.retryInterval ? errors.retryInterval : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Retries"
                          type="number"
                          value={values.retries}
                          name="retries"
                          onChange={handleChange}
                          // error={errors.retries && touched.retries ? errors.retries : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Start Date"
                          type="date"
                          value={values.startDate}
                          name="startDate"
                          onChange={handleChange}
                          // error={errors.startDate && touched.startDate ? errors.startDate : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Start Time"
                          type="time"
                          value={values.startTime}
                          name="startTimes"
                          onChange={handleChange}
                          // error={errors.startTimes && touched.startTimes ? errors.startTimes : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Stop Date"
                          type="date"
                          value={values.stopDate}
                          name="stopDate"
                          onChange={handleChange}
                          // error={errors.stopDate && touched.stopDate ? errors.stopDate : null}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Stop Time"
                          type="time"
                          value={values.stopTime}
                          name="stopTimes"
                          onChange={handleChange}
                          // error={errors.stopTimes && touched.stopTimes ? errors.stopTimes : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Get Diagnostics
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default GetDiagnosticsForm;
