import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { startsWith } from 'components/common/utils';
import { DELETE_USER_GROUP } from 'actions/userGroup';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { FiEdit2, FiUser } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

const UserGroupList = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [allUserGroupData, setAllUserGroupData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userGroupId, setUserGroupId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const dispatch = useDispatch();
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const page = useSelector((state) => state.userGroup.page);
  const totalData = useSelector((state) => state.userGroup.total);
  const limit = useSelector((state) => state.userGroup.limit);
  const totalPages = useSelector((state) => state.userGroup.totalPages);
  const [pageWiseUserGroupData, setPageWiseUserGroupData] = useState([]);

  const userGroupData = allUserGroup.filter(({ id: id1 }) => pageWiseUserGroupData.some(({ id: id2 }) => id2 === id1));

  const getUserGroup = useCallback(
    (data) => {
      dispatch({
        type: FETCH_USER_GROUP,
        payload: data ? data : {},
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseUserGroupData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseUserGroupData]
  );

  // const allUsersInUserGroup = useCallback((id) => {
  //   const userGroupData = {
  //     user_group: id,
  //   };
  //   dispatch({ type: ALL_USERS_IN_USER_GROUP, payload: userGroupData });
  // }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setUserGroupId('');
  };

  const deleteUserGroup = useCallback(
    () =>
      dispatch({
        type: DELETE_USER_GROUP,
        payload: userGroupId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getUserGroup({ page });
          }
        },
      }),
    [userGroupId]
  );

  useEffect(() => {
    setAllUserGroupData(_.filter(userGroupData, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  }, [searchText, allUserGroup, pageWiseUserGroupData]);

  useEffect(() => {
    getUserGroup();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getUserGroup(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getUserGroup(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getUserGroup(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="User Groups" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-page_main">
            <div className="user-group-main-block">
              <Card>
                <div className="user-group-block">
                  <div className="user-group-search-box">
                    <Row>
                      <Col xl={10}>
                        <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                      </Col>
                      <Col xl={2}>
                        <Button className="user-group-btn" onClick={() => history.push('/addUserGroup')}>
                          Add User Group
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="user-group-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Sr no.</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>User Group Name</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>Action</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(allUserGroupData) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">No User Groups</div>
                              </td>
                            </tr>
                          ) : (
                            _.map(allUserGroupData, (data, index) => {
                              return (
                                <tr key={`user-group-${index}`}>
                                  <td>{index + 1}</td>
                                  <td>{_.get(data, 'name', '')}</td>
                                  <td>
                                    <span
                                      className="user-group-icon"
                                      // onClick={() => allUsersInUserGroup(_.get(data, 'id', ''))}
                                    >
                                      <FiUser size={22} />
                                    </span>
                                    <span className="user-group-table-editIcon" onClick={() => history.push(`/editUserGroup/${data.id}`)}>
                                      <FiEdit2 />
                                    </span>
                                    <span
                                      className="user-group-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setUserGroupId(_.get(data, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!_.isEmpty(allUserGroupData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="userGroup" onClose={handleCloseDeleteModel} onRemove={deleteUserGroup} />
                    </Modal>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default UserGroupList;
