import React, { useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import EmspInformation from './EMSPInformation';
import Connectivity from './Connectivity';
import Financials from './Financials';
import { useParams } from 'react-router-dom';

const AddEmsp = () => {
  const [currentTab, setCurrentTab] = useState('EmspInformation');
  const { ocpiId } = useParams();


  const setTab = (tab) => {
    setCurrentTab(tab);
  };

  // const infoToConnectivity = () => {
  //   setCurrentTab('Connectivity');
  // };
  // const connectivityToFinancials = () => {
  //   setCurrentTab('Financials');
  // };

  // const onInformation = () => {
  //   setTab('Connectivity');
  // };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${ocpiId ? 'Edit' : 'Add'} eMSP`} backTo={`/emsp`} />
        <div className="page-content-wrapper scrollable">
          <div className="add-vehicle-page-main">
            <Card>
              <div>
                <div className="tab-block">
                  <div className="tab-nav-block">
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'EmspInformation' ? 'active' : ''}`}
                      onClick={() => setTab('EmspInformation')}
                      data-content="tab-profile"
                    >
                      Emsp Information
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'Connectivity' ? 'active' : ''}`}
                      onClick={() => setTab('Connectivity')}
                      data-content="tab-bookings"
                    >
                      Connectivity
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'Financials' ? 'active' : ''}`}
                      onClick={() => setTab('Financials')}
                      data-content="tab-transactions"
                    >
                      Financials
                    </a>
                  </div>
                </div>
              </div>
            </Card>

            {currentTab === 'EmspInformation' && <EmspInformation setCurrentTab={setCurrentTab} />}
            {currentTab === 'Connectivity' && <Connectivity setCurrentTab={setCurrentTab} />}
            {currentTab === 'Financials' && <Financials />}
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default AddEmsp;
