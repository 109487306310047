import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'components/general/Pagination';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import { DELETE_FAQ, FETCH_FAQ, GET_FAQ } from 'actions/faq';
import FaqForm from './FaqForm';

const FaqList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [allFaqData, setAllFaqData] = useState([]);

  const page = useSelector((state) => state.faq.page);
  const totalData = useSelector((state) => state.faq.total);
  const limit = useSelector((state) => state.faq.limit);
  const faqList = useSelector((state) => state.faq.faqs);

  const deleteFaq = useCallback((id) => dispatch({ type: DELETE_FAQ, payload: id }), []);

  const getFaq = useCallback((data = {}) => {
    dispatch({ type: FETCH_FAQ, payload: data });
  }, []);

  const getFaqDetail = useCallback((id) => {
    dispatch({ type: GET_FAQ, payload: id });
  }, []);

  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    setAllFaqData(
      _.filter(faqList, (item) => startsWith(_.get(item, 'title', ''), searchText) || startsWith(_.get(item, 'message', ''), searchText))
    );
  }, [searchText, faqList]);

  const handlePageClick = (page) => {
    const data = {
      page: page,
    };
    getFaq(data);
  };

  const handleCloseFormFaq = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Faq" />
        <div className="page-content-wrapper scrollable">
          <div className="faqList-page">
            <Card>
              <div className="faqList-page-wrapper">
                <Row className="faqList-search-box">
                  <Col xl={10} lg={9} md={8} className="faqList-search-inputBox">
                    <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="faqList-search-boxButton">
                    <Button
                      className="faqList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      Add Faq
                    </Button>
                  </Col>
                </Row>
                <div className="faqList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Oreder</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Title</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Message</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allFaqData) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">No Faq Data</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allFaqData, (item, key) => {
                            return (
                              <>
                                <tr key={`faq-${key}`}>
                                  <td>{_.get(item, 'order', '')}</td>
                                  <td>{_.get(item, 'title', '')}</td>
                                  <td>{_.get(item, 'message', '')}</td>
                                  <td>
                                    <span
                                      className="faqList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getFaqDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 />
                                    </span>
                                    <span className="faqList-table-deleteIcon" onClick={() => deleteFaq(_.get(item, 'id', ''))}>
                                      <RiDeleteBinLine />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!_.isEmpty(allFaqData) && (
                  <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <FaqForm onClose={handleCloseFormFaq} isEdit={isEdit} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default FaqList;
