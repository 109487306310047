import { FETCH_MODULE, FETCH_MODULE_SUCCESS, FETCH_MODULE_FAIL } from 'actions/module';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchModule(action) {
  try {
    const response = yield call(API.getAllModule, action.payload);
    yield put({ type: FETCH_MODULE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_MODULE_FAIL, payload: { error: e } });
  }
}

function* module() {
  yield takeLatest(FETCH_MODULE, fetchModule);
}
export default module;
