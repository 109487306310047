import {
  FETCH_ZONES,
  FETCH_ZONES_SUCCESS,
  FETCH_ZONES_FAIL,
  CREATE_ZONES,
  CREATE_ZONES_SUCCESS,
  CREATE_ZONES_FAIL,
  DELETE_ZONES,
  DELETE_ZONES_SUCCESS,
  DELETE_ZONES_FAIL,
  UPDATE_ZONES,
  UPDATE_ZONES_FAIL,
  UPDATE_ZONES_SUCCESS,
  GET_ZONE,
  GET_ZONE_SUCCESS,
  GET_ZONE_FAIL

} from 'actions/zoneManagement';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  zones: [],
  zonesDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const zoneManagementreducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ZONES:
    case CREATE_ZONES:
    case DELETE_ZONES:
    case GET_ZONE:
      {
        return { ...state, isLoading: true, zonesDetail: {} };
      }

    case UPDATE_ZONES: {
      return { ...state, isLoading: true };
    }
    case FETCH_ZONES_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          zones: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          zones: _.concat(state.countries, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_ZONE_SUCCESS: {
      return {
        ...state,
        zonesDetail: payload,
        isLoading: false,
      }
    }
    case CREATE_ZONES_SUCCESS: {
      toast.success('Zone Added Successfully');
      return {
        ...state,
        isLoading: false,
        zones: _.concat(state.zones,payload),
      };
    }

    case UPDATE_ZONES_SUCCESS: {
      toast.success('Zone Updated Successfully');
      return {
        ...state,
        isLoading: false,
        zones: state.zones.map((data) =>  (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_ZONES_SUCCESS: {
      toast.success('Zone Deleted Successfully');
      return {
        ...state,
        isLoading:false,
        zones: state.zones.filter((data) => data.id !== payload),
      };
    }

    case FETCH_ZONES_FAIL:
    case CREATE_ZONES_FAIL:
    case DELETE_ZONES_FAIL:
    case UPDATE_ZONES_FAIL:
    case GET_ZONE_FAIL: {
        return { ...state, isLoading: false };
      }

    default:
      return { ...state };
  }
};
export default zoneManagementreducer;
