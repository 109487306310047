import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit, startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { BOOKING_HISTORY, DOWNLOAD_BOOKING_HISTORY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { IoSearchOutline } from 'react-icons/io5';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';

const BookingHistory = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [allBookingHistoryListData, setAllBookingHistoryListData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  const bookingHistoryList = useSelector((state) => state.bookingHistory.bookingHistories);

  const bookingHistory = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({ type: BOOKING_HISTORY, payload: data });
    },
    [startDate, endDate]
  );
  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      bookingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  useEffect(() => {
    setAllBookingHistoryListData(
      _.filter(
        bookingHistoryList,
        (item) =>
          startsWith(_.get(item, 'customer_user_booked.name'), searchText) ||
          startsWith(_.get(item, 'idTag'), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'estimated_amount'), 2).toString(), searchText) ||
          startsWith(moment(_.get(item, 'schedule_datetime')).format('HH:mm'), searchText) ||
          startsWith(moment(_.get(item, 'schedule_datetime')).format('DD-MM-YYYY'), searchText) ||
          startsWith(_.get(item, 'charger.charging_station.name'), searchText) ||
          startsWith(_.get(item, 'id'), searchText) ||
          startsWith(_.get(item, 'status'), searchText)
      )
    );
  }, [searchText, bookingHistoryList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    bookingHistory();
  }, []);

  // Download Excel Functionality

  const downloadBookingHistory = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '', status_array: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          report: 'bookinghistory',
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          report: 'bookinghistory',
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          report: 'bookinghistory',
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          report: 'bookinghistory',
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History'}.xlsx`);
              }
            }
          },
        });
      }
    } else {
      const data = {
        excel: true,
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        report: 'bookinghistory',
      };
      dispatch({
        type: DOWNLOAD_BOOKING_HISTORY,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Booking History'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadBookingHistory();
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      bookingHistory(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      bookingHistory(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', status_array: '' };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  const allStatus = [
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Rescheduled', value: 'rescheduled' },
  ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Booking History" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    <Col xl={`${showSearchBar ? 6 : 7}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).startOf('day')}
                          initialValueOfEndDate={moment(new Date()).endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block">
                      <div className="report-excel-icon" onClick={downloadFile}>
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booking Id</span>
                              <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Mobile No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>OTP</span>
                              <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Station Name</span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>State</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>City</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>OCPP ID</span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Vendor Name</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Connector Id</span>
                              <span className="ico" onClick={() => handleSorting('connector_id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booking Date</span>
                              <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booking SlotTime</span>
                              <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booking Amount(INR)</span>
                              <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Status</span>
                              <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allBookingHistoryListData) ? (
                          <tr>
                            <td colSpan={11} className="border-0">
                              <div className="empty-data-block">No Booking History Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allBookingHistoryListData, (item, key) => {
                            return (
                              <>
                                <tr key={`booking-history-list-${key}`}>
                                  <td>{key + 1}</td>
                                  <td>{_.get(item, 'id', '')}</td>
                                  <td>{_.get(item, 'customer_user_booked.name', 'Guest User')}</td>
                                  <td>{_.get(item, 'customer_user_booked.phone', '')}</td>
                                  <td>{_.get(item, 'idTag', '')}</td>
                                  <td>{_.get(item, 'charger.charging_station.name', '')}</td>
                                  {/* <td>{_.get(item, 'charger.charging_station.state', '-')}</td>
                                  <td>{_.get(item, 'charger.charging_station.city', '-')}</td> */}
                                  <td>{_.get(item, 'charger.charger_id', '')}</td>
                                  {/* <td>{_.get(item, 'charger.oem.vendor.name')}</td> */}
                                  <td>{_.get(item, 'connectorId', '')}</td>
                                  <td>{_.get(item, 'schedule_datetime') ? moment(_.get(item, 'schedule_datetime')).format('DD-MM-YYYY') : ''}</td>
                                  <td>{_.get(item, 'schedule_datetime') ? moment(_.get(item, 'schedule_datetime')).tz('Asia/Dubai').format('HH:mm') : ''}</td>
                                  <td>{roundOfDigit(_.get(item, 'estimated_amount', ''), 2)}</td>
                                  <td>{_.get(item, 'status')}</td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.status_array && delete values['status_array'];
              if (values.charging_station === 'All' && values.status_array === 'All') {
                const bookingHistoryData = { ...values, charging_station: '', status_array: '' };
                bookingHistory(bookingHistoryData);
              } else if (values.charging_station === 'All') {
                const bookingHistoryData = { ...values, charging_station: '' };
                bookingHistory(bookingHistoryData);
              } else if (values.status_array === 'All') {
                const bookingHistoryData = { ...values, status_array: '' };
                bookingHistory(bookingHistoryData);
              } else {
                bookingHistory(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label="Station"
                    options={
                      !changeStation
                        ? !_.isEmpty(allStation) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allStation, (station) => {
                              return { label: station.name, value: station.id };
                            }),
                          ]
                        : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select Station"
                    name="charging_station"
                    isMulti
                    value={values.charging_station}
                    onMenuScrollDown={true}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangeStation(true);
                        setFieldValue(`charging_station`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`charging_station`, '');
                        setChangeStation(false);
                      } else {
                        setChangeStation(false);
                        setFieldValue('charging_station', val);
                      }
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label="Status"
                      options={
                        !changeStatus
                          ? !_.isEmpty(allStatus) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStatus, (status) => {
                                return { label: status.label, value: status.value };
                              }),
                            ]
                          : !_.isEmpty(allStatus) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Status"
                      name="status_array"
                      isMulti
                      value={values.status_array}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStatus(true);
                          setFieldValue(`status_array`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`status_array`, '');
                          setChangeStatus(false);
                        } else {
                          setChangeStatus(false);
                          setFieldValue('status_array', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      bookingHistory();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default BookingHistory;
