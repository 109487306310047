import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
//import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
//import RadioButton from 'components/inputs/RadioButton';
import ProfilePicInput from 'components/inputs/ProfilePicInput';
import { GET_PROFILE, UPDATE_PROFILE } from 'actions/profile';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
//import DatePickerInput from 'components/inputs/DatePickerInput';

const ProfileSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .strict(true)
    .trim('Space is not allowed')
    .email('Please enter valid email id')
    .max(100, 'Text limit should be less than 100 characters.'),
  name: Yup.string().required('Name is required').trim('Space is not allowed').strict(true),
  // dob: Yup.string().required('birthDate is required'),
  // gender: Yup.string().required('gender is required'),
  // address: Yup.string().required('address is required').trim('Space is not allowed').strict(true),
  phoneNumber: Yup.string().required('phoneNumber is required'),
});

const ProfileForm = () => {
  const dispatch = useDispatch();
  //const [phoneError, setPhoneError] = useState('');
  const profileDetail = useSelector((state) => state.profile.userProfile);

  useEffect(() => {
    dispatch({ type: GET_PROFILE });
  }, []);

  const updateUserProfile = useCallback((data) => {
    dispatch({ type: UPDATE_PROFILE, payload: data });
  }, []);

  const initialValues = profileDetail
    ? {
        name: _.get(profileDetail, 'name'),
        email: _.get(profileDetail, 'email'),
        dob: moment(_.get(profileDetail, 'dob')).format('YYYY-MM-DD'),
        address: _.get(profileDetail, 'address'),
        gender: _.get(profileDetail, 'gender'),
        phoneNumber: `+${_.get(profileDetail, 'country_code')} ${_.get(profileDetail, 'phone')}`.toString(),
      }
    : {
        email: 'test@gmail.com',
        // picture: 'https://test.in/pics.jpg',
        gender: 'male',
        dob: moment(),
        address: 'abc',
        phoneNumber: '',
        name: '',
      };

  return (
    <LayoutContainer>
      <AdminHeader title="Edit Profile" backTo="/setting" />
      <div className="page-content-wrapper scrollable">
        <div className="profile-form-page__main">
          <Card>
            <div className="profile-form-page--inner">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={ProfileSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (!isValidPhoneNumber(values.phoneNumber)) {
                    //setPhoneError('Phone number is not valid!');
                  } else {
                    const profileData = _.omit(values, ['phoneNumber']);
                    updateUserProfile({ ...profileData, dob: moment(values.dob).format('MM-DD-YYYY') });
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, isSubmitting, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xxl={2} xl={3} lg={5} md={4}>
                        <div className="d-flex justify-content-center">
                          <ProfilePicInput name="picture" />
                        </div>
                      </Col>
                      <Col>
                        <div className="profile-form__block">
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label="Name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.name && touched.name ? errors.name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                error={errors.email && touched.email ? errors.email : null}
                              />
                            </Col>
                            {/* <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                isRequired
                                disabled
                                label="Phone No."
                                name="phoneNumber"
                                value={values.phoneNumber}
                                onChange={(val) => {
                                  setFieldValue(`phoneNumber`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                error={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : phoneError ? phoneError : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <DatePickerInput
                                label="Date of birth"
                                value={values.dob}
                                onChange={(dateString) => setFieldValue('dob', dateString)}
                                // error={errors.dob && touched.dob ? errors.dob : null}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label="Address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                // error={errors.address && touched.address ? errors.address : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'male'}
                                  onChange={() => setFieldValue('gender', 'male')}
                                >
                                  Male
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'female'}
                                  onChange={() => setFieldValue('gender', 'female')}
                                >
                                  Female
                                </RadioButton>
                              </div>
                            </Col> */}
                            <Col md={12}>
                              <div className="save-btn--block">
                                <Button type="submit" className="profile-save-btn" disabled={isSubmitting}>
                                  Save Changes
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </div>
      </div>
    </LayoutContainer>
  );
};
export default ProfileForm;
