import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Card from 'components/inputs/Card';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';

const SingleOemList = (props) => {
  const { onClose } = props;
  const chargeSpotDetail = useSelector((state) => state.oem.oemDetail);

  return (
    <React.Fragment>
      <div className="chargeSpot-page__main">
        <Card>
          <div className="chargeSpot-header__block">
            <div className="chargeSpot-header-name">
              <span>Charge Spot Configuration Details</span>
            </div>
            <div className="chargeSpot-close_btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <ChargeSpotBlock headingName={'Basic'}>
            <ChargeSportDetail chargeSpotText={'Charge Spot Vendor Name'} chargeSpotvalue={_.get(chargeSpotDetail, 'vendor.name')} />
            <ChargeSportDetail chargeSpotText={'Charge Spot'} chargeSpotvalue={_.get(chargeSpotDetail, 'name')} />
          </ChargeSpotBlock>
          <ChargeSpotBlock headingName={'Input Power'}>
            <ChargeSportDetail chargeSpotText={'Input Voltage(AC)'} chargeSpotvalue={_.get(chargeSpotDetail, 'input_voltage')} />
            <ChargeSportDetail chargeSpotText={'Power Factor'} chargeSpotvalue={_.get(chargeSpotDetail, 'power_factor')} />
            <ChargeSportDetail chargeSpotText={'Efficiency'} chargeSpotvalue={_.get(chargeSpotDetail, 'efficiency')} />
            <ChargeSportDetail chargeSpotText={'Input Frequency'} chargeSpotvalue={_.get(chargeSpotDetail, 'input_frequency')} />
            <ChargeSportDetail chargeSpotText={'Wires'} chargeSpotvalue={_.get(chargeSpotDetail, 'wires')} />
          </ChargeSpotBlock>
          <ChargeSpotBlock headingName={'Environment'}>
            <ChargeSportDetail chargeSpotText={'Ambient Temperature'} chargeSpotvalue={_.get(chargeSpotDetail, 'ambient_temperature')} />
            <ChargeSportDetail chargeSpotText={'storage Temperature'} chargeSpotvalue={_.get(chargeSpotDetail, 'storage_temperature')} />
            <ChargeSportDetail chargeSpotText={'Altitude'} chargeSpotvalue={_.get(chargeSpotDetail, 'altitude')} />
            <ChargeSportDetail chargeSpotText={'Humidity'} chargeSpotvalue={_.get(chargeSpotDetail, 'humidity')} />
          </ChargeSpotBlock>
          <ChargeSpotBlock headingName={'User Interface & Control'}>
            <ChargeSportDetail chargeSpotText={'Display'} chargeSpotvalue={_.get(chargeSpotDetail, 'display')} />
            <ChargeSportDetail chargeSpotText={'Language'} chargeSpotvalue={_.get(chargeSpotDetail, 'language')} />
            <ChargeSportDetail chargeSpotText={'Push Button'} chargeSpotvalue={_.get(chargeSpotDetail, 'push_button')} />
            <ChargeSportDetail chargeSpotText={'User Authentication'} chargeSpotvalue={_.get(chargeSpotDetail, 'user_authentication')} />
          </ChargeSpotBlock>
          <ChargeSpotBlock headingName={'Communication'}>
            <ChargeSportDetail chargeSpotText={'Charger & Vehicle'} chargeSpotvalue={_.get(chargeSpotDetail, 'charger_vehicle_communication')} />
            <ChargeSportDetail chargeSpotText={'Charger & CMS'} chargeSpotvalue={_.get(chargeSpotDetail, 'charger_cms_communication')} />
          </ChargeSpotBlock>
          <ChargeSpotBlock headingName={'Mechanical'}>
            <ChargeSportDetail chargeSpotText={'Ingress Protection'} chargeSpotvalue={_.get(chargeSpotDetail, 'ingress_protection')} />
            <ChargeSportDetail chargeSpotText={'Enclosure Protection'} chargeSpotvalue={_.get(chargeSpotDetail, 'enclosure_protection')} />
            <ChargeSportDetail chargeSpotText={'Cooling'} chargeSpotvalue={_.get(chargeSpotDetail, 'cooling')} />
            <ChargeSportDetail chargeSpotText={'Wire Length'} chargeSpotvalue={_.get(chargeSpotDetail, 'wire_length')} />
            <ChargeSportDetail chargeSpotText={'Dimension(WXHXD)'} chargeSpotvalue={_.get(chargeSpotDetail, 'dimension_w_h_d')} />
          </ChargeSpotBlock>
          <div className="chargeSpot-connectors__block">
            <div className="chargeSpot-heading">Connectors</div>
            <div className="ac-connector-table">
              <ChargeSpotBlock headingName={'AC Connectors (Slow)'}>
                {!_.isEmpty(_.filter(_.get(chargeSpotDetail, 'plugs'), { type: 'AC' })) ? (
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Format</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Power Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charging Speed</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>Connector Name</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>Code</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(_.filter(_.get(chargeSpotDetail, 'plugs'), { type: 'AC' }), (item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{_.get(item, 'connector_type.name')}</td>
                                <td>{_.get(item, 'format')}</td>
                                <td>{_.get(item, 'power_type')}</td>
                                <td>{_.get(item, 'charge_speed.name')}</td>
                                <td>{_.get(item, 'name')}</td>
                                <td>{_.get(item, 'code')}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="empty__data">No AC Connectors</div>
                )}
              </ChargeSpotBlock>
            </div>
            <div className="dc-connector-table">
              <ChargeSpotBlock headingName={'DC Connectors (Normal/Fast)'}>
                {!_.isEmpty(_.filter(_.get(chargeSpotDetail, 'plugs'), { type: 'DC' })) ? (
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Format</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Power Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charging Speed</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>Connector Name</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>Code</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(_.filter(_.get(chargeSpotDetail, 'plugs'), { type: 'DC' }), (item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{_.get(item, 'connector_type.name')}</td>
                                <td>{_.get(item, 'format')}</td>
                                <td>{_.get(item, 'power_type')}</td>
                                <td>{_.get(item, 'charge_speed.name')}</td>
                                <td>{_.get(item, 'name')}</td>
                                <td>{_.get(item, 'code')}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="empty__data">No DC Connectors</div>
                )}
              </ChargeSpotBlock>
            </div>
          </div>
          <div className="chargeSpot-close-button-box" onClick={onClose}>
            <Button className="chargeSpot-close-button">Close</Button>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export const ChargeSpotBlock = (props) => {
  const { headingName, children } = props;

  return (
    <div className="chargeSpot-body__block">
      <div className="chargeSpot-inner--block">
        <div className="chargeSpot--header">
          <span className="chargeSpot-header-text">{headingName}</span>
        </div>
        <div className="chargeSpot-content">{children}</div>
      </div>
    </div>
  );
};
ChargeSpotBlock.propTypes = {
  headingName: PropTypes.string,
  children: PropTypes.node,
};

export const ChargeSportDetail = (props) => {
  const { chargeSpotText, chargeSpotvalue } = props;
  return (
    <div className="chargeSpot-content-inner">
      <div className="chargeSpot-content-text">{chargeSpotText}</div>
      <div className="chargeSpot-content-detail">{chargeSpotvalue}</div>
    </div>
  );
};

ChargeSportDetail.propTypes = {
  chargeSpotText: PropTypes.string,
  chargeSpotvalue: PropTypes.string,
};

SingleOemList.propTypes = {
  onClose: PropTypes.func,
};

export default SingleOemList;
