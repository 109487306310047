export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_FAIL = 'CREATE_VEHICLE_FAIL';

export const CREATE_MULTIPLE_VEHICLE = 'CREATE_MULTIPLE_VEHICLE';
export const CREATE_MULTIPLE_VEHICLE_SUCCESS = 'CREATE_MULTIPLE_VEHICLE_SUCCESS';
export const CREATE_MULTIPLE_VEHICLE_FAIL = 'CREATE_MULTIPLE_VEHICLE_FAIL';

export const FETCH_VEHICLE = 'FETCH_VEHICLE';
export const FETCH_VEHICLE_SUCCESS = 'FETCH_VEHICLE_SUCCESS';
export const FETCH_VEHICLE_FAIL = 'FETCH_VEHICLE_FAIL';

export const GET_VEHICLE = 'GET_VEHICLE';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_FAIL = 'GET_VEHICLE_FAIL';

export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAIL = 'DELETE_VEHICLE_FAIL';

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAIL = 'UPDATE_VEHICLE_FAIL';

export const DOWNLOAD_EXCEL_VEHICLE = 'DOWNLOAD_EXCEL_VEHICLE';
export const DOWNLOAD_EXCEL_VEHICLE_SUCCESS = 'DOWNLOAD_EXCEL_VEHICLE_SUCCESS';
export const DOWNLOAD_EXCEL_VEHICLE_FAIL = 'DOWNLOAD_EXCEL_VEHICLE_FAIL';
