import { get, deleteRequest, post, patch } from 'utils/api';

export const getCharger = (data) => {
  return get(`/chargers`, data);
};

export const getChargerById = (id) => {
  return get(`/chargers/${id}`);
};

export const getChargersByStation = (data) => {
  const page = data.page ? data.page : 1;
  const id = data.id;
  return get(`/chargingStations/chargers/${id}?page=${parseInt(page)}`);
};

export const addCharger = (data) => {
  return post(`/chargers`, data);
};

export const deleteCharger = (id) => {
  return deleteRequest(`/chargers/${id}`);
};

export const updateCharger = (data) => {
  const chargerId = data.chargerId;
  delete data['chargerId'];
  return patch(`/chargers/${chargerId}`, data);
};

export const charger = {
  getCharger,
  getChargerById,
  addCharger,
  deleteCharger,
  getChargersByStation,
  updateCharger,
};
