import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_ZONES,
  FETCH_ZONES_SUCCESS,
  FETCH_ZONES_FAIL,
  CREATE_ZONES,
  CREATE_ZONES_SUCCESS,
  CREATE_ZONES_FAIL,
  DELETE_ZONES,
  DELETE_ZONES_SUCCESS,
  DELETE_ZONES_FAIL,
  UPDATE_ZONES,
  UPDATE_ZONES_SUCCESS,
  UPDATE_ZONES_FAIL,
  GET_ZONE,
  GET_ZONE_SUCCESS,
  GET_ZONE_FAIL,
} from 'actions/zoneManagement';

function* getZones(action) {
  try {
    const response = yield call(API.getZones, action.payload);
    yield put({ type: FETCH_ZONES_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ZONES_FAIL, payload: { error: e } });
  }
}
function* createZones(action) {
  try {
    const response = yield call(API.addZones, action.payload);
    yield put({ type: CREATE_ZONES_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_ZONES_FAIL, payload: { error: e } });
  }
}

function* deleteZones(action) {
  try {
    const response = yield call(API.deleteZones, action.payload);
    yield put({ type: DELETE_ZONES_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_ZONES_FAIL, payload: { error: e } });
  }
}

function* updateZones(action) {
  try {
    const response = yield call(API.updateZones, action.payload);
    yield put({ type: UPDATE_ZONES_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_ZONES_FAIL, payload: { error: e } });
  }
}

function* getZonesbyID(action) {
  try {
    const response = yield call(API.getZonesbyId, action.payload);
    yield put({ type: GET_ZONE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ZONE_FAIL, payload: { error: e } });
  }
}

function* zoneManagement() {
  yield takeLatest(FETCH_ZONES, getZones),
    yield takeLatest(CREATE_ZONES, createZones),
    yield takeLatest(DELETE_ZONES, deleteZones),
    yield takeLatest(UPDATE_ZONES, updateZones),
    yield takeLatest(GET_ZONE, getZonesbyID);
}

export default zoneManagement;
