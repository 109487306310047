import { USER_CHARGING_HISTORY, USER_CHARGING_HISTORY_SUCCESS, USER_CHARGING_HISTORY_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  userChargingHistories: [],
};

const userChargingHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_CHARGING_HISTORY: {
      return { ...state, isLoading: true };
    }

    case USER_CHARGING_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userChargingHistories: payload,
      };
    }

    case USER_CHARGING_HISTORY_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default userChargingHistoryReducer;
