import { get, post, deleteRequest, patch } from 'utils/api';

export const getCountry = (data) => {
  return get(`/country`, data);
};

export const getCountryById = (id) => {
  return get(`/country/${id}`);
};

export const addCountry = (data) => {
  return post(`/country`, data);
};

export const updateCountry = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/country/${id}`, data);
};

export const deleteCountry = (id) => {
  return deleteRequest(`/country/${id}`);
};

export const getState = (data) => {
  return get(`/state`, data);
};

export const getStateById = (id) => {
  return get(`/state/${id}`);
};

export const addState = (data) => {
  return post(`/state`, data);
};

export const updateState = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/state/${id}`, data);
};

export const deleteState = (id) => {
  return deleteRequest(`/state/${id}`);
};

export const getCity = (data) => {
  return get('/city', data);
};

export const getCityById = (id) => {
  return get(`/city/${id}`);
};

export const addCity = (data) => {
  return post(`/city`, data);
};

export const getCityByState = (data) => {
  return post(`/city/with_filter`, data);
};

export const updateCity = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/city/${id}`, data);
};

export const deleteCity = (id) => {
  return deleteRequest(`/city/${id}`);
};

export const getArea = (data) => {
  return get('/area', data);
};

export const getAreaById = (id) => {
  return get(`/area/${id}`);
};

export const addArea = (data) => {
  return post(`/area`, data);
};

export const updateArea = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/area/${id}`, data);
};

export const deleteArea = (id) => {
  return deleteRequest(`/area/${id}`);
};
export const address = {
  getCountry,
  addCountry,
  getCountryById,
  getCityByState,
  deleteCountry,
  updateCountry,
  getState,
  addState,
  getStateById,
  deleteState,
  updateState,
  getCity,
  getCityById,
  addCity,
  updateCity,
  deleteCity,
  getArea,
  getAreaById,
  addArea,
  updateArea,
  deleteArea,
};
