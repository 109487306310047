import { post, get, deleteRequest, patch } from 'utils/api';

export const addTenant = (data) => {
  return post(`/companies`, data);
};

export const getTenant = (data) => {
  return get(`/companies`, data);
};

export const getTenantById = (id) => {
  return get(`/companies/${id}`);
};

export const updateTenant = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/companies/${id}`, data);
};

export const updateTenantContactInformation = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/users/${id}`, data);
};

export const deleteTenant = (id) => {
  return deleteRequest(`/companies/${id}`);
};

export const tenant = {
  addTenant,
  getTenant,
  getTenantById,
  updateTenant,
  updateTenantContactInformation,
  deleteTenant,
};
