import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DropDown from 'components/inputs/DropDown';
import Button from 'components/inputs/Button';
import Avatar from 'components/inputs/Avatar';
import DummyImage from 'assets/images/icons/dummyImage.png';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsBell } from 'react-icons/bs';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { BiChevronLeft } from 'react-icons/bi';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_SIDEBAR } from 'actions/sidebar';
import { SidebarContext } from '../layout';
import CZIconLogo from 'assets/images/icons/czIconWhite.svg';
import { useHistory, Link } from 'react-router-dom';
import { MdOutlineLogout } from 'react-icons/md';
import { FiUser } from 'react-icons/fi';
import { cookie } from 'utils/cookies/cookies';
import { FETCH_NOTIFICATION, REMOVE_NOTIFICATION } from 'actions/notification';
import ActivityItem from 'components/general/ActivityItem';
import { AiOutlineCloudSync } from 'react-icons/ai';
import { MdOutlinePersonOutline } from 'react-icons/md';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import LogoutModal from 'view/logout/LogoutModal';
import { LOGOUT } from 'actions/authAction';

const AdminHeader = (props) => {
  const { title, backTo, showButton, buttonText, onClickButton } = props;
  const dispatch = useDispatch();
  const token = cookie.get('token');

  const sidebarData = useContext(SidebarContext);
  const history = useHistory();
  const profileData = useSelector((state) => state.profile.userProfile);
  const allGeneralNotification = useSelector((state) => state.notification.notifications);
  const notification = useSelector((state) => state.notification.notificationCount);

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const getGeneralNotification = useCallback(() => {
    const data = {
      limit: 10,
    };
    dispatch({ type: FETCH_NOTIFICATION, payload: data });
  }, []);

  const handleSidebarToggle = () => {
    dispatch({ type: SHOW_SIDEBAR });
  };

  const logOut = useCallback(() => {
    const logOutData = {
      refreshToken: token,
    };
    dispatch({
      type: LOGOUT,
      payload: logOutData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 204) {
            cookie.clean();
            window.location.href = '/login';
          }
        }
      },
    });
  }, []);

  const handleCloseLogoutModel = () => {
    setShowLogoutModal(false);
  };

  const removeNotification = () => {
    dispatch({ type: REMOVE_NOTIFICATION });
  };

  return (
    <React.Fragment>
      <div className="header-container__main">
        <div className="header-container__inner">
          <div className="header-left-content__block">
            {sidebarData.showToggle && (
              <div className="header-sidebar-toggle">
                <div className="header-logo--img" onClick={handleSidebarToggle}>
                  <img src={CZIconLogo} alt="" />
                </div>
              </div>
            )}
            <div className="header-left-icon">
              {backTo && !_.isEmpty(backTo) ? (
                <Link to={backTo} className="back-to-link">
                  <BiChevronLeft />
                </Link>
              ) : (
                <HiOutlineMenuAlt1 />
              )}
            </div>
            <div className="header-page--title">
              <span>{title}</span>
            </div>
            {showButton && (
              <div className="header-left--btn">
                <Button className="header--btn" onClick={onClickButton}>
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
          <div className="header-right-content__block">
            <div className="notification-icon">
              <DropDown
                popupClass="bottom-left notification-list-menu scrollable"
                trigger={
                  <div className="notification--block" onClick={removeNotification}>
                    <BsBell onClick={() => getGeneralNotification()} />
                    {notification > 0 && <span className="notification-count"> {notification}</span>}
                  </div>
                }
                triggerClass={'p-2'}
              >
                {/* <ul>
                  <li className="item">
                    <DropDownList
                      icon={<BiError />}
                      text={
                        <>
                          An error occurred <span className="meta-info">to the</span> Charger 4 <span className="meta-info">i(ID: Gt586142) in </span>{' '}
                          Malleswarm
                        </>
                      }
                      time={'Fri, 26 Nov, 11:24 AM'}
                    />
                  </li>
                  <li className="item">
                    <DropDownList
                      icon={<BiUser />}
                      text={
                        <>
                          Gaye Garland <span className="meta-info">has now acces to</span> Admin role
                        </>
                      }
                      time={'Fri, 26 Nov, 11:24 AM'}
                      className="list--box"
                    />
                  </li>
                </ul> */}
                {_.map(
                  _.filter(allGeneralNotification, (item) => {
                    return item.message_type !== 'firmware_update';
                  }),
                  (notification, index) => (
                    <div onClick={() => history.push('/notification')}>
                      <ActivityItem
                        key={`notification-${index}`}
                        icon={notification.type === 'charger' ? <AiOutlineCloudSync /> : <MdOutlinePersonOutline />}
                        text={
                          <>
                            {_.get(notification, 'from', '')} {''}
                            <span className="meta-info">{_.get(notification, 'message', '')}</span> {_.get(notification, 'to', '')}
                          </>
                        }
                        date={moment(_.get(notification, 'createdAt')).format('dddd, DD MMMM, h:mm A')}
                      />
                    </div>
                  )
                )}
              </DropDown>
            </div>
            <DropDown
              popupClass="bottom-left user-info-menu"
              trigger={
                <>
                  <div className="user-info--block">
                    <div className="user__profile-pic">
                      <Avatar imgSrc={DummyImage} name="userProfile" />
                    </div>
                    <div className="user__info">
                      <span className="user--name text-ellipsis">{_.get(profileData, 'name', '')}</span>
                      <span className="user--role text-ellipsis">{_.get(profileData, 'name', '')}</span>
                    </div>
                    <div className="arrow__icon">
                      <AiFillCaretDown />
                    </div>
                  </div>
                </>
              }
              triggerClass={'p-2'}
            >
              <ul>
                <li className="item" onClick={() => history.push('/setting?tab=profile')}>
                  <div className="user-info--block">
                    <span className="user-info-icon">
                      <FiUser color={'#3c7cdd'} size={20} />
                    </span>
                    Profile
                  </div>
                </li>
                <li
                  className="item"
                  onClick={() => {
                    setShowLogoutModal(true);
                    logOut;
                  }}
                >
                  <div className="user-info--block">
                    <span className="user-info-icon">
                      <MdOutlineLogout color={'#be210b'} size={20} />
                    </span>
                    <span>Logout</span>
                  </div>
                </li>
              </ul>
            </DropDown>
          </div>
        </div>
        {showLogoutModal && (
          <Modal show={showLogoutModal} centered onHide={handleCloseLogoutModel}>
            <LogoutModal onClose={handleCloseLogoutModel} onRemove={logOut} />
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

const DropDownList = (props) => {
  const { icon, text, time, className } = props;
  const history = useHistory();

  return (
    <div className={`notification-dropdown-list__main ${className ? className : ''}`} onClick={() => history.push('/notification')}>
      <div className="list-item--inner">
        <div className="list-icon--block">
          <div className="list_icon__inner">
            <div className="list--icon">{icon}</div>
          </div>
        </div>
        <div className="list-content__main-block">
          <div className="list-content--inner">
            <div className="list-details--content-block">
              <div className="list-details-block">{text}</div>
              <span className="list-timing">{time}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
DropDownList.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  time: PropTypes.string,
  className: PropTypes.string,
};

AdminHeader.propTypes = {
  title: PropTypes.string,
  backTo: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
};

export default AdminHeader;
