import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  WALLET_TRANSACTION,
  WALLET_TRANSACTION_SUCCESS,
  WALLET_TRANSACTION_FAIL,
} from 'actions/customer';

function* fetchCustomer(action) {
  try {
    const response = yield call(API.getCustomers, action.payload);
    yield put({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* getCustomerById(action) {
  try {
    const response = yield call(API.getUserById, action.payload);
    yield put({ type: GET_CUSTOMER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* createCustomer(action) {
  try {
    const response = yield call(API.addUser, action.payload);
    yield put({ type: CREATE_CUSTOMER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* createWalletTransaction(action) {
  try {
    const response = yield call(API.walletTransaction, action.payload);
    yield put({ type: WALLET_TRANSACTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: WALLET_TRANSACTION_FAIL, payload: { error: e } });
  }
}

function* updateCustomer(action) {
  try {
    const response = yield call(API.updateUser, action.payload);
    yield put({ type: UPDATE_CUSTOMER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* deleteCustomer(action) {
  try {
    yield call(API.deleteUser, action.payload);
    yield put({ type: DELETE_CUSTOMER_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* customer() {
  yield takeLatest(FETCH_CUSTOMER, fetchCustomer);
  yield takeLatest(GET_CUSTOMER, getCustomerById);
  yield takeLatest(CREATE_CUSTOMER, createCustomer);
  yield takeLatest(UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(DELETE_CUSTOMER, deleteCustomer);
  yield takeLatest(WALLET_TRANSACTION, createWalletTransaction);
}

export default customer;
