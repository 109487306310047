import React, { useCallback } from 'react';
import Button from 'components/inputs/Button';
import Card from 'components/inputs/Card';
import { CLEAR_CACHE } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const ClearCacheForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const getClearCache = useCallback((chargerId) => {
    const data = {
      id: chargerId,
    };
    dispatch({
      type: CLEAR_CACHE,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Clear cache successfully');
          }
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">CLEAR CACHE</div>
      <Card>
        <div className="p-2">
          <Button onClick={() => getClearCache(chargerId)}>Clear Cache</Button>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default ClearCacheForm;
