import Button from 'components/inputs/Button';
import TextInput from 'components/inputs/Input';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { CREATE_NEW_PASSWORD } from 'actions/authAction';
import ErrorMessage from 'components/inputs/ErrorMessage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ResetSchema = Yup.object().shape({
  password: Yup.string().required('Password is required').trim('Space is not allowed').strict(true),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Both passwords must match')
    .required('Confirm Password is required')
    .trim('Space is not allowed')
    .strict(true),
  otp: Yup.string().required('OTP is required').min(4, 'OTP must be 4 digits'),
});

const ResetPassword = (props) => {
  const { phoneData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState('');

  const resetPassword = useCallback((data) => {
    dispatch({
      type: CREATE_NEW_PASSWORD,
      payload: { ...data, ...phoneData },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Password change successfully');
            history.push('/login');
          } else {
            setErrorMsg(res.data.message);
          }
        }
      },
    });
  }, []);

  return (
    <div className="resetPassword-content--inner">
      <div className="title">Create New Password</div>
      <div className="resetPassword-form__block">
        <Formik
          initialValues={{ password: '', confirm_password: '', otp: '' }}
          validationSchema={ResetSchema}
          onSubmit={(values, { setSubmitting }) => {
            const resetPasswordValue = _.omit(values, ['confirm_password']);
            resetPassword(resetPasswordValue);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-content-block">
                <Row>
                  <Col lg={12}>
                    <div className="otp-input__main">
                      <div className="otp-inner-text">
                        <span>Please enter code sent to {`+${_.get(phoneData, 'country_code')} ${_.get(phoneData, 'phone')}`}</span>
                      </div>
                      <div className="otp-input--box">
                        <div className="otp-input-box--inner">
                          <OtpInput
                            inputStyle="otp--box"
                            name="otp"
                            value={values.otp}
                            onChange={(code) => {
                              setFieldValue('otp', code);
                            }}
                            separator={<span style={{ width: '1.5rem' }} />}
                            isInputNum={true}
                            shouldAutoFocus={true}
                          />
                          {errors.otp && touched.otp ? (
                            <ErrorMessage>{errors.otp}</ErrorMessage>
                          ) : errorMsg ? (
                            <ErrorMessage>{errorMsg}</ErrorMessage>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <TextInput
                      label="Password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      value={values.password}
                      onChange={handleChange}
                      error={errors.password && touched.password ? errors.password : null}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <TextInput
                      label="Confirm Password"
                      name="confirm_password"
                      type="password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : null}
                    />
                  </Col>
                </Row>
                <div className="form-button--block">
                  <Button type="submit" className="reset--btn">
                    Reset Password
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  phoneData: PropTypes.func,
};
export default ResetPassword;
