import { CHARGER_STATUS, CHARGER_STATUS_SUCCESS, CHARGER_STATUS_FAIL } from '../actions/chargerStatus';

import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargerStatus(action) {
  try {
    const response = yield call(API.getChargerStatus, action.payload);
    yield put({ type: CHARGER_STATUS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGER_STATUS_FAIL, payload: { error: e } });
  }
}

function* chargerStatus() {
  yield takeLatest(CHARGER_STATUS, fetchChargerStatus);
}
export default chargerStatus;
