import React, { useCallback, useState } from 'react';
import { cookie } from 'utils/cookies/cookies';
import { Modal } from 'react-bootstrap';
import LogoutModal from './LogoutModal';
import { useHistory } from 'react-router-dom';
import { LOGOUT } from 'actions/authAction';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';

const Logout = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = cookie.get('token');
  const onClickRemove = useCallback(() => {
    const logOutData = {
      refreshToken: token,
    };
    dispatch({
      type: LOGOUT,
      payload: logOutData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 204) {
            cookie.clean();
            window.location.href = '/login';
          }
        }
      },
    });
  }, []);

  const handleCloseLogoutModel = () => {
    setShowLogoutModal(false);
    history.push('/dashboard');
  };

  return (
    <>
      <div className="logout-main__page">
        {showLogoutModal && (
          <Modal show={showLogoutModal} centered onHide={handleCloseLogoutModel}>
            <LogoutModal onClose={handleCloseLogoutModel} onRemove={onClickRemove} />
          </Modal>
        )}
      </div>
    </>
  );
};
export default Logout;
