import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit, startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_MONEY_TRANSACTION_PG_REPORT, MONEY_TRANSACTION_PG_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import fileDownload from 'js-file-download';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';

const MoneyTransactionPGReport = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [allMoneyTransactionPGReportData, setAllMoneyTransactionPGReportData] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [changePaymentStatus, setChangePaymentStatus] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const moneyTransactionPGReportList = useSelector((state) => state.moneyTransactionPgReport.moneyTransactionPgReports);

  const moneyTransactionPGReport = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({ type: MONEY_TRANSACTION_PG_REPORT, payload: data });
    },
    [startDate, endDate]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      moneyTransactionPGReport(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      moneyTransactionPGReport(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      moneyTransactionPGReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  useEffect(() => {
    moneyTransactionPGReport();
  }, []);

  useEffect(() => {
    setAllMoneyTransactionPGReportData(
      _.filter(
        moneyTransactionPGReportList,
        (item) =>
          startsWith(_.get(item, 'details.status'), searchText) ||
          startsWith(_.get(item, 'details.amount').toString(), searchText) ||
          startsWith(_.get(item, 'details.currency'), searchText) ||
          startsWith(_.get(item, 'details.created_at').toString(), searchText) ||
          startsWith(_.get(item, 'transaction_id'), searchText) ||
          startsWith(_.get(item, 'details.email'), searchText) ||
          startsWith(_.get(item, 'details.contact'), searchText) ||
          startsWith(_.get(item, 'details.order_id'), searchText) ||
          startsWith(_.get(item, 'details.method'), searchText) ||
          startsWith(_.get(item, 'details.bank'), searchText) ||
          startsWith(_.get(item, 'id'), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'details.amount') / 100, 2).toString(), searchText) ||
          startsWith(moment(_.get(item, 'createdAt')).format('DD/MM/YYYY H:mm:ss'), searchText)
      )
    );
  }, [searchText, moneyTransactionPGReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  // Download Excel Functionality

  const downloadMoneyTransactionPGReport = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.method === 'All' && advanceFilterData.status === 'All') {
        const filterData = { ...advanceFilterData, method: '', status: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.method === 'All') {
        const filterData = { ...advanceFilterData, method: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.status === 'All') {
        const filterData = { ...advanceFilterData, status: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
              }
            }
          },
        });
      }
    } else {
      const data = {
        excel: true,
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({
        type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Money Transaction PG Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadMoneyTransactionPGReport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { method: '', status: '' };

  const allPaymentMethod = [
    { label: 'Net banking', value: 'netbanking' },
    { label: 'Card', value: 'card' },
    { label: 'Wallet', value: 'wallet' },
    { label: 'UPI', value: 'upi' },
  ];

  const allPaymentStatus = [
    { label: 'Captured', value: 'captured' },
    { label: 'Failed', value: 'failed' },
    { label: 'Authorized', value: 'authorized' },
  ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Money Transaction PG Report" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    <Col xl={`${showSearchBar ? 6 : 7}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).startOf('day')}
                          initialValueOfEndDate={moment(new Date()).endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block" onClick={downloadFile}>
                      <div className="report-excel-icon">
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                    {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>RazorPay ID</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>User Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Mobile No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Order ID</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Payment Status</span>
                              {/* <span className="ico" onClick={() => handleSorting('details.status', sortByItem)}>
                                <BiSort size={15} />
                              </span> */}
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Error Message</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Payment Method</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Payment Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Transaction ID</span>
                              <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Currency</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Amount</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Transaction DateTime</span>
                              {/* <span className="ico" onClick={() => handleSorting('transaction_status', sortByItem)}>
                                <BiSort size={15} />
                              </span> */}
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allMoneyTransactionPGReportData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No Money Transaction PG Report Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(
                            _.filter(allMoneyTransactionPGReportData, (item) => item !== null),
                            (item, key) => {
                              const amount = isNaN(_.get(item, 'details.amount', '') / 100)
                                ? '-'
                                : roundOfDigit(_.get(item, 'details.amount', '') / 100, 2);
                              let paymentType;
                              if (item.details) {
                                if (item.details.method === 'netbanking') {
                                  paymentType = item.details.bank;
                                } else if (item.details.method === 'wallet') {
                                  paymentType = item.details.wallet;
                                } else if (item.details.method === 'upi') {
                                  paymentType = item.details.vpa;
                                } else if (item.details.method === 'card') {
                                  if (item.details.card) {
                                    paymentType = item.details.card.network;
                                  } else {
                                    paymentType = '';
                                  }
                                } else {
                                  paymentType = '';
                                }
                              }
                              return (
                                <>
                                  <tr key={`money-transactionPG-report-${key}`}>
                                    <td>{key + 1}</td>
                                    <td>{_.get(item, 'transaction_id', '')}</td>
                                    <td>{_.get(item, 'details.email', '')}</td>
                                    <td>{_.get(item, 'details.contact', '')}</td>
                                    <td>{_.get(item, 'details.order_id', '')}</td>
                                    <td>{_.get(item, 'details.status', '')}</td>
                                    <td>{_.get(item, 'details.error_code', '')}</td>
                                    <td>{_.get(item, 'details.method', '')}</td>
                                    <td>{paymentType}</td>
                                    <td>{_.get(item, 'id', '-')}</td>
                                    <td>{_.get(item, 'details.currency', '')}</td>
                                    <td>{amount}</td>
                                    <td>{item.createdAt ? moment(item.createdAt).tz('Asia/Dubai').format('DD/MM/YYYY H:mm:ss') : '-'}</td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.method && delete values['method'];
              !values.status && delete values['status'];
              if (values.method === 'All' && values.status === 'All') {
                const moneyTransactionPGReportData = { ...values, method: '', status: '' };
                moneyTransactionPGReport(moneyTransactionPGReportData);
              } else if (values.method === 'All') {
                const moneyTransactionPGReportData = { ...values, method: '' };
                moneyTransactionPGReport(moneyTransactionPGReportData);
              } else if (values.status === 'All') {
                const moneyTransactionPGReportData = { ...values, status: '' };
                moneyTransactionPGReport(moneyTransactionPGReportData);
              } else {
                moneyTransactionPGReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label="Payment Method"
                    options={
                      !changePaymentMethod
                        ? !_.isEmpty(allPaymentMethod) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allPaymentMethod, (item) => {
                              return { label: item.label, value: item.value };
                            }),
                          ]
                        : !_.isEmpty(allPaymentMethod) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select Payment Method"
                    name="method"
                    isMulti
                    value={values.method}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangePaymentMethod(true);
                        setFieldValue(`method`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`method`, '');
                        setChangePaymentMethod(false);
                      } else {
                        setChangePaymentMethod(false);
                        setFieldValue('method', val);
                      }
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label="Payment Status"
                      options={
                        !changePaymentStatus
                          ? !_.isEmpty(allPaymentStatus) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allPaymentStatus, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allPaymentStatus) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Payment Status"
                      name="status"
                      isMulti
                      value={values.status}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangePaymentStatus(true);
                          setFieldValue(`status`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`status`, '');
                          setChangePaymentStatus(false);
                        } else {
                          setChangePaymentStatus(false);
                          setFieldValue('status', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      moneyTransactionPGReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default MoneyTransactionPGReport;
