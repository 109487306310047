import { STATION_UNIT_CONSUMPTION, STATION_UNIT_CONSUMPTION_SUCCESS, STATION_UNIT_CONSUMPTION_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  stationUnitConsumptions: [],
};

const stationUnitConsumptionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STATION_UNIT_CONSUMPTION: {
      return { ...state, isLoading: true };
    }
    case STATION_UNIT_CONSUMPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationUnitConsumptions: payload,
      };
    }

    case STATION_UNIT_CONSUMPTION_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default stationUnitConsumptionReducer;
