import {
  FETCH_TARIFF,
  FETCH_TARIFF_SUCCESS,
  FETCH_TARIFF_FAIL,
  DELETE_TARIFF,
  DELETE_TARIFF_SUCCESS,
  DELETE_TARIFF_FAIL,
  UPDATE_TARIFF,
  UPDATE_TARIFF_SUCCESS,
  UPDATE_TARIFF_FAIL,
  CREATE_TARIFF,
  CREATE_TARIFF_SUCCESS,
  CREATE_TARIFF_FAIL,
  GET_TARIFF,
  GET_TARIFF_SUCCESS,
  GET_TARIFF_FAIL,
} from 'actions/tariff';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  tariff: [],
  tariffDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tariffReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TARIFF:
    case DELETE_TARIFF:
    case FETCH_TARIFF:
    case CREATE_TARIFF:
    case GET_TARIFF: {
      return { ...state, isLoading: true };
    }

    case FETCH_TARIFF_SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          tariff: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          tariff: _.concat(state.tariff, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_TARIFF_SUCCESS: {
      return {
        ...state,
        tariffDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_TARIFF_SUCCESS: {
      toast.success('Tariff added successfully');
      return {
        ...state,
        tariff: _.concat(state.tariff, payload),
        isLoading: false,
      };
    }

    case UPDATE_TARIFF_SUCCESS: {
      toast.success('Tariff updated successfully');
      return {
        ...state,
        tariff: state.tariff.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_TARIFF_SUCCESS: {
      toast.success('Tariff deleted successfully');
      return {
        ...state,
        tariff: state.tariff.filter((data) => data.id !== payload),
      };
    }
    case DELETE_TARIFF_FAIL:
    case UPDATE_TARIFF_FAIL:
    case FETCH_TARIFF_FAIL:
    case CREATE_TARIFF_FAIL:
    case GET_TARIFF_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default tariffReducer;
