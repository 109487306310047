import { STATION_CHARGING_SUMMARY_SUCCESS, STATION_CHARGING_SUMMARY_FAIL, STATION_CHARGING_SUMMARY } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  stationChargingSummaries: [],
};

const stationChargingSummaryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STATION_CHARGING_SUMMARY: {
      return { ...state, isLoading: true };
    }
    case STATION_CHARGING_SUMMARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationChargingSummaries: payload,
      };
    }

    case STATION_CHARGING_SUMMARY_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default stationChargingSummaryReducer;
