import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_ZONES, UPDATE_ZONES } from 'actions/zoneManagement';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { FETCH_TARIFF } from 'actions/tariff';

const zoneSchema = Yup.object().shape({
  name: Yup.string(),
});

const ZonesForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();

  const allTariffList = useSelector((state) => state.tariff.tariff);
  const allChargerList = useSelector((state) => state?.chargingStation?.chargingStations);
  const zonesDetails = useSelector((state) => state?.zoneManagement?.zonesDetail);
  const isLoading = useSelector((state) => state?.zoneManagement?.isLoading);

  const addzones = useCallback((data) => {
    dispatch({
      type: CREATE_ZONES,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const getTariff = useCallback((data = {}) => {
    dispatch({ type: FETCH_TARIFF, payload: data });
  }, []);

  const updatezones = useCallback((data) => {
    dispatch({
      type: UPDATE_ZONES,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
    getTariff();
  }, []);

  const initialValues = isEdit
    ? {
        ...zonesDetails,
        chargers: _.map(_.get(zonesDetails, 'chargers'), (item) => item.id),
      }
    : {
        name: '',
        chargers: '',
      };
  return (
    <React.Fragment>
      <div className="zone-form-page__main">
        <Card>
          <div className="zone-form-header__block">
            <div className="zone-header-name">
              <span>{isEdit ? 'Edit' : 'Add'} Zones</span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="zone-form-body__block">
            <div className="zone-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={zoneSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updatezones(values);
                  } else {
                    addzones(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label=" Zone Name"
                          placeholder="Name"
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          isRequired
                          className="roles-select__box"
                          label="Charger(s)"
                          placeholder="Link Chargers to Zone"
                          name="chargers"
                          options={allChargerList.flatMap((item) =>
                            item.chargers.map((charger) => {
                              return { label: charger?.charger_id, value: charger?.id };
                            })
                          )}
                          isMulti
                          value={values.chargers}
                          onChange={(val) => {
                            setFieldValue('chargers', val);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          isClearable
                          className="roles-select__box"
                          label="PLU"
                          placeholder="Assign PLU"
                          name="tariff"
                          options={_.map(allTariffList, (item) => {
                            return { label: _.startCase(item?.plu), value: item?.id };
                          })}
                          value={values.tariff}
                          onChange={(val) => {
                            setFieldValue('tariff', val);
                          }}
                          error={errors.tariff && touched.tariff ? errors.tariff : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ZonesForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ZonesForm;
