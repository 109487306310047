import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { RESET } from 'actions/chargerOcpp';

const ResetSchema = Yup.object().shape({
  type: Yup.string().required('Reset type is required'),
});

const ResetForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const handleReset = useCallback((data, resetForm) => {
    dispatch({
      type: RESET,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Charger reset successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const initialValues = {
    type: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">RESET</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={ResetSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  id: chargerId,
                  ...values,
                };
                handleReset(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col>
                        <Select
                          isRequired
                          label="Reset Type"
                          placeholder="Select reset type"
                          options={[
                            { label: 'Soft', value: 'Soft' },
                            { label: 'Hard', value: 'Hard' },
                          ]}
                          name="type"
                          value={values.type}
                          onChange={(val) => setFieldValue(`type`, val)}
                          error={errors.type && touched.type ? errors.type : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Reset
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default ResetForm;
