import React from 'react';
// import { BsDot } from 'react-icons/bs';
import { MdOutlineGpsFixed } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
// import moment from 'moment';

const ChargerInfo = () => {
  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);

  // const currentStatus = _.get(chargerDetailData, 'charger_status');
  // let statusClassName = 'available--text';
  // if (currentStatus === 'In Use') {
  //   statusClassName = 'in-use--text';
  // } else if (currentStatus === 'Unavailable') {
  //   statusClassName = 'disabled--text';
  // }

  return (
    <React.Fragment>
      <div className="charger-data-main">
        <Row className="charger-data-inner">
          <Col xl={3} md={6}>
            <span className="charger-OEM">OEM :</span> {_.get(chargerDetailData, 'oem.vendor.name', '')}
          </Col>
          <Col xl={3} md={6}>
            <span className="charger-OEM">ChargeSpot Model :</span> {_.get(chargerDetailData, 'oem.name', '')}
          </Col>
          {/* <Col xl={3} md={6}>
           <span className="charger-OEM">Type of charger : </span> {_.get(chargerDetailData, 'access_type', '')}
          </Col> */}
          {/* <Col xl={3} md={6}>
            <span className="charger-OEM">Rate : </span> {_.get(chargerDetailData, 'tarrif.schedule', '-')[0].price_per_unit} / unit
          </Col> */}
          {/*<Col xl={3} md={6}>*/}
          {/*  <span className="charger-OEM">Maximum charging time : </span>*/}
          {/*  {_.get(chargerDetailData, 'maxChargingTime', 0)} mins*/}
          {/*</Col>*/}
          <Col xl={3} md={6}>
            <span className="charger-OEM">Charging Speed : </span>
            {_.get(chargerDetailData, 'charging_speed', '')}
          </Col>
          <Col xl={3} md={6}>
            <span className="charger-OEM">Parking Restrictions : </span>
            {_.get(chargerDetailData, 'parking_restrictions', false) ? 'Yes' : 'No'}
          </Col>
        </Row>
        {/*<Row className="charger-data-main_inner">*/}
        {/*<Col xl={3} md={6}>*/}
        {/*  <span className="Charger-speed">Cooldow time : </span>*/}
        {/*  {_.get(chargerDetailData, 'cooldown_time', '')}*/}
        {/*</Col>*/}
        {/*<Col xl={3} md={6}>*/}
        {/*  <span className="Charger-speed">Energy limit : </span>*/}
        {/*  {_.get(chargerDetailData, 'energy_limits', 0)} kWh*/}
        {/*</Col>*/}
        {/*<Col xl={3} md={6}>*/}
        {/*  <span className="Charger-speed">Parking Restrictions : </span>*/}
        {/*  {_.get(chargerDetailData, 'parking_restrictions', false) ? 'Yes' : 'No'}*/}
        {/*</Col>*/}
        {/*</Row>*/}
      </div>
      <div className="charger-box-pages_main">
        <Row>
          {/* <Col xl={6} md={12} className="charger-box-pages_inner"> */}
          {/*<div className="opration-timing__block">*/}
          {/*  <div className="Charger-operation_timing">*/}
          {/*    <span className="charger-operation">Operational Timings</span>*/}
          {/*  </div>*/}
          {/*  {_.map(_.get(chargerDetailData, 'timings', ''), (item, index) => (*/}
          {/*    <div className="operation-timing__inner" key={`timing-${index}`}>*/}
          {/*      <div className="watch--icon">*/}
          {/*        <MdOutlineWatchLater className="watch-icon" />*/}
          {/*      </div>*/}
          {/*      <div className="oprational-timing-info">*/}
          {/*        {_.startCase(_.get(item, 'day', ''))} <BsDot className="dot-icon" /> {moment(_.get(item, 'from', ''), 'HH:mm').format('LT')} -{' '}*/}
          {/*        {moment(_.get(item, 'to', ''), 'HH:mm').format('LT')}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*</div>*/}
          {/* <div className="charger-box_block">
              <div className="charger-time charger-status--info">
                <div className={`charger--status ${statusClassName}`}>
                  <BsDot className="dot--icon" />
                  Charger {_.get(chargerDetailData, 'charger_status', '')}
                </div>
                <div className="charging-time_left">
                  <span className="charger-Tltc">
                    <span className="charger-watch_icon">
                      <MdOutlineWatchLater className="watch_icon" />
                    </span>
                    Time left to charger :
                  </span>
                  0 min
                </div>
                <div className="charger-energy">
                  <span className="charger-ectn">
                    <span className="charger-enery_icon">
                      <MdOutlineShowChart className="enery_icon" />
                    </span>
                    Energy consumed till now :{' '}
                  </span>
                  0 kWh
                </div>
              </div>
            </div>
          </Col> */}
          <Col xl={12} md={12} className="charger-data-page-main">
            <div className="charger-colom-inner">
              <div className="charger-four">
                <span className="charger-pluge">Plugs</span>
                <div className="charger-four-btn">
                  <span>{_.size(_.get(chargerDetailData.oem, 'plugs', []))}</span>
                </div>
              </div>
              <Row>
                {_.map(_.get(chargerDetailData.oem, 'plugs', []), (item, index) => (
                  <Col lg={6} key={`plug-${index}`}>
                    <PlugInfo icon={<MdOutlineGpsFixed />} title={_.get(item, 'connector_type.name', '')} />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* <Col xl={6} md={12} className="charger-data-page-main">
            <div className="charger-comments_notes">
              <span className="charger-Comment">Comments/Notes</span>
              <p>{_.get(chargerDetailData, 'comments', '')}</p>
            </div>
          </Col> */}
        </Row>
      </div>
    </React.Fragment>
  );
};
export default ChargerInfo;

export const PlugInfo = (props) => {
  const { icon, title } = props;
  return (
    <div className="charger-name_icon-data">
      <div className="charger-name--icon">
        <div className="charger-round_icon">{icon}</div>
        {title}
      </div>
    </div>
  );
};

PlugInfo.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.number,
};
