import { combineReducers } from 'redux';
import authReducer from './authReducer';
import sidebarReducer from './sidebar';
import tenantReducer from './tenant';
import chargingStationReducer from './chargingStation';
import vehicleReducer from './vehicle';
import chargerReducer from './charger';
import invoiceReducer from './invoice';
import chargerBookingReducer from './chargerBooking';
import userReducer from './user';
import oemReducer from './oem';
import amenityReducer from './amenity';
import subscriptionReducer from './subscription';
import userProfileReducer from './profile';
import customerReducer from './customer';
import countryReducer from './country';
import stateReducer from './state';
import cityReducer from './city';
import areaReducer from './area';
import notificationReducer from './notification';
import reviewReducer from './review';
import tariffReducer from './tariff';
import vehicleMakeReducer from './vehicleMake';
import vehicleModelReducer from './vehicleModel';
import activityLogReducer from './activityLogReducer';
import electricitySupplierReducer from './electricitySupplier';
import stationAccessTypeReducer from './stationAccessType';
import oemVendorReducer from './oemVendor';
import faqReducer from './faq';
import privacyReducer from './privacy';
import connectorTypeReducer from './connectorType';
import chargeSpeedReducer from './chargeSpeed';
import roleReducer from './role';
import dashboardReducer from './dashboard';
import chargerStateReducer from './chargerState';
import oemErrorCodeReducer from './oemErrorCode';
import stationUnitConsumptionReducer from './stationUnitConsumption';
import b2bStationChargingHistoryReducer from './b2bStationChargingHistory';
import b2cStationChargingHistoryReducer from './b2cStationChargingHistory';
import jsonRequestResponseReportReducer from './jsonRequestResponseReport';
import walletTransactionReportReducer from './walletTransactionReport';
import mostVehicleChargedListReducer from './mostVehicleChargedList';
import appUserDetailsListReducer from './appUserDetailsList';
import bookingHistoryReducer from './bookingHistory';
import stationChargingSummaryReducer from './stationChargingSummary';
import userChargingHistoryReducer from './userChargingHistory';
import bookingSummaryReportReducer from './bookingSummaryReport';
import moneyTransactionPgReportReducer from './moneyTransactionPgReport';
import permissionReducer from './permissions';
import offersReducer from './offers';
import userGroupReducer from './userGroup';
import smsReducer from './sms';
import emailReducer from './email';
import cityByStateReducer from './cityByState';
import moduleReducer from './module';
import rfidReducer from './rfid';
import rfidGroupReducer from './rfidGroup';
import chargerStatusReducer from './chargerStatus';
import accessReportReducer from './accessReport';
import zoneManagementreducer from './zoneManagement';
import capacityUtilisationReducer from './capacity_reducer';
import stationDownTimeReportReducer from './stationDownTimeReport';
import ocpiPullRequestReducer from './ocpiPullRequest';
import partnerManagementReducer from './partnerManagement';

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  tenant: tenantReducer,
  chargingStation: chargingStationReducer,
  vehicle: vehicleReducer,
  charger: chargerReducer,
  invoice: invoiceReducer,
  chargerBooking: chargerBookingReducer,
  user: userReducer,
  oem: oemReducer,
  amenity: amenityReducer,
  subscription: subscriptionReducer,
  profile: userProfileReducer,
  customer: customerReducer,
  country: countryReducer,
  state: stateReducer,
  city: cityReducer,
  area: areaReducer,
  notification: notificationReducer,
  review: reviewReducer,
  tariff: tariffReducer,
  vehicleMake: vehicleMakeReducer,
  vehicleModel: vehicleModelReducer,
  activityLog: activityLogReducer,
  electricitySupplier: electricitySupplierReducer,
  stationAccessType: stationAccessTypeReducer,
  oemVendor: oemVendorReducer,
  faq: faqReducer,
  privacy: privacyReducer,
  connectorType: connectorTypeReducer,
  chargeSpeed: chargeSpeedReducer,
  role: roleReducer,
  dashboard: dashboardReducer,
  chargerState: chargerStateReducer,
  oemErrorCode: oemErrorCodeReducer,
  stationUnitConsumption: stationUnitConsumptionReducer,
  b2bStationChargingHistory: b2bStationChargingHistoryReducer,
  b2cStationChargingHistory: b2cStationChargingHistoryReducer,
  jsonRequestResponseReport: jsonRequestResponseReportReducer,
  walletTransactionReport: walletTransactionReportReducer,
  mostVehicleChargedList: mostVehicleChargedListReducer,
  appUserDetailsList: appUserDetailsListReducer,
  bookingHistory: bookingHistoryReducer,
  stationChargingSummary: stationChargingSummaryReducer,
  userChargingHistory: userChargingHistoryReducer,
  bookingSummaryReport: bookingSummaryReportReducer,
  moneyTransactionPgReport: moneyTransactionPgReportReducer,
  permissions: permissionReducer,
  offers: offersReducer,
  userGroup: userGroupReducer,
  sms: smsReducer,
  email: emailReducer,
  cityByState: cityByStateReducer,
  module: moduleReducer,
  rfid: rfidReducer,
  rfidGroup: rfidGroupReducer,
  chargerStatus: chargerStatusReducer,
  accessReport: accessReportReducer,
  zoneManagement: zoneManagementreducer,
  capacityUtilisation: capacityUtilisationReducer,
  stationDownTimeReport: stationDownTimeReportReducer,
  ocpiPullRequest: ocpiPullRequestReducer,
  partnerManagement: partnerManagementReducer,
});

export default rootReducer;
