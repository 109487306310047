import { get, post, deleteRequest, patch } from 'utils/api';

export const getUserGroup = (data) => {
  return get(`/user_group`, data);
};

export const getUserGroupById = (id) => {
  return get(`/user_group/${id}`);
};

export const addUserGroup = (data) => {
  return post(`/user_group`, data);
};

export const updateUserGroup = (data) => {
  const id = data.id;
  delete data['id'];

  return patch(`/user_group/${id}`, data);
};

export const deleteUserGroup = (id) => {
  return deleteRequest(`/user_group/${id}`);
};

export const allUsersInUserGroup = (data) => {
  return get(`/users`, data);
};

export const userGroup = {
  getUserGroup,
  getUserGroupById,
  addUserGroup,
  updateUserGroup,
  deleteUserGroup,
  allUsersInUserGroup,
};
