export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAIL = 'CREATE_CUSTOMER_FAIL';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = ' UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAIL = ' DELETE_CUSTOMER_FAIL';

export const WALLET_TRANSACTION = 'WALLET_TRANSACTION';
export const WALLET_TRANSACTION_SUCCESS = 'WALLET_TRANSACTION_SUCCESS';
export const WALLET_TRANSACTION_FAIL = 'WALLET_TRANSACTION_FAIL';
