import React from 'react';
import PropTypes from 'prop-types';
import { FaCamera } from 'react-icons/fa';
import UserImage from 'assets/images/icons/dummyImage.png';

const ProfilePicInput = (props) => {
  const { path, name = '', onChange, showAdd, disabled, showLoader } = props;

  return (
    <div className="profile-block__main">
      <div className="profile-block__inner">
        <div className="profile-img--inner">
          <div className="picture__block">
            {showLoader ? (
              <div className="profile-loader--block">
                <div className="spinner-border text-danger" />
              </div>
            ) : (
              <img src={path || UserImage} alt="" />
            )}
            <div className="camera-icon__block">
              <FaCamera className="camera-icon" />
              <div className="file-input--block">
                <input type="file" className="file-input" onChange={onChange} disabled={disabled} name={name} />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-content">
          <span>{showAdd ? 'Add' : 'Replace'} Profile Pic</span>
        </div>
      </div>
    </div>
  );
};
ProfilePicInput.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  showAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
  onChange: PropTypes.func,
};
export default ProfilePicInput;
