export const FETCH_CHARGER_LOG = 'FETCH_CHARGER_LOG';
export const FETCH_CHARGER_LOG_SUCCESS = 'FETCH_CHARGER_LOG_SUCCESS';
export const FETCH_CHARGER_LOG_FAIL = 'FETCH_CHARGER_LOG_FAIL';

export const DELETE_CHARGER_LOG = 'DELETE_CHARGER_LOG';
export const DELETE_CHARGER_LOG_SUCCESS = 'DELETE_CHARGER_LOG_SUCCESS';
export const DELETE_CHARGER_LOG_FAIL = 'DELETE_CHARGER_LOG_FAIL';

export const DOWNLOAD_CHARGER_LOG_DATA = 'DOWNLOAD_CHARGER_LOG_DATA';
export const DOWNLOAD_CHARGER_LOG_DATA_SUCCESS = 'DOWNLOAD_CHARGER_LOG_DATA_SUCCESS';
export const DOWNLOAD_CHARGER_LOG_DATA_FAIL = 'DOWNLOAD_CHARGER_LOG_DATA_FAIL';
