import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDHLLX_W9JJ6sk09l4qjoqQAES-5yeUyyE',
  authDomain: 'adnoc-1d548.firebaseapp.com',
  projectId: 'adnoc-1d548',
  storageBucket: 'adnoc-1d548.appspot.com',
  messagingSenderId: '513504393019',
  appId: '1:513504393019:web:7833dfad7825e01c89a91e',
  measurementId: 'G-J6R7MCC5D9',
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: 'BOvRh9vyixAa_3yH9QRvWkvGykxe1RggwBsyd-WopRddqcr5Se8rAeEZopVxwT1wsZ95dS3jeVMChCCakY-0jm0',
    });
    return token;
  } catch (error) {
    console.log('No registration token available. Request permission to generate one.');
    return error;
  }
};

// ForeGround
export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      //console.log('Message received====>', payload);
      resolve(payload);
      // const notificationTitle = payload.notification.title;
      // const notificationOptions = {
      //   body: payload.notification.body,
      //   icon: payload.notification.icon,
      // };
      // const notification = new Notification(notificationTitle, notificationOptions);
      // console.log('notification-====', notification);
    });
  });
};

// const { REACT_APP_VAPID_KEY } = process.env;
// const publicKey = REACT_APP_VAPID_KEY;
