import { MONEY_TRANSACTION_PG_REPORT, MONEY_TRANSACTION_PG_REPORT_SUCCESS, MONEY_TRANSACTION_PG_REPORT_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  moneyTransactionPgReports: [],
};

const moneyTransactionPgReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MONEY_TRANSACTION_PG_REPORT: {
      return { ...state, isLoading: true };
    }

    case MONEY_TRANSACTION_PG_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        moneyTransactionPgReports: payload,
      };
    }

    case MONEY_TRANSACTION_PG_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default moneyTransactionPgReportReducer;
