import { post } from 'utils/api';

export const cancelReservation = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/cancel_reservation/${id}`, data);
};

export const changeAvailability = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/change_availability/${id}`, data);
};

export const getConfiguration = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/get_configuration/${id}`, data);
};

export const changeConfiguration = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/change_config/${id}`, data);
};

export const clearCache = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/clear_cache/${id}`, data);
};

export const getDiagnostics = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/get_diagnostics/${id}`, data);
};

export const getLocalListVersion = (data) => {
  const id = data.id;
  delete ['id'];
  return post(`/chargers/actions/get_local_list_version/${id}`);
};

export const remoteStartTransaction = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/remote_start_transaction/${id}`, data);
};

export const remoteStopTransaction = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/remote_stop_transaction/${id}`, data);
};

export const reserveNow = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/reserve_now/${id}`, data);
};

export const reset = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/reset/${id}`, data);
};

export const sendLocalList = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/send_local_list/${id}`, data);
};

export const triggerMessage = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/trigger_message/${id}`, data);
};

export const unlockConnector = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/unlock_connector/${id}`, data);
};

export const updateFirmware = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/update_firmware/${id}`, data);
};

export const dataTransferServer = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/data_transfer/${id}`, data);
};

export const changeAuthorization = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/change_config/${id}`, data);
};

export const chargeOcpp = {
  cancelReservation,
  changeAvailability,
  getConfiguration,
  changeConfiguration,
  clearCache,
  getDiagnostics,
  getLocalListVersion,
  remoteStartTransaction,
  remoteStopTransaction,
  reserveNow,
  reset,
  sendLocalList,
  triggerMessage,
  unlockConnector,
  updateFirmware,
  dataTransferServer,
  changeAuthorization,
};
