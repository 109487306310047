import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
// import { Row, Col } from 'react-bootstrap';
// import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { CREATE_RFID, UPDATE_RFID } from 'actions/rfid';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import TextInput from 'components/inputs/Input';

const RfidSchema = Yup.object().shape({
  code: Yup.string()
    .required('RFID tag is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters'),
  date_of_issuance: Yup.string().required('Insurance date is required'),
  expiry_date: Yup.string().required('Expiry date is required'),
  offer_expiry_date: Yup.string().required('Offer expiry date is required'),
});

const RfidEditList = (props) => {
  const dispatch = useDispatch();
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;

  const rfidDetail = useSelector((state) => state.rfid.rfidDetail);
  const isLoading = useSelector((state) => state.rfid.isLoading);

  const addRfid = useCallback((data) => {
    dispatch({
      type: CREATE_RFID,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateRfid = useCallback((data) => {
    dispatch({
      type: UPDATE_RFID,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const initialValues = isEdit
    ? {
        ...rfidDetail,
        date_of_issuance: moment(_.get(rfidDetail, 'date_of_issuance')).format('DD MMM YYYY'),
        expiry_date: moment(_.get(rfidDetail, 'expiry_date')).format('DD MMM YYYY'),
        offer_expiry_date: moment(_.get(rfidDetail, 'offer_expiry_date')).format('DD MMM YYYY'),
      }
    : {
        code: '',
        date_of_issuance: '',
        expiry_date: '',
        offer_expiry_date: '',
        user: '',
        comments: '',
        offer_enabled: false,
        group: '',
      };
  return (
    <React.Fragment>
      <div className="rfid-edit-list-page__main">
        <Card>
          <div className="rfid-edit-list-header__block">
            <span>{isEdit ? 'Update' : 'Add'} RFID Details </span>
            {/* <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div> */}
          </div>
          <div className="rfid-edit-list-body__block">
            <div className="rfid-edit-list--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={RfidSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    const editRfidData = _.omit(values, ['status', 'tenant']);
                    updateRfid(editRfidData);
                  } else {
                    addRfid(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="rfid-edit-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>Sr. No</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>RFID Tag</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Date of issuance</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Expiry Date</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Offer Expiry Date</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1.</td>
                              <td>
                                <TextInput
                                  isRequired
                                  name="code"
                                  value={values.code}
                                  onChange={handleChange}
                                  error={errors.code && touched.code ? errors.code : null}
                                />
                              </td>
                              <td>
                                {' '}
                                <TextInput
                                  isRequired
                                  name="date_of_issuance"
                                  value={values.date_of_issuance}
                                  onChange={handleChange}
                                  error={errors.date_of_issuance && touched.date_of_issuance ? errors.date_of_issuance : null}
                                />
                              </td>
                              <td>
                                <TextInput
                                  isRequired
                                  name="expiry_date"
                                  value={values.expiry_date}
                                  onChange={handleChange}
                                  error={errors.expiry_date && touched.expiry_date ? errors.expiry_date : null}
                                />
                              </td>
                              <td>
                                <TextInput
                                  isRequired
                                  name="offer_expiry_date"
                                  value={values.offer_expiry_date}
                                  onChange={handleChange}
                                  error={errors.offer_expiry_date && touched.offer_expiry_date ? errors.offer_expiry_date : null}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="rfid-edit-list-btn__block">
                          <span>
                            <Button type="submit" disabled={isSubmitting || isLoading} className="rfid-edit-list_btn">
                              {isEdit ? 'Update' : 'Add'} RFID
                            </Button>
                          </span>
                          <span className="cancel-button-block">
                            <Button className="cancel____btn" onClick={onClose}>
                              Cancel
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
RfidEditList.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default RfidEditList;
