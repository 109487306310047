import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { CANCEL_RESERVATION } from 'actions/chargerOcpp';

const CancelReservationSchema = Yup.object().shape({
  reservationId: Yup.number().required('Reservation id is required!'),
});

const CancelReservationForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const initialValues = {
    reservationId: '',
  };

  const getCancelReservation = useCallback((data, resetForm) => {
    dispatch({
      type: CANCEL_RESERVATION,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Reservation cancel successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">CANCEL RESERVATION</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={CancelReservationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  id: chargerId,
                  ...values,
                };
                getCancelReservation(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Reservation ID"
                          type="number"
                          value={values.reservationId}
                          name="reservationId"
                          onChange={handleChange}
                          error={errors.reservationId && touched.reservationId ? errors.reservationId : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Cancel Reservation
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default CancelReservationForm;
