import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_INVOICE, FETCH_INVOICE_FAIL, FETCH_INVOICE_SUCCESS } from '../actions/invoice';

function* fetchInvoice(action) {
  try {
    const response = yield call(API.getAllInvoice, action.payload);
    yield put({ type: FETCH_INVOICE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_INVOICE_FAIL, payload: { error: e } });
  }
}

function* invoice() {
  yield takeLatest(FETCH_INVOICE, fetchInvoice);
}

export default invoice;
