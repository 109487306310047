import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { REMOTE_STOP_TRANSACTION } from 'actions/chargerOcpp';

const RemoteStopTransactionSchema = Yup.object().shape({
  transactionId: Yup.number().required('Transaction id is required!'),
});

const RemoteStopTransactionForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();

  const getRemoteStopTransaction = useCallback((data, resetForm) => {
    dispatch({
      type: REMOTE_STOP_TRANSACTION,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Remote stop transaction successfully');
            resetForm();
          }
        }
      },
    });
  }, []);

  const initialValues = {
    transactionId: '',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">REMOTE STOP TRANSACTION</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={RemoteStopTransactionSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = {
                  ...values,
                  id: chargerId,
                };
                getRemoteStopTransaction(formData, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label="Transaction Id "
                          placeholder="Transaction id"
                          type="number"
                          value={values.transactionId}
                          name="transactionId"
                          onChange={handleChange}
                          error={errors.transactionId && touched.transactionId ? errors.transactionId : null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                      Stop Transaction
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default RemoteStopTransactionForm;
